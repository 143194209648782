import logoUrl from "#/images/colored-logo-invoice.png";
import { RouterOutputs } from "#/trpc.js";
import { fetchImageAsBase64 } from "#/util/index.js";
import { applyDiscountV2 } from "../util";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import ExcelJS, { Borders } from "exceljs";
import { saveAs } from "file-saver";
import _ from "lodash";

type Order = NonNullable<RouterOutputs["order"]["getById"]>;

export const exportInvoiceToXLSX = async (order: Order) => {
  const wb = new ExcelJS.Workbook();
  const sheet = wb.addWorksheet("Sheet 1", {
    pageSetup: { paperSize: 9 },
  });

  sheet.getCell(1, 1).value = `Printed: ${dayjs().format("MM/DD/YYYY")}`;
  sheet.getCell(2, 1).value = `Store: 1`;
  sheet.getCell(3, 1).value = `Workstation: 1`;

  sheet.mergeCells("I1:J1");
  sheet.mergeCells("I2:J2");
  sheet.mergeCells("I3:J3");
  sheet.mergeCells("I4:J4");
  sheet.getCell("I1").value = `Sales receipt #${order?.receiptNumber}`;
  sheet.getCell("I2").value = `Sale Date: ${dayjs(order.createdAt).format(
    "MM/DD/YYYY"
  )}`;
  sheet.getCell("I3").value = `Cashier: ${order.cashier?.firstName} ${
    order.cashier?.lastName ?? ""
  }`;
  sheet.getCell("I4").value = "Page: 1";

  sheet.mergeCells("C5:H5");
  sheet.getCell("C5").font = {
    bold: true,
    size: 22,
  };
  sheet.getCell("C5").value = "INVOICE";
  sheet.getCell("C5").alignment = {
    horizontal: "center",
  };
  sheet.getRow(5).height = 28;

  const imgData = await fetchImageAsBase64(logoUrl);
  const logo = wb.addImage({
    base64: imgData.data,
    extension: imgData.ext,
  });
  sheet.addImage(logo, {
    tl: {
      col: 3,
      row: 5,
    },
    ext: { width: 180, height: 120 },
  });

  sheet.mergeCells("C12:H12");
  sheet.mergeCells("C13:H13");
  sheet.mergeCells("C14:H14");
  sheet.mergeCells("C15:H15");
  sheet.getCell("C12").value = "GOLD TREE";
  sheet.getCell("C13").value = "755 NW 72n AVE PLAZA 33";
  sheet.getCell("C14").value = "MIAMI, FL, 33126";
  sheet.getCell("C15").value = "PHONE: +1 (786) 7177131";

  sheet.getCell("A17").value = "Bill to:";
  sheet.getCell("B17").value =
    order.customer?.company ??
    `${order.customer?.firstName} ${order.customer?.lastName ?? ""}`;

  const borderStyle = {
    top: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  } as Partial<Borders>;

  sheet.getCell("A24").value = "SKU";
  sheet.getCell("A24").border = borderStyle;
  sheet.getCell("B24").value = "Qty";
  sheet.getCell("B24").border = borderStyle;
  sheet.mergeCells("C24:G24 ");
  sheet.getCell("C24").value = "Product Name";
  sheet.getCell("C24").border = borderStyle;
  sheet.getCell("H24").value = "Price";
  sheet.getCell("H24").border = borderStyle;
  sheet.getCell("I24").value = "Discount";
  sheet.getCell("I24").border = borderStyle;
  sheet.getCell("J24").value = "Ext price";
  sheet.getCell("J24").border = borderStyle;

  order.orderItemSku?.forEach((p, i) => {
    sheet.getCell(`A${i + 25}`).value = p?.itemSku.sku;
    sheet.getCell(`A${i + 25}`).border = borderStyle;
    sheet.getCell(`B${i + 25}`).value = p?.quantity;
    sheet.getCell(`B${i + 25}`).border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.mergeCells(`C${i + 25}:G${i + 25}`);
    sheet.getCell(`C${i + 25}`).value = p?.itemSku.title;
    sheet.getCell(`C${i + 25}`).border = borderStyle;
    sheet.getCell(`H${i + 25}`).value = p?.price.toNumber();
    sheet.getCell(`H${i + 25}`).numFmt = "$0.00";
    sheet.getCell(`H${i + 25}`).border = borderStyle;
    sheet.getCell(`I${i + 25}`).value = `$${(p?.price ?? 0)
      .times(p?.quantity)
      .minus(
        p?.discountType === "PERCENTAGE"
          ? p?.price
              .times(p?.quantity)
              .times(new Decimal(1).sub(p?.discountAmount?.div(100) ?? 0))
          : p?.price.times(p?.quantity).sub(p?.discountAmount ?? 0)
      )
      .toNumber()
      .toFixed(2)}`;
    sheet.getCell(`I${i + 25}`).border = borderStyle;
    sheet.getCell(`J${i + 25}`).value =
      p?.discountType === "PERCENTAGE"
        ? p?.price
            .times(p?.quantity)
            .times(new Decimal(1).sub(p?.discountAmount?.div(100) ?? 0))
            .toNumber()
            .toFixed(2)
        : p?.price
            .times(p?.quantity)
            .sub(p?.discountAmount ?? 0)
            .toNumber();
    sheet.getCell(`J${i + 25}`).numFmt = "$0.00";
    sheet.getCell(`J${i + 25}`).border = borderStyle;
  });
  let afterLastRow = 25 + (order.orderItemSku?.length ?? 0);

  sheet.getCell(`I${afterLastRow}`).value = "Subtotal";
  sheet.getCell(`J${afterLastRow}`).value = order.subTotal.toNumber();
  sheet.getCell(`J${afterLastRow}`).numFmt = "$0.00";
  sheet.getCell(`I${afterLastRow}`).border = borderStyle;
  sheet.getCell(`J${afterLastRow}`).border = borderStyle;

  const couponToSubtotal =
    order?.coupon != null &&
    order.coupon.discount.type === "ORDER_SUBTOTAL_PERCENTAGE"
      ? {
          amount: `${order.coupon.discount.amount.toFixed(2)}%`,
          appliedAmount: order?.subTotal
            ?.sub(applyDiscountV2(order.subTotal, order.coupon.discount))
            ?.toNumber(),
        }
      : null;

  const couponToShipping =
    order?.coupon != null &&
    order.coupon.discount.type === "SHIPPING_PERCENTAGE" &&
    order?.orderShipping?.shippingCost != null
      ? {
          previousShipping: order.orderShipping.shippingCost
            ?.div(new Decimal(1).sub(order.coupon.discount.amount.div(100)))
            .toNumber(),
          discountShipping: order.orderShipping.shippingCost
            ?.sub(
              order.orderShipping.shippingCost?.div(
                new Decimal(1).sub(order.coupon.discount.amount.div(100))
              )
            )
            .toNumber(),
        }
      : null;

  if (couponToSubtotal) {
    sheet.getCell(
      `I${afterLastRow + 1}`
    ).value = `Applied coupon ${couponToSubtotal.amount}`;
    sheet.getCell(`J${afterLastRow + 1}`).value =
      couponToSubtotal.appliedAmount;
    sheet.getCell(`J${afterLastRow + 1}`).numFmt = "$0.00";
    sheet.getCell(`I${afterLastRow + 1}`).border = borderStyle;
    sheet.getCell(`J${afterLastRow + 1}`).border = borderStyle;

    afterLastRow = afterLastRow + 1;
  }

  sheet.getCell(`I${afterLastRow + 1}`).value = "Discount";
  sheet.getCell(`J${afterLastRow + 1}`).value = order?.discountReference
    ? order?.discountType === "PERCENTAGE"
      ? `${order?.discountAmount}%`
      : `$${order?.discountAmount?.toFixed(2)}`
    : "$0.00";
  sheet.getCell(`J${afterLastRow + 1}`).numFmt = "$0.00";
  sheet.getCell(`J${afterLastRow + 1}`).alignment = {
    horizontal: "right",
  };
  sheet.getCell(`I${afterLastRow + 1}`).border = borderStyle;
  sheet.getCell(`J${afterLastRow + 1}`).border = borderStyle;

  const taxLabel = order?.customer?.taxable
    ? `${order?.taxRate?.times(100) ?? 0}% Tax`
    : "0% Tax";
  sheet.getCell(`I${afterLastRow + 2}`).value = taxLabel;
  sheet.getCell(`J${afterLastRow + 2}`).value = order.taxTotal.toNumber();
  sheet.getCell(`J${afterLastRow + 2}`).numFmt = "$0.00";
  sheet.getCell(`I${afterLastRow + 2}`).border = borderStyle;
  sheet.getCell(`J${afterLastRow + 2}`).border = borderStyle;

  if (couponToShipping) {
    sheet.getCell(`I${afterLastRow + 3}`).value = "Shipping";
    sheet.getCell(`J${afterLastRow + 3}`).value =
      couponToShipping.previousShipping;
    sheet.getCell(`J${afterLastRow + 3}`).numFmt = "$0.00";
    sheet.getCell(`I${afterLastRow + 3}`).border = borderStyle;
    sheet.getCell(`J${afterLastRow + 3}`).border = borderStyle;

    sheet.getCell(`I${afterLastRow + 4}`).value = "Discount Shipping";
    sheet.getCell(`J${afterLastRow + 4}`).value =
      couponToShipping.discountShipping;
    sheet.getCell(`J${afterLastRow + 4}`).numFmt = "$0.00";
    sheet.getCell(`I${afterLastRow + 4}`).border = borderStyle;
    sheet.getCell(`J${afterLastRow + 4}`).border = borderStyle;

    afterLastRow = afterLastRow + 2;
  }

  sheet.getCell(`I${afterLastRow + 3}`).value = "Total Shipping";
  sheet.getCell(`J${afterLastRow + 3}`).value =
    order.orderShipping?.shippingCost.toNumber() ?? 0;
  sheet.getCell(`J${afterLastRow + 3}`).numFmt = "$0.00";
  sheet.getCell(`I${afterLastRow + 3}`).border = borderStyle;
  sheet.getCell(`J${afterLastRow + 3}`).border = borderStyle;

  sheet.getCell(`I${afterLastRow + 4}`).value = "Total";
  sheet.getCell(`J${afterLastRow + 4}`).value = Number(order.total);
  sheet.getCell(`J${afterLastRow + 4}`).numFmt = "$0.00";
  sheet.getCell(`I${afterLastRow + 4}`).border = borderStyle;
  sheet.getCell(`J${afterLastRow + 4}`).border = borderStyle;

  sheet.getCell(`A${afterLastRow + 4}`).value = "Tendered amount:";
  sheet.getCell(`B${afterLastRow + 4}`).value =
    order.orderPayment?.reduce(
      (old, c) => old + (c?.paidIn.toNumber() ?? 0),
      0
    ) ?? 0;
  sheet.getCell(`B${afterLastRow + 4}`).numFmt = "$0.00";

  sheet.getCell(`A${afterLastRow + 5}`).value = "Change:";
  sheet.getCell(`B${afterLastRow + 5}`).value =
    order.orderPayment?.reduce(
      (old, c) => old + (c?.paidOut.toNumber() ?? 0),
      0
    ) ?? 0;
  sheet.getCell(`B${afterLastRow + 5}`).numFmt = "$0.00";
  _.sortBy(
    order.orderPayment?.filter((pm) => (pm?.paidIn.toNumber() ?? 0) > 0.001),
    (o) =>
      o?.paymentType === "VISA"
        ? 0
        : o?.paymentType === "MASTERCARD"
        ? 1
        : o?.paymentType === "AMERICAN_EXPRESS"
        ? 2
        : 3
  ).forEach((pm, i) => {
    sheet.getCell(`A${afterLastRow + 7 + i}`).value = pm?.paymentType;
    sheet.getCell(`B${afterLastRow + 7 + i}`).value = pm?.paidIn.toNumber();
    sheet.getCell(`B${afterLastRow + 7 + i}`).numFmt = "$0.00";
  });

  const xlsx = await wb.xlsx.writeBuffer({
    // @ts-ignore
    base64: true,
  });
  saveAs(
    new Blob([xlsx], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    `invoice_${dayjs().format("MM/DD/YYYY")}`
  );
};
