import { createFormContext } from "#/components-ng/form-context"
import { trpc } from "#/trpc"
import { reportUserSuccess } from "#/util"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Menu, Select, Title } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { modals } from "@mantine/modals"
import { FormProvider } from "react-hook-form"
import { z } from "zod"

export function SendStockHistoryMenuItem() {
	function handleSendStockHistoryClick() {
		modals.open({
			title: (
				<Title order={3} className="text-base text-slate-700">
					Send stock history through email
				</Title>
			),
			children: <SendStockHistoryForm />,
		})
	}

	return (
		<Menu.Item onClick={handleSendStockHistoryClick}>
			Send stock history through email
		</Menu.Item>
	)
}

const FormSchema = z.object({
	date: z.date(),
	filter: z
		.discriminatedUnion("by", [
			z.object({
				by: z.literal("entity"),
				id: z.number(),
			}),
			z.object({
				by: z.literal("filial"),
				id: z.number(),
			}),
		])
		.default({ by: "entity", id: 1 }),
})
type FormSchema = z.infer<typeof FormSchema>

const { C, useForm, Controller } = createFormContext<FormSchema>()

function SendStockHistoryForm() {
	const form = useForm({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			date: new Date(),
			filter: {
				by: "entity",
				id: 1,
			},
		},
	})

	const { mutate } = trpc.v2_5.itemSku.getStockHistory.useMutation({
		onSuccess(data) {
			reportUserSuccess({
				title: "Stock history is in the process of being sent",
				message: (
					<span>
						Recipent is <span className="font-medium">{data.sentTo}</span>. This
						can take a few minutes.
					</span>
				),
			})
		},
	})

	function handleSubmit(values: FormSchema) {
		mutate({
			date: values.date,
			filter: {
				by: values.filter.by,
				id: values.filter.id,
			},
		})
	}

	const filterBy = form.watch("filter.by")

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<div className="grid gap-y-4">
					<C
						$as={DatePickerInput}
						name="date"
						label="Date"
						popoverProps={{
							withinPortal: true,
						}}
					/>
					<C
						$as={Select}
						label="Report type"
						name="filter.by"
						data={[
							{ label: "Entity", value: "entity" },
							{ label: "Filial", value: "filial" },
						]}
						required
					/>
					<FilialField by={filterBy} />
					<Button type="submit" className="justify-self-end px-10">
						Send
					</Button>
				</div>
			</form>
		</FormProvider>
	)
}

interface FilialFieldProps {
	by: "entity" | "filial"
}

function FilialField(props: FilialFieldProps) {
	const { data } = trpc.filial.getAll.useQuery()
	const { data: entities } = trpc.entity.getAll.useQuery()

	const entityOptions = useMemo(
		() =>
			entities?.map((entity) => ({
				label: entity.name,
				value: entity.id.toString(),
			})) ?? [],
		[entities],
	)

	const selectData =
		data?.map((filial) => ({
			label: filial.name,
			value: filial.id.toString(),
		})) ?? []

	return (
		<Controller
			name="filter.id"
			render={(c) => (
				<Select
					data={props.by === "entity" ? entityOptions : selectData}
					label={props.by === "entity" ? "Select entity" : "Select filial"}
					withinPortal
					onChange={(v) => {
						if (v != null) {
							c.field.onChange(Number(v))
						}
					}}
					value={c.field.value.toString()}
				/>
			)}
		/>
	)
}
