import { Drawer } from "@mantine/core";
import { styled } from "#/css/jsx";
import { z } from "zod";
import { makeController } from "#/components-ng";
import {
	type DefaultValues,
	FormProvider,
	type SubmitHandler,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Select } from "@mantine/core";
import { trpc } from "#/trpc";
import { DateTimePicker } from "@mantine/dates";
import { Button } from "@gt/ui";
import { css } from "#/css/css";

export const updateCalledCartSchema = z.object({
	calledByUserId: z.string(),
	calledAt: z.date().nullish(),
});

export type UpdateCalledCartValues = z.infer<typeof updateCalledCartSchema>;
export const CUpdateCalledCart = makeController<UpdateCalledCartValues>();

interface CalledFormDrawerProps {
	opened: boolean;
	onClose: () => void;
	defaultValues?: DefaultValues<UpdateCalledCartValues>;
	isLoading: boolean;
	onSubmit: SubmitHandler<UpdateCalledCartValues>;
}

export const CalledFormDrawer = (props: CalledFormDrawerProps) => {
	const { data, isLoading } = trpc.user.getManyWithEmployeeRole.useQuery(
		undefined,
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const form = CUpdateCalledCart.useForm({
		resolver: zodResolver(updateCalledCartSchema),
		shouldUnregister: false,
		resetOptions: {
			keepDirtyValues: true,
		},
		defaultValues: {
			calledByUserId: "",
			calledAt: null,
			...props.defaultValues,
		},
	});

	return (
		<Drawer
			opened={props.opened}
			onClose={props.onClose}
			closeOnClickOutside={false}
			closeOnEscape={false}
			position="right"
			size="md"
			padding="lg"
		>
			<styled.h2 marginBottom={5}>Called</styled.h2>
			<FormProvider {...form}>
				<form
					onSubmit={form.handleSubmit((values) => {
						form.reset();
						props.onSubmit(values);
					})}
				>
					<styled.div display="flex" flexDirection="column" gap="1.5rem">
						<CUpdateCalledCart.M
							as={Select}
							name="calledByUserId"
							data={
								data?.map((u) => ({
									value: u.id.toString(),
									label: `${u.firstName} ${u?.lastName ?? ""}`,
								})) ?? []
							}
							searchable
							placeholder="POS - Users"
							label="Called by"
						/>
						<CUpdateCalledCart.M
							as={DateTimePicker}
							name="calledAt"
							label="Called at"
							placeholder="01/01/2024 00:00 p.m."
							onPointerEnterCapture={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
							onPointerLeaveCapture={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
						/>
						<Button
							className={css({ width: "fit-content" })}
							type="submit"
							isLoading={isLoading || props.isLoading}
						>
							Save
						</Button>
					</styled.div>
				</form>
			</FormProvider>
		</Drawer>
	);
};
