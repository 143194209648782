import { useGlobalAtomValue } from "#/lib/jotai"
import { trpc } from "#/trpc"
import { terminalDeviceCodeIdAtom } from "./terminal.atom"

export function useTerminal() {
	const deviceCodeId = useGlobalAtomValue(terminalDeviceCodeIdAtom)

	const { data: deviceCodeData } = trpc.v2_5.square.getDeviceCode.useQuery(
		{
			id: deviceCodeId!,
		},
		{
			enabled: deviceCodeId != null,
			cacheTime: 0,
			refetchInterval: 10000,
		},
	)

	const memoedData = useMemo(() => {
		if (deviceCodeData == null) {
			return null
		}

		return {
			id: deviceCodeData.deviceId,
			status: deviceCodeData.status,
			// id: "9fa747a2-25ff-48ee-b078-04381f7c828f",
			// status: "PAIRED"
		}
	}, [deviceCodeData])

	return memoedData
}
