import { EllipsisHorizontal } from "#/components-ng"
import { VStack, styled } from "#/css/jsx"
import { type RouterOutputs, trpc } from "#/trpc"
import { Dropdown } from "@gt/ui"
import { modals } from "@mantine/modals"
import { IconButton, Spinner } from "@radix-ui/themes"
import dayjs from "dayjs"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table"
import FileSaver from "file-saver"
import { css } from "#/css/css"

export const openSeeDocumentsModal = (poId: number) => {
	modals.open({
		modalId: "SEE-PO-DOCUMENTS-MODAL",
		children: <SeeDocumentsModal poId={poId} />,
		size: "auto",
		centered: true,
		title: <h2>See documents</h2>,
	})
}

interface SeeDocumentsModalProps {
	poId: number
}

const SeeDocumentsModal = (props: SeeDocumentsModalProps) => {
	const { data, isLoading, isFetchedAfterMount } =
		trpc.v2_5.purchaseOrder.getDocumentsByPoId.useQuery(
			{
				poId: props.poId,
			},
			{
				enabled: !!props.poId,
				refetchOnWindowFocus: false,
				cacheTime: 0,
			},
		)

	const table = useMantineReactTable({
		data: data ?? [],
		columns: columns,
		initialState: { density: "xs" },
		state: {
			isLoading,
		},
	})

	return isLoading || !isFetchedAfterMount ? (
		<Spinner loading={isLoading} size="2" />
	) : (
		<VStack gap={6}>
			<MantineReactTable table={table} />
		</VStack>
	)
}

type VendorDocument =
	RouterOutputs["v2_5"]["purchaseOrder"]["getDocumentsByPoId"][number]

const columns: MRT_ColumnDef<VendorDocument>[] = [
	{
		id: "actions",
		header: "Actions",
		enableSorting: false,
		enableColumnFilter: false,
		size: 100,
		Cell: (table) => {
			const original = table.row.original

			return (
				<Dropdown.Root>
					<Dropdown.Trigger>
						<styled.div
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<IconButton variant="ghost" color="gray" size="1">
								<EllipsisHorizontal />
							</IconButton>
						</styled.div>
					</Dropdown.Trigger>
					<Dropdown.Content
						className={css({
							zIndex: "1000 !important",
						})}
					>
						<Dropdown.Item
							onClick={() => {
								FileSaver.saveAs(original.fileUrl, original.title)
							}}
						>
							Download file
						</Dropdown.Item>
					</Dropdown.Content>
				</Dropdown.Root>
			)
		},
	},
	{
		header: "Title",
		accessorKey: "title",
		enableColumnFilter: false,
	},
	{
		header: "Vendor",
		accessorKey: "vendor.company",
		enableColumnFilter: false,
	},
	{
		header: "Type",
		accessorKey: "type",
		enableColumnFilter: false,
	},
  {
    header: "Total paid",
    accessorKey: "totalPaid",
    enableColumnFilter: false,
    Cell: (table) => {
      const totalPaid = table.row.original.totalPaid
      if (!totalPaid) return ""
      return `$${totalPaid.toFixed(2)}`
    },
  },
	{
		header: "Created At",
		accessorKey: "createdAt",
		enableColumnFilter: false,
		Cell: (table) => {
			const createdAt = table.row.original.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).format("MM/DD/YYYY").toString()
		},
	},
]
