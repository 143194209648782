import { HelpOutlineIcon, MenuOutlineIcon, SpinnerIcon } from "#/components-ng"
import { MantineController } from "#/components/Form/v3/index.js"
import { RouterPrompt } from "#/components/index.js"
import placeholderImage from "#/placeholder-image.jpg"
import { RouterInputs, RouterOutputs, trpc } from "#/trpc.js"
import * as M from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import {
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from "mantine-react-table"
import React, { useState } from "react"
import {
	useForm,
	FormProvider,
	useFieldArray,
	useFormContext,
	SubmitHandler,
	useWatch,
	useController,
} from "react-hook-form"
import DeleteIcon from "~icons/ion/trash-outline"
import { DatePickerInput } from "@mantine/dates"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Flex, styled } from "#/css/jsx"
import { css } from "#/css/css"
import { circle } from "#/css/patterns"
import { useDisclosure } from "@mantine/hooks"
import { token } from "#/css/tokens"
import { IconButton, Tooltip } from "@radix-ui/themes"
import { MdOutlineDownload } from "react-icons/md"
import { generateCsv } from "#/util/csv"
import FileSaver from "file-saver"
import { reportUserSuccess } from "#/util"
import CloseIcon from "~icons/ic/baseline-close"

export type CreateDiscountInput = RouterInputs["discount"]["pos"]["create"]
const Mc = MantineController

type Department = RouterOutputs["department"]["pos"]["search"][number]
type Category = RouterOutputs["category"]["pos"]["search"][number]
type ItemSku = RouterOutputs["department"]["pos"]["itemSkus"][number]

type DiscountMode = "LOYALTY" | "PROMOTION" | "COUPON" | "SEASONAL"

const CouponDiscountTypes = [
	{
		label: "Shipping percentage",
		value: "SHIPPING_PERCENTAGE",
	},
	{
		label: "Order subtotal percentage",
		value: "ORDER_SUBTOTAL_PERCENTAGE",
	},
]

const RegularDiscountTypes = [
	{
		label: "Percentage",
		value: "PERCENTAGE",
	},
	{
		label: "Fixed amount",
		value: "AMOUNT",
	},
]

const PromotionDiscountTypes = [
	...RegularDiscountTypes,
	{
		label: "Shipping percentage",
		value: "SHIPPING_PERCENTAGE",
	},
]

const SeasonalDiscountTypes = [
	{
		label: "Order subtotal percentage",
		value: "ORDER_SUBTOTAL_PERCENTAGE",
	},
	{
		label: "Order subtotal amount",
		value: "ORDER_SUBTOTAL_AMOUNT",
	},
]

export const DiscountFormSchema = z
	.object({
		rates: z.any(),
		ref: z.string(),
		active: z.boolean(),
		filterMode: z.enum(["BY_DEPARTMENT", "BY_ITEM", "ALL"]),
		departments: z.any(),
		itemSkus: z.any(),
		discountMode: z.enum(["LOYALTY", "PROMOTION", "COUPON", "SEASONAL"]),
		activeDateRange: z.tuple([z.date().nullable(), z.date().nullable()]),
		validOn: z.enum(["ECOMMERCE", "IN_STORE", "BOTH"]).default("BOTH"),
	})
	.superRefine((vals, ctx) => {
		if (vals.discountMode === "SEASONAL") {
			if (vals.activeDateRange[0] == null || vals.activeDateRange[1] == null) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Active date range is required",
					path: ["activeDateRange"],
				})
			}
		}
	})

export interface DiscountFormValues {
	ref: string
	rates: {
		id?: number
		// ^ Only present when `mode === "number` and set by the caller.
		type: CreateDiscountInput["type"]
		amount: number
		redeemable: boolean
		minPurchase?: number
		minPurchaseForEligibility?: number
	}[]
	discountMode: DiscountMode
	active: boolean
	filterMode: "BY_DEPARTMENT" | "BY_ITEM" | "ALL"
	departments: Array<Department>
	itemSkus: Array<ItemSku>
	activeDateRange: [Date | null, Date | null]
	// ^ Only necessary for seasonal discounts.
	validOn?: "ECOMMERCE" | "IN_STORE" | "BOTH"
	// ^ Only necessary for seasonal discounts.
}

export default function DiscountForm({
	defaultValues,
	onSubmit,
	isLoading,
	mode,
}: {
	defaultValues?: Partial<DiscountFormValues>
	onSubmit: SubmitHandler<DiscountFormValues>
	isLoading: boolean
	mode: "create" | "update"
}) {
	const formDefaultValues = {
		ref: defaultValues?.ref ?? "",
		itemSkus: defaultValues?.itemSkus ?? [],
		discountMode: defaultValues?.discountMode ?? ("LOYALTY" as const),
		active: defaultValues?.active ?? true,
		filterMode: defaultValues?.filterMode ?? "BY_DEPARTMENT",
		departments: defaultValues?.departments ?? [],
		activeDateRange: defaultValues?.activeDateRange ?? [null, null],
		validOn: "BOTH" as const,

		rates: defaultValues?.rates ?? [
			{
				type: "PERCENTAGE" as const,
				amount: 0,
				redeemable: false,
				minPurchase: 0,
				minPurchaseForEligibility: 0,
				couponCode: "",
			},
		],
	}

	const [submitted, setSubmitted] = React.useState(false)
	const handlePreSubmit = React.useCallback(
		(f: DiscountFormValues) => {
			setSubmitted(true)
			onSubmit({
				...f,
				itemSkus: f.filterMode === "BY_ITEM" ? f.itemSkus : [],
				departments: f.filterMode === "BY_DEPARTMENT" ? f.departments : [],
				active: true,
			})
		},
		[onSubmit],
	)

	const formMethods = useForm<DiscountFormValues>({
		resolver: zodResolver(DiscountFormSchema),
		values: formDefaultValues,
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	return (
		<form onSubmit={formMethods.handleSubmit(handlePreSubmit)}>
			<RouterPrompt when={!submitted} />
			<M.Center>
				<M.Stack spacing={6} align="start" maw={900}>
					<Flex justify="space-between" align="center" w="full">
						<M.Title order={2}>Discount</M.Title>
						<HelpSectionButton />
					</Flex>
					<FormProvider {...formMethods}>
						<M.Stack spacing={16}>
							<Mc
								as={M.TextInput}
								label="Ref"
								name="ref"
								required
								disabled={mode === "update"}
							/>
							<Mc
								as={M.Select}
								label="Discount mode"
								name="discountMode"
								data={[
									{ label: "Loyalty", value: "LOYALTY" },
									{ label: "Promotion", value: "PROMOTION" },
									{ label: "Coupon", value: "COUPON" },
									{ label: "Seasonal", value: "SEASONAL" },
								]}
								required
								disabled={mode === "update"}
							/>
							<ActiveDateRangeField mode={mode} />
							<RatesSection mode={mode} />
						</M.Stack>
						<AddItemsSection mode={mode} />
						<M.Divider color="gray.1" />
						<M.Button type="submit" w="100%" loading={isLoading}>
							Submit
						</M.Button>
					</FormProvider>
				</M.Stack>
			</M.Center>
		</form>
	)
}

function HelpSectionButton() {
	const [opened, { open, close }] = useDisclosure()

	return (
		<>
			<button
				type="button"
				className={circle({ size: "6", bg: "gray.100", p: "1" })}
				onClick={open}
			>
				<HelpOutlineIcon className={css({ color: "gray.500" })} />
			</button>
			<M.Drawer
				opened={opened}
				onClose={close}
				position="right"
				title="Help"
				classNames={{
					title: css({
						color: "slate.600",
						fontWeight: "medium",
						fontSize: "sm",
						bg: "slate.100",
						rounded: "full",
						px: "2",
						py: "1",
					}),
					content: css({
						"& :where(p)": {
							color: "slate.800",
						},
						"& p + div, & p + p, & div + p": {
							mt: "3",
						},
						"& code": {
							bg: "slate.100",
						},
					}),
				}}
			>
				<M.Title
					order={2}
					fz={token("fontSizes.lg")}
					fw="500"
					c={token("colors.slate.700")}
					mb={token("spacing.3")}
				>
					Seasonal discounts
				</M.Title>
				<p>
					To create a seasonal discount, select <M.Code>Seasonal</M.Code> in{" "}
					<M.Code>Discount mode</M.Code>.
				</p>
				<p>
					Set <M.Code>Min. purchase</M.Code> to the minimum order total amount
					needed to be eligible for the discount.
				</p>
				<p>
					The customer will only be eligible for the discount rate with the
					larger <M.Code>Min. purchase</M.Code> amount that they surpassed with
					their order total.
				</p>
				<M.Text
					bg={token("colors.slate.100")}
					p={token("spacing.3")}
					c={token("colors.slate.700")}
					className={css({
						rounded: "md",
						"& p": { color: "slate.700" },
						"& code": { bg: "slate.200!" },
					})}
				>
					<M.Text span fw="500" c={token("colors.slate.700")}>
						Example:{" "}
					</M.Text>
					<span>You created a season discount with the following rates:</span>
					<M.Table className={css({ "& *": { color: "slate.700" } })}>
						<thead>
							<tr className={css({ "& > th": { fontWeight: "500!" } })}>
								<th>#</th>
								<th>Min. purchase</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Discount rate 1</td>
								<td>$50</td>
							</tr>
							<tr>
								<td>Discount rate 2</td>
								<td>$100</td>
							</tr>
							<tr>
								<td>Discount rate 3</td>
								<td>$150</td>
							</tr>
						</tbody>
					</M.Table>
					<M.Text component="p" my={token("spacing.3")}>
						These are the discount rates that the customer will be eligible for
						depending on their order&apos;s total amount:
					</M.Text>
					<M.Table className={css({ "& *": { color: "slate.700" } })}>
						<thead>
							<tr className={css({ "& > th": { fontWeight: "500!" } })}>
								<th>#</th>
								<th>Order total</th>
								<th>Discount rate #</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td>$80</td>
								<td>Discount rate 1</td>
							</tr>
							<tr>
								<td>2</td>
								<td>$100</td>
								<td>Discount rate 2</td>
							</tr>
							<tr>
								<td>3</td>
								<td>$300</td>
								<td>Discount rate 3</td>
							</tr>
						</tbody>
					</M.Table>
					<M.Text component="p" my={token("spacing.3")}>
						Note that after the customer has been eligible for a discount rate,
						they will not be eligible for another discount rate from the same{" "}
						<M.Code>Ref</M.Code>.
					</M.Text>
				</M.Text>
				<p>
					Seasonal discounts allow you to create discounts that are only valid
					in store, on e-commerce, or both. This is changed by selecting the{" "}
					<M.Code>Valid on</M.Code> option in each rate.
				</p>
				<p>
					All seasonal discounts are redeemable, so you cannot turn of the{" "}
					<M.Code>Redeemable</M.Code> option.
				</p>
			</M.Drawer>
		</>
	)
}

interface ActiveDateRangeFieldProps {
	mode: "create" | "update"
}

function ActiveDateRangeField(props: ActiveDateRangeFieldProps) {
	const { control } = useFormContext<DiscountFormValues>()
	const discountMode = useWatch({ control, name: "discountMode" })

	return discountMode === "SEASONAL" ? (
		<Mc
			as={DatePickerInput}
			name="activeDateRange"
			label="Active date range"
			type="range"
			numberOfColumns={2}
			required
			disabled={props.mode === "update"}
		/>
	) : null
}

interface RatesSectionProps {
	mode: "create" | "update"
}

function RatesSection(props: RatesSectionProps) {
	const form = useFormContext<DiscountFormValues>()

	const ratesField = useFieldArray({
		control: form.control,
		name: "rates",
	})

	const mode = useWatch({
		control: form.control,
		name: "discountMode",
	})

	const formWatch = form.watch
	const formGetValues = form.getValues
	useEffect(() => {
		const sub = formWatch((values, { name }) => {
			if (name == null) {
				return
			}

			// Check if any part of any rate has changed or the discount mode
			if (name.startsWith("rates") || name === "discountMode") {
				if (values.discountMode === "PROMOTION") {
					const currentRates = formGetValues("rates")

					// Force shipping percentage to be 100%
					ratesField.replace(
						currentRates.map((rate) => {
							if (rate.type === "SHIPPING_PERCENTAGE") {
								return {
									...rate,
									// Force shipping percentage to be 100%
									amount: 100,
								}
							}
							return rate
						}),
					)
				}
			}

			if (name === "discountMode") {
				const currentRates = formGetValues("rates")

				// Update discount types if the mode changes to coupon
				// in case the discount type is not supported yet.
				if (values.discountMode === "COUPON") {
					ratesField.replace(
						currentRates.map((rate) => {
							if (CouponDiscountTypes.map((t) => t.value).includes(rate.type)) {
								// Leave the field as is if the discount type is supported for
								// this mode
								return rate
							}

							return {
								...rate,
								type: "SHIPPING_PERCENTAGE",
							}
						}),
					)
				} else if (values.discountMode === "SEASONAL") {
					ratesField.replace(
						currentRates.map((rate) => {
							if (
								SeasonalDiscountTypes.map((t) => t.value).includes(rate.type)
							) {
								// Leave the field as is if the discount type is supported for
								// this mode
								return rate
							}

							return {
								...rate,
								type: "ORDER_SUBTOTAL_PERCENTAGE",
								redeemable: true,
							}
						}),
					)
				} else {
					ratesField.replace(
						currentRates.map((rate) => {
							const discountTypeAppliesToPromotion =
								values.discountMode === "PROMOTION" &&
								PromotionDiscountTypes.map((t) => t.value).includes(rate.type)

							const discountTypeAppliesToRegularDiscount =
								RegularDiscountTypes.map((t) => t.value).includes(rate.type)

							if (
								discountTypeAppliesToPromotion ||
								discountTypeAppliesToRegularDiscount
							) {
								// Leave the field as is if the discount type is supported for
								// this mode
								return {
									...rate,
								}
							}

							return {
								...rate,
								type: "PERCENTAGE",
							}
						}),
					)
				}
			}
		})
		return () => sub.unsubscribe()
	}, [formGetValues, formWatch, ratesField])

	return (
		<>
			{ratesField.fields.map((field, i) => {
				return (
					<M.Group noWrap align="end" key={field.id}>
						<Mc
							as={M.NativeSelect}
							data={
								mode === "COUPON"
									? CouponDiscountTypes
									: mode === "SEASONAL"
										? SeasonalDiscountTypes
										: mode === "PROMOTION"
											? PromotionDiscountTypes
											: RegularDiscountTypes
							}
							label="Type"
							name={`rates.${i}.type`}
							required
							w="100%"
							disabled={props.mode === "update"}
						/>
						<Mc
							as={M.NumberInput}
							label="Amount"
							name={`rates.${i}.amount`}
							precision={2}
							required
							w="100%"
							disabled={props.mode === "update"}
						/>
						<Mc
							as={M.NumberInput}
							label="Min purchase for redeeming"
							name={`rates.${i}.minPurchase`}
							precision={2}
							w="100%"
						/>
						<Mc
							as={M.NumberInput}
							label="Min purchase for eligibility"
							name={`rates.${i}.minPurchaseForEligibility`}
							precision={2}
							w="100%"
							classNames={{
								root: css({
									display:
										mode === "SEASONAL" || mode === "COUPON" ? "block" : "none",
								}),
							}}
						/>
						<Mc
							as={M.Select}
							name="validOn"
							label="Valid on"
							disabled={props.mode === "update"}
							data={[
								{
									label: "Both",
									value: "BOTH",
								},
								{
									label: "E-Commerce",
									value: "ECOMMERCE",
								},
								{
									label: "In store",
									value: "IN_STORE",
								},
							]}
							w="100%"
							display={mode === "SEASONAL" ? "block" : "none"}
						/>
						<Mc
							as={M.Switch}
							label="Redeemable"
							name={`rates.${i}.redeemable`}
							disabled={props.mode === "update" || mode === "SEASONAL"}
						/>
						<M.ActionIcon
							onClick={() => {
								ratesField.remove(i)
							}}
						>
							<DeleteIcon />
						</M.ActionIcon>
					</M.Group>
				)
			})}
			<M.Button
				color="gray.1"
				c="dark.4"
				onClick={() => {
					ratesField.append({
						type:
							mode === "COUPON"
								? "SHIPPING_PERCENTAGE"
								: mode === "SEASONAL"
									? "ORDER_SUBTOTAL_PERCENTAGE"
									: "PERCENTAGE",
						amount: 0,
						redeemable: false,
						minPurchase: 0,
						minPurchaseForEligibility: 0,
					})
				}}
			>
				Add rate
			</M.Button>
		</>
	)
}

interface AddItemsSectionProps {
	mode: "create" | "update"
}
function AddItemsSection({ mode }: AddItemsSectionProps) {
	const FILTER_MODE_DATA = [
		{
			label: "By department",
			value: "BY_DEPARTMENT",
		},
		{
			label: "By items",
			value: "BY_ITEM",
		},
		{
			label: "All items",
			value: "ALL",
		},
	]

	const form = useFormContext<DiscountFormValues>()
	const filterMode = useWatch({
		control: form.control,
		name: "filterMode",
	})

	const discountMode = useWatch({
		control: form.control,
		name: "discountMode",
	})

	if (discountMode === "SEASONAL") {
		return null
	}

	return (
		<div className="mt-4 grid w-full gap-y-4">
			<p className="text-xl font-semibold">Add items</p>
			<Mc
				as={M.Select}
				name="filterMode"
				label="Filter mode"
				data={FILTER_MODE_DATA}
				disabled={mode === "update"}
			/>
			{filterMode === "BY_DEPARTMENT" ? (
				<div className="grid gap-y-2">
					<DepartmentsField />
					<DepartmentsTable />
				</div>
			) : filterMode === "BY_ITEM" ? (
				<div className="grid gap-y-2">
					<SearchableFields />
					<ItemSkusTable />
				</div>
			) : null}
		</div>
	)
}

function DepartmentsField() {
	const form = useFormContext<DiscountFormValues>()
	const ctrl = useController({
		control: form.control,
		name: "departments",
	})
	const [query, setQuery] = useState("")
	const { data, isLoading } = trpc.department.pos.search.useQuery(
		{
			query,
			limit: 10,
		},
		{
			onError(error) {
				showNotification({
					title: "Failed to get departments",
					message: error.message,
					color: "red",
				})
			},
		},
	)

	const selectData = React.useMemo(
		() =>
			data?.map((e) => ({
				label: e.name,
				value: e.id.toString(),
				department: e,
			})) ?? [],
		[data],
	)

	const handleChange = (selectedId: string | null) => {
		if (selectedId == null) return

		const selectedEntry = selectData.find((e) => e.value === selectedId)
		if (!selectedEntry) {
			throw new Error("selected non existing data")
			// ^ Theoretically, this should never happen.
		}

		// if ya esta agregado no agregarlo
		const check = ctrl.field.value.find(
			(e) => e.id === selectedEntry.department.id,
		)
		if (check) {
			return
		}

		ctrl.field.onChange([...ctrl.field.value, selectedEntry.department])
	}

	return (
		<M.Select
			data={selectData}
			searchable
			placeholder="Search by department..."
			rightSection={isLoading && <SpinnerIcon />}
			value={null}
			onChange={handleChange}
			onSearchChange={setQuery}
			filter={() => true}
		/>
	)
}

function SearchableFields() {
	const form = useFormContext<DiscountFormValues>()

	function addItemSkus(itemSkus: Array<ItemSku>) {
		const existingItemSkus = form.getValues("itemSkus")
		const newItemSkus = [...existingItemSkus, ...itemSkus]
		// ^ Items might be duplicated.
		const newItemSkusMap = new Map(newItemSkus.map((isku) => [isku.sku, isku]))
		// ^ Convert to a map where the key is the sku. This effectively de-duplicates by sku.

		const deduplicatedItemSkus = Array.from(newItemSkusMap.values())
		form.setValue("itemSkus", deduplicatedItemSkus)
	}

	return (
		<div className="grid grid-cols-3 gap-x-4">
			<ItemSkusSearchField onSelect={addItemSkus} />
			<DepartmentsSearchField onSelect={addItemSkus} />
			<CategoriesSearchField onSelect={addItemSkus} />
		</div>
	)
}

interface SearchableFieldProps {
	onSelect: (itemSkus: Array<ItemSku>) => void
}

const ItemSkusSearchField = (props: SearchableFieldProps) => {
	const [query, setQuery] = useState("")
	const { data, isLoading } = trpc.itemSku.pos.search.useQuery(
		{
			query: `\\"${query}\\"`,
			limit: 10,
		},
		{
			onError(error) {
				showNotification({
					title: "Failed to get item skus",
					message: error.message,
					color: "red",
				})
			},
		},
	)

	const selectData = React.useMemo(
		() =>
			data?.map((e) => ({
				label: `${e.sku} - ${e.title}`,
				value: e.id.toString(),
				itemSku: e,
				// ^ Add the whole itemSku so that we can refer to it on selection
			})) ?? [],
		[data],
	)

	const handleSelect = (itemSku: ItemSku) => {
		props.onSelect([itemSku])
	}

	const handleChange = (selectedId: string | null) => {
		if (selectedId == null) return

		const selectedEntry = selectData.find((e) => e.value === selectedId)
		if (!selectedEntry) {
			throw new Error("selected non existing data")
			// ^ Theoretically, this should never happen.
		}

		handleSelect(selectedEntry.itemSku)
	}

	return (
		<M.Select
			data={selectData}
			searchable
			placeholder="Search by item sku..."
			rightSection={isLoading && <SpinnerIcon />}
			value={null}
			// ^ We only use this select as search field, so we don't care about
			// showing a value.
			onChange={handleChange}
			onSearchChange={setQuery}
			filter={() => true}
		/>
	)
}

const DepartmentsSearchField = (props: SearchableFieldProps) => {
	const [query, setQuery] = useState("")
	const tctx = trpc.useContext()
	const { data, isLoading } = trpc.department.pos.search.useQuery(
		{
			query,
			limit: 10,
		},
		{
			onError(error) {
				showNotification({
					title: "Failed to get departments",
					message: error.message,
					color: "red",
				})
			},
		},
	)

	const selectData = React.useMemo(
		() =>
			data?.map((e) => ({
				label: e.name,
				value: e.id.toString(),
				department: e,
				// ^ Add the whole department so that we can refer to it on selection
			})) ?? [],
		[data],
	)

	const handleSelect = async (department: Department) => {
		const itemSkus = await tctx.client.department.pos.itemSkus.query({
			departmentId: department.id,
		})
		props.onSelect(itemSkus)
	}

	const handleChange = (selectedId: string | null) => {
		if (selectedId == null) return

		const selectedEntry = selectData.find((e) => e.value === selectedId)
		if (!selectedEntry) {
			throw new Error("selected non existing data")
			// ^ Theoretically, this should never happen.
		}

		handleSelect(selectedEntry.department)
	}

	return (
		<M.Select
			data={selectData}
			searchable
			placeholder="Search by department..."
			rightSection={isLoading && <SpinnerIcon />}
			value={null}
			// ^ We only use this select as search field, so we don't care about
			// showing a value.
			onChange={handleChange}
			onSearchChange={setQuery}
			filter={() => true}
		/>
	)
}

const CategoriesSearchField = (props: SearchableFieldProps) => {
	const [query, setQuery] = useState("")
	const { data, isLoading } = trpc.category.pos.search.useQuery(
		{
			query,
			limit: 10,
		},
		{
			onError(error) {
				showNotification({
					title: "Failed to get categories",
					message: error.message,
					color: "red",
				})
			},
		},
	)

	const selectData = React.useMemo(
		() =>
			data?.map((e) => ({
				label: e.name,
				value: e.id.toString(),
				category: e,
				// ^ Add the whole category so that we can refer to it on selection
			})) ?? [],
		[data],
	)

	const tctx = trpc.useContext()
	const handleSelect = async (category: Category) => {
		const itemSkus = await tctx.client.category.pos.itemSkus.query({
			categoryId: category.id,
		})

		props.onSelect(itemSkus)
	}

	const handleChange = (selectedId: string | null) => {
		if (selectedId == null) return

		const selectedEntry = selectData.find((e) => e.value === selectedId)
		if (!selectedEntry) {
			throw new Error("selected non existing data")
			// ^ Theoretically, this should never happen.
		}

		handleSelect(selectedEntry.category)
	}

	return (
		<M.Select
			data={selectData}
			searchable
			placeholder="Search by category..."
			rightSection={isLoading && <SpinnerIcon />}
			value={null}
			// ^ We only use this select as search field, so we don't care about
			// showing a value.
			onChange={handleChange}
			onSearchChange={setQuery}
			filter={() => true}
		/>
	)
}

function ItemSkusTable() {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const form = useFormContext<DiscountFormValues>()
	const itemSkus = useWatch({
		control: form.control,
		name: "itemSkus",
	})

	const handleExportCsv = async () => {
		setIsLoading(true)
		const headers = [
			{ label: "SKU", value: "sku" },
			{ label: "Title", value: "title" },
		]

		const csv = await generateCsv({
			headers,
			data: itemSkus,
		})

		setIsLoading(false)
		FileSaver.saveAs(
			new Blob([csv], { type: "text/csv" }),
			"items-discount.csv",
		)

		reportUserSuccess({
			title: "The csv file has been exported, download should start soon",
		})
	}

	const table = useMantineReactTable({
		data: itemSkus,
		columns: itemSkusColumns,
		initialState: { density: "xs" },
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						onClick={handleExportCsv}
						loading={isLoading}
						type="button"
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return <MantineReactTable table={table} />
}

const itemSkusColumns: Array<MRT_ColumnDef<ItemSku>> = [
	{
		id: "actions",
		header: "Actions",
		Cell(props) {
			const form = useFormContext<DiscountFormValues>()

			function deleteItemSku() {
				const sku = props.row.original.sku
				const existingItemSkus = form.getValues("itemSkus")
				const newItemSkus = existingItemSkus.filter((isku) => isku.sku !== sku)
				form.setValue("itemSkus", newItemSkus)
			}

			return (
				<M.Menu>
					<M.Menu.Target>
						<M.ActionIcon>
							<MenuOutlineIcon />
						</M.ActionIcon>
					</M.Menu.Target>
					<M.Menu.Dropdown>
						<M.Menu.Item icon={<DeleteIcon />} onClick={deleteItemSku}>
							Delete
						</M.Menu.Item>
					</M.Menu.Dropdown>
				</M.Menu>
			)
		},
	},
	{
		id: "image",
		header: "Image",
		Cell(props) {
			const image =
				props.row.original.defaultImage ??
				props.row.original.images[0] ??
				placeholderImage

			return (
				<img
					src={image}
					alt=""
					className="aspect-square h-[48px] w-[48px] object-cover"
				/>
			)
		},
	},
	{
		accessorKey: "sku",
		header: "SKU",
		sortingFn: "alphanumeric",
	},
	{
		accessorKey: "title",
		header: "Title",
	},
]

const DepartmentsTable = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const form = useFormContext<DiscountFormValues>()
	const departments = useWatch({
		control: form.control,
		name: "departments",
	})

	const handleExportCsv = async () => {
		setIsLoading(true)
		const headers = [{ label: "name", value: "name" }]

		const csv = await generateCsv({
			headers,
			data: departments,
		})

		setIsLoading(false)
		FileSaver.saveAs(
			new Blob([csv], { type: "text/csv" }),
			"departments-discount.csv",
		)

		reportUserSuccess({
			title: "The csv file has been exported, download should start soon",
		})
	}

	const table = useMantineReactTable({
		data: departments,
		columns: departmentsColumns,
		initialState: { density: "xs" },
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						onClick={handleExportCsv}
						loading={isLoading}
						type="button"
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return <MantineReactTable table={table} />
}

const departmentsColumns: Array<MRT_ColumnDef<Department>> = [
	{
		id: "actions",
		header: "Actions",
		size: 100,
		Cell(props) {
			const form = useFormContext<DiscountFormValues>()

			function deleteDepartment() {
				const id = props.row.original.id
				const existingDepartments = form.getValues("departments")
				const newDepartments = existingDepartments.filter((d) => d.id !== id)
				form.setValue("departments", newDepartments)
			}

			return (
				<styled.div display="flex">
					<M.ActionIcon onClick={deleteDepartment} color="red">
						<CloseIcon />
					</M.ActionIcon>
				</styled.div>
			)
		},
	},
	{
		accessorKey: "name",
		header: "Name",
		sortingFn: "alphanumeric",
	},
]
