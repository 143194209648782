import { trpc } from "#/trpc.js"
import { reportUserError, reportUserSuccess } from "#/util/index.js"
import {
	CreatePoNewItemsForm,
	CreatePoNewItemsFormSchema,
} from "./NewItemsForm.js"
import * as M from "@mantine/core"
import { useNavigate, useParams } from "react-router"

export const UpdatePoNewItems = () => {
	const params = useParams() as any
	const id = Number(params.poId)
	const navigate = useNavigate()

	const ctx = trpc.useContext()
	const poQuery = trpc.v2_5.purchaseOrder.getById.useQuery(
		{
			id,
		},
		{
			cacheTime: 0,
			refetchOnWindowFocus: false,
		},
	)
	const updatePoNewItemsMutation =
		trpc.v2_5.purchaseOrder.updatePurchaseOrder.useMutation({
			onSuccess() {
				reportUserSuccess({
					title: "Purchase order updated",
				})
				ctx.purchaseOrder.invalidate()
				ctx.purcharseOrderItemSku.invalidate()
				ctx.purchaseOrderShipping.invalidate()
				ctx.v2_5.purchaseOrder.invalidate()
				navigate("/inventory/upcoming-items")
			},
			onError(error) {
				reportUserError({
					title: "Purchase order not updated",
					message: error.message,
				})
			},
		})

	function handleSubmit(status: "DRAFT" | "UPCOMING") {
		return function (values: CreatePoNewItemsFormSchema) {
			if (status === "UPCOMING") {
				const verify = values.itemSkus.find(
					(itemSku) => itemSku.shippingQuantity === 0,
				)
				if (verify) {
					reportUserError({
						title: "Purchase order not created",
						message: "Shipping quantity must be greater than 0",
					})
					return
				}
			}

			if (status === "UPCOMING" && values.itemSkus.length === 0) {
				reportUserError({
					title: "Purchase order not created",
					message: "Please add at least one item",
				})
				return
			}

			updatePoNewItemsMutation.mutate({
				id,
				dutyCost: values.dutyCost,
				status,
				type: "NEW_ITEMS",
				purchaseOrderShipping: values.shippingInformation.entries.map(
					(entry) => ({
						shippingCompany: entry.company,
						shippingCost: entry.cost,
						shippingNumber: entry.trackingNumber,
					}),
				),

				purchaseOrderItemSkus: values.itemSkus.map((itemSku) => ({
					itemSkuId: itemSku.id,
					expectedQuantity: itemSku.shippingQuantity,
					cost: itemSku.cost,
					unitCost: itemSku.cost,
				})),
			})
		}
	}

	const po = poQuery.data
	return !po || poQuery.isLoading || !poQuery.isFetchedAfterMount ? (
		<M.Center m={32}>
			<M.Loader />
		</M.Center>
	) : (
		<CreatePoNewItemsForm
			onSubmit={handleSubmit}
			defaultValues={{
				dutyCost: po.dutyCost?.toNumber() ?? 0,
				itemSkus: po.purchaseOrderItemSku.map((poItemSku) => ({
					id: poItemSku.itemSkuId,
					title: poItemSku.itemSku.title,
					sku: poItemSku.itemSku.sku,
					presentation: `${poItemSku.itemSku.presentationValue ?? "-"} ${
						poItemSku.itemSku.presentationType ?? "-"
					}`,
					size: `${poItemSku.itemSku.width ?? "-"}x${
						poItemSku.itemSku.height ?? "-"
					}x${poItemSku.itemSku.length ?? "-"}`,
					cost: poItemSku.cost?.toNumber() ?? 0,
					shippingQuantity: poItemSku.expectedQuantity,
				})),
			}}
		/>
	)
}
