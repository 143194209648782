import { styled } from "#/css/jsx"
import { trpc } from "#/trpc"
import { Switch } from "@mantine/core"

export function FeatureFlagsPage() {
	const { data: featureFlagsJoin } =
		trpc.v2_5.featureFlags.getAllByUser.useQuery()

	const tctx = trpc.useContext()
	const { mutate: toggleFeatureFlag, isLoading: isToggling } =
		trpc.v2_5.featureFlags.toggle.useMutation({
			onSuccess() {
				return tctx.v2_5.featureFlags.invalidate()
			},
		})

	const activeFeatureFlags = [
		...(featureFlagsJoin?.featureFlags
			?.filter((d) => d.active)
			.map((d) => d.id) ?? []),
		...(featureFlagsJoin?.featureFlagsOnUsers.map((d) => d.featureId) ?? []),
	]

	function handleToggle(flagId: number, privacy: "PUBLIC" | "PRIVATE") {
		const isCurrentlyActive = activeFeatureFlags.includes(flagId)
		toggleFeatureFlag({
			id: flagId,
			status: isCurrentlyActive ? "inactive" : "active",
			privacy,
		})
	}

	return (
		<styled.div
			display="flex"
			gap="1rem"
			flexDir={{
				base: "column",
				md: "row",
			}}
		>
			<styled.div>
				<styled.h3 mb="1rem">Private</styled.h3>
				<styled.div
					display="grid"
					gridTemplateColumns="repeat(1, 1fr)"
					gridGap="1rem"
				>
					{featureFlagsJoin?.featureFlags
						?.filter((f) => f.privacy === "PRIVATE")
						.map((flag) => {
							return (
								<styled.div
									key={flag.id}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									bg="white"
									border="solid 1px token(colors.slate.200)"
									p="4"
									rounded="md"
								>
									<div>
										<div>{flag.name}</div>
										<styled.div c="slate.500" fs="sm">
											{flag.description}
										</styled.div>
									</div>
									<div>
										<Switch
											disabled={isToggling}
											checked={activeFeatureFlags.includes(flag.id)}
											onClick={() => handleToggle(flag.id, flag.privacy)}
										/>
									</div>
								</styled.div>
							)
						})}
				</styled.div>
			</styled.div>
			<styled.div>
				<styled.h3 mb="1rem">Public</styled.h3>
				<styled.div
					display="grid"
					gridTemplateColumns="repeat(1, 1fr)"
					gridGap="1rem"
				>
					{featureFlagsJoin?.featureFlags
						?.filter((f) => f.privacy === "PUBLIC")
						.map((flag) => {
							return (
								<styled.div
									key={flag.id}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									bg="white"
									border="solid 1px token(colors.slate.200)"
									p="4"
									rounded="md"
								>
									<div>
										<div>{flag.name}</div>
										<styled.div c="slate.500" fs="sm">
											{flag.description}
										</styled.div>
									</div>
									<div>
										<Switch
											disabled={isToggling}
											checked={activeFeatureFlags.includes(flag.id)}
											onClick={() => handleToggle(flag.id, flag.privacy)}
										/>
									</div>
								</styled.div>
							)
						})}
				</styled.div>
			</styled.div>
		</styled.div>
	)
}
