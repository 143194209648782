import { SearchButton } from "#/components@v2_5/search-button"
import { HStack, styled } from "#/css/jsx"
import { useState } from "react"
import { CartsNotificationsTable } from "./table"
import { Filter, type FilterKey } from "./$common"
import { Button, Checkbox, Dropdown } from "@gt/ui"
import { css } from "#/css/css"
import { MdFilterAlt } from "react-icons/md"
import { useNavigate } from "react-router"

export const CartsNotifications = () => {
	const navigate = useNavigate()

	const [query, setQuery] = useState<string | null>(null)
	const [filters, setFilters] = useState<Record<FilterKey, boolean>>(
		() =>
			Object.fromEntries(
				Object.keys(Filter).map((key) => [
					key,
					key === "COMPLETE" ? true : false,
				]),
			) as any,
	)

	function selectFilter(filter: FilterKey) {
		setFilters((old) => ({
			...old,
			[filter]: !old[filter],
		}))
	}
	return (
		<>
			<HStack justify="space-between" gap={3} flexWrap="nowrap">
				<HStack width="100%" gap={3}>
					<SearchButton setQuery={setQuery} />
					<FilterComponent filters={filters} selectFilter={selectFilter} />
				</HStack>
				<Button
					className={css({
						width: "full",
						maxWidth: "180px",
					})}
					onClick={() => {
						navigate("/reports/notification-upsell")
					}}
				>
					Go to metrics
				</Button>
			</HStack>
			<styled.div marginTop={15}>
				<CartsNotificationsTable
					filter={
						Object.entries(filters)
							.filter(([, active]) => active)
							.map(([filter]) => filter) as any
					}
					search={query}
				/>
			</styled.div>
		</>
	)
}

interface FilterComponent {
	filters: Record<FilterKey, boolean>
	selectFilter: (filter: FilterKey) => void
}

const FilterComponent = (props: FilterComponent) => {
	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button variant="secondary">
					<MdFilterAlt
						className={css({
							marginRight: 2,
						})}
						fontSize="20px"
					/>
					Filter by
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content
				className={css({
					display: "grid",
					gridGap: "0.1rem",
				})}
				loop
			>
				{Object.entries(props.filters).map(([filterKey, isFilterActive], i) => (
					<Dropdown.Item
						key={i}
						className={css({
							display: "flex",
							alignItems: "center",
							gap: 2,
						})}
						onSelect={(e) => {
							e.preventDefault()
							props.selectFilter(filterKey as FilterKey)
						}}
					>
						<Checkbox checked={isFilterActive} />
						<span>{Filter[filterKey]}</span>
					</Dropdown.Item>
				))}
			</Dropdown.Content>
		</Dropdown.Root>
	)
}
