import logoSrc from "#/images/monochromatic-logo-ticket.png"
import { applyDiscountV2 } from "#/scenes/Sales/util/index.js"
import { RouterOutputs, gTrpc } from "#/trpc.js"
import Ticket from "./ticket.js"
import { showNotification } from "@mantine/notifications"
import { format } from "date-fns"
import Decimal from "decimal.js"
import EscPosEncoder from "esc-pos-encoder"
import _ from "lodash"
import type { CartAllFields as CartV2 } from "server"
import { match } from "ts-pattern"

type Order = NonNullable<RouterOutputs["order"]["getById"]>

type OrderV2 = Omit<Order, "userBasicInfo" | "packages" | "orderItemSku"> & {
	userBasicInfo?: {
		firstName: string
		lastName?: string | null
	} | null
	orderItemSku: Omit<Order["orderItemSku"][number], "packageItem">[]
}

const columns = 48

export async function encodeTicketToEscpos(
	ticket: Ticket,
	withStoreLocation = false,
	type: "ORDER" | "RETURN",
): Promise<Uint8Array> {
	let encoder = new EscPosEncoder()

	const date = format(ticket?.createdAt ?? new Date(), "MM/dd/yyyy")

	// STORE    WORKSTATION
	const store = `Store: ${ticket.store}`
	const workstation = `Workstation: ${ticket.workstation}`

	const logo = await new Promise<HTMLImageElement>((resolve) => {
		const logo = new Image()
		logo.crossOrigin = "anonymous"
		logo.src = logoSrc
		logo.onload = () => resolve(logo)
	})

	const orderType = match(ticket.orderType)
		.with("IN_STORE", () => "Store")
		.with("ONLINE", () => "Delivery")
		.with("PICKUP", () => "Pickup")
		.otherwise(() => "Store")

	encoder = encoder
		.initialize()
		.line(leftRight(date, `Order: ${ticket.order.toString()}`))
		.line(leftRight(store, workstation))
		.align("center")
		.line("REPRINTED")
		.image(logo, 280, 120)
		.line("GOLD TREE")
		.bold(false)
		.line("755 NW 72nd AVE PLAZA 33")
		.line("MIAMI, FL, 33126")
		.line("TEL +1 (786) 7177131")
		.newline()
		.align("left")
		.bold(true)
		.line(`Bill to: ${ticket.customer}`)

	if (ticket.cashier) {
		encoder = encoder.bold(true).line(`Cashier: ${ticket.cashier}`)
	}

	if (ticket.address) {
		encoder = encoder
			.newline()
			.bold(true)
			.text(`Shipping: `)
			.bold(false)
			.text(ticket.address)
			.newline()
	}

	encoder = encoder
		.bold(true)
		.line(`Type: ${orderType}`)
		.bold(false)
		.newline()
		.newline()
		.underline(true)
		.line(
			row([
				{
					text: "Item #",
					cols: 16,
				},
				{
					text: "",
					cols: 1,
				},
				{
					text: "Qty",
					cols: 3,
				},
				{
					text: "",
					cols: 3,
				},
				{
					text: "D%",
					cols: 3,
				},
				{
					text: "",
					cols: 3,
				},
				{
					text: "Price",
					cols: 8,
				},
				{
					text: "",
					cols: 3,
				},
				{
					text: "Total",
					cols: 8,
				},
			]),
		)
		.underline(false)

	const products = _.sortBy(ticket.products, (item) => item?.storeLocation ?? "")

	for (const product of products) {
		const itemCell = `${product.sku} ${product.title}`
		let itemFirstRow = itemCell.substring(0, 17)
		let itemSecondRow = itemCell.substring(17, 34)
		if (withStoreLocation) {
			itemFirstRow = product.sku
			if (product.storeLocation) {
				let storeLocation = product.storeLocation
				const storeLocationComponents = product.storeLocation.split(" ")
				if (storeLocationComponents.length >= 2) {
					const storeLocationComponent_ = storeLocationComponents[0]
						.replace(/\d/g, "")
						.trim()

					storeLocation = `${storeLocationComponent_}${storeLocationComponents[1]}`
				}
				itemFirstRow += ` SL: ${storeLocation}`
			}
			itemSecondRow = product.title.substring(0, 17)
		}
		encoder = encoder
			.line(
				row([
					{
						text: itemFirstRow,
						cols: 16,
					},
					{
						text: "",
						cols: 1,
					},
					{
						text: product.qty.toString().padStart(3),
						cols: 3,
					},
					{
						text: "",
						cols: 3,
					},
					{
						text: product.discount ? `${product.discount} %` : "",
						cols: 3,
					},
					{
						text: "",
						cols: 3,
					},
					{
						text: `$${product.price.toFixed(2)}`,
						cols: 8,
					},
					{
						text: "",
						cols: 3,
					},
					{
						text: `$${product.total.toFixed(2)}`,
						cols: 8,
					},
				]),
			)
			.line(
				row([
					{
						text: itemSecondRow,
						cols: 16,
					},
					{
						text: "",
						cols: 1,
					},
					{
						text: "",
						cols: 3,
					},
					{
						text: "",
						cols: 3,
					},
					{
						text: "",
						cols: 3,
					},
					{
						text: "",
						cols: 2,
					},
					{
						text: product.discount
							? `$${(
									product.price -
									product.price * (product.discount / 100.0)
								).toFixed(2)}`
							: "",
						cols: 8,
					},
					{
						text: "",
						cols: 3,
					},
					{
						text: "",
						cols: 8,
					},
				]),
			)
	}

	if (ticket.customizedMultipledProducts.length > 0) {
		for (const product of ticket.customizedMultipledProducts) {
			const itemCell = `${product[0].sku} ${product[0].title}`
			let itemFirstRow = itemCell.substring(0, 17)
			let itemSecondRow = itemCell.substring(17, 34)
			if (withStoreLocation) {
				itemFirstRow = product[0].sku
				if (product[0].storeLocation) {
					let storeLocation = product[0].storeLocation
					const storeLocationComponents = product[0].storeLocation.split(" ")
					if (storeLocationComponents.length >= 2) {
						const storeLocationComponent_ = storeLocationComponents[0]
							.replace(/\d/g, "")
							.trim()

						storeLocation = `${storeLocationComponent_}${storeLocationComponents[1]}`
					}
					itemFirstRow += ` SL: ${storeLocation}`
				}
				itemSecondRow = product[0].title.substring(0, 17)
			}

			encoder = encoder
				.newline()
				.bold(true)
				.line(`Custom: Selected Items`)
				.bold(
					product[0].productCustomAttributePrincipal === true ? true : false,
				)
				.line(
					row([
						{
							text: itemFirstRow,
							cols: 16,
						},
						{
							text: "",
							cols: 1,
						},
						{
							text: product[0].qty.toString().padStart(3),
							cols: 3,
						},
						{
							text: "",
							cols: 3,
						},
						{
							text: product[0].discount ? `${product[0].discount} %` : "",
							cols: 3,
						},
						{
							text: "",
							cols: 3,
						},
						{
							text: `$${product[0].price.toFixed(2)}`,
							cols: 8,
						},
						{
							text: "",
							cols: 3,
						},
						{
							text: `$${product[0].total.toFixed(2)}`,
							cols: 8,
						},
					]),
				)
				.bold(
					product[0].productCustomAttributePrincipal === true ? true : false,
				)
				.line(
					row([
						{
							text: itemSecondRow,
							cols: 16,
						},
						{
							text: "",
							cols: 1,
						},
						{
							text: "",
							cols: 3,
						},
						{
							text: "",
							cols: 3,
						},
						{
							text: "",
							cols: 3,
						},
						{
							text: "",
							cols: 2,
						},
						{
							text: product[0].discount
								? `$${(
										product[0].price -
										product[0].price * (product[0].discount / 100.0)
									).toFixed(2)}`
								: "",
							cols: 8,
						},
						{
							text: "",
							cols: 3,
						},
						{
							text: "",
							cols: 8,
						},
					]),
				)
				.bold(false)

			for (const p of product[1]) {
				const itemCell_ = `${p.sku} ${p.title}`
				let itemFirstRow = itemCell_.substring(0, 17)
				let itemSecondRow = itemCell_.substring(17, 34)
				if (withStoreLocation) {
					itemFirstRow = p.sku
					if (p.storeLocation) {
						let storeLocation = p.storeLocation
						const storeLocationComponents = p.storeLocation.split(" ")
						if (storeLocationComponents.length >= 2) {
							const storeLocationComponent_ = storeLocationComponents[0]
								.replace(/\d/g, "")
								.trim()

							storeLocation = `${storeLocationComponent_}${storeLocationComponents[1]}`
						}
						itemFirstRow += ` SL: ${storeLocation}`
					}
					itemSecondRow = p.title.substring(0, 17)
				}

				encoder = encoder
					.newline()
					.bold(true)
					.line(`Custom: Selected Items`)
					.bold(false)
					.line(
						row([
							{
								text: itemFirstRow,
								cols: 16,
							},
							{
								text: "",
								cols: 1,
							},
							{
								text: p.qty.toString().padStart(3),
								cols: 3,
							},
							{
								text: "",
								cols: 3,
							},
							{
								text: p.discount ? `${p.discount} %` : "",
								cols: 3,
							},
							{
								text: "",
								cols: 3,
							},
							{
								text: `$${p.price.toFixed(2)}`,
								cols: 8,
							},
							{
								text: "",
								cols: 3,
							},
							{
								text: `$${p.total.toFixed(2)}`,
								cols: 8,
							},
						]),
					)
					.line(
						row([
							{
								text: itemSecondRow,
								cols: 16,
							},
							{
								text: "",
								cols: 1,
							},
							{
								text: "",
								cols: 3,
							},
							{
								text: "",
								cols: 3,
							},
							{
								text: "",
								cols: 3,
							},
							{
								text: "",
								cols: 2,
							},
							{
								text: p.discount
									? `$${(p.price - p.price * (p.discount / 100.0)).toFixed(2)}`
									: "",
								cols: 8,
							},
							{
								text: "",
								cols: 3,
							},
							{
								text: "",
								cols: 8,
							},
						]),
					)
			}
		}
	}

	let changeString = `$${ticket.change.toFixed(2)}`
	let shippingString = ticket.shipping ? `$${ticket.shipping.toFixed(2)}` : ""
	let couponString =
		ticket.coupon != null ? `$${ticket.coupon.appliedAmount.toFixed(2)}` : ""
	let previousShippingString =
		ticket?.previousShipping != null
			? `$${ticket.previousShipping.toFixed(2)}`
			: ""
	let discountShippingString =
		ticket?.discountShipping != null
			? `$${ticket.discountShipping.toFixed(2)}`
			: ""
	let subtotalString = `$${ticket.subtotal.toFixed(2)}`
	let taxString = `+$${ticket.taxAmount.toFixed(2)}`
	let insuranceString =
		ticket?.insurance != null ? `$${ticket.insurance.toFixed(2)}` : null
	let totalString = `$${ticket.total.toFixed(2)}`
	const maxLength = Math.max(
		subtotalString.length,
		taxString.length,
		totalString.length,
		changeString.length,
		shippingString.length,
		couponString.length,
		previousShippingString.length,
		discountShippingString.length,
		insuranceString?.length ?? 0,
	)
	changeString = changeString.padStart(maxLength, " ")
	shippingString = shippingString.padStart(maxLength, " ")
	subtotalString = subtotalString.padStart(maxLength, " ")
	taxString = taxString.padStart(maxLength, " ")
	totalString = totalString.padStart(maxLength, " ")
	couponString = couponString.padStart(maxLength, " ")
	previousShippingString = previousShippingString.padStart(maxLength, " ")
	discountShippingString = discountShippingString.padStart(maxLength, " ")
	insuranceString = insuranceString?.padStart(maxLength, " ") ?? null

	encoder = encoder.align("right").newline()
	if (ticket.shipping) {
		if (ticket.previousShipping != null && ticket.previousShipping != null) {
			encoder = encoder.line(`Shipping: ${previousShippingString}`)
			encoder = encoder.line(`Discount Shipping: ${discountShippingString}`)
		}

		encoder = encoder.line(`Total Shipping: ${shippingString}`)
	}
	encoder = encoder.line(`Subtotal: ${subtotalString}`)

	if (ticket.coupon != null) {
		encoder = encoder.line(
			`Applied Coupon ${ticket.coupon.amount}: -${couponString}`,
		)
	}

	if (ticket.saleDiscount) {
		encoder = encoder.line(
			`Applied Discount: ${
				ticket.saleDiscount.type === "AMOUNT" ? "$" : ""
			}${ticket.saleDiscount.amount.toFixed(2)}${
				ticket.saleDiscount.type === "PERCENTAGE" ? "%" : ""
			}`,
		)
	}

	encoder = encoder.line(
		`Local Sales Tax    ${ticket.taxPercentage?.toFixed(0)}% Tax: ${taxString}`,
	)

	if (ticket.insurance != null) {
		encoder = encoder.line(`Insurance: $${ticket.insurance.toFixed(2)}`)
	}

	encoder = encoder
		.bold(true)
		.line(
			`${
				type === "RETURN" ? "CREDIT RECEIPT" : "RECEIPT TOTAL"
			}: ${totalString}`,
		)
		.bold(false)
		.align("left")

	for (const payment of ticket.payments) {
		encoder = encoder.line(`   ${payment.method}: $${payment.amount}`)
	}
	encoder = encoder.newline().line(`Change: ${changeString}`)

	// Barcode
	encoder = encoder
		.line("")
		.align("center")
		.barcode(
			ticket.order.toString().padStart(8, "0"),
			// @ts-ignore
			"code128",
			60,
		)
		.line("")
		.align("left")

	encoder = encoder.align("right")
	encoder = encoder
		.line(`Total Sales Discounts:    $${ticket.totalDiscount.toFixed(2)}`)
		.newline()
		.align("left")
		.line(ticket?.note ? `Note: ${ticket.note}` : "")
		.newline()
		.align("center")
		.line("Thanks for shopping with us!")
		.line("All sales are final")
		.line("No exchanges. No returns")

	return encoder.raw(CTL_VT).raw(CTL_VT).raw(HW_INIT).cut().encode()
}

export async function printTicket(
	ticket: Ticket,
	withLocation = false,
	type: "ORDER" | "RETURN",
) {
	const buf = await encodeTicketToEscpos(ticket, withLocation, type)

	await fetch("http://localhost:4455/print", {
		method: "POST",
		headers: {
			"Content-Type": "application/octet-stream",
		},
		body: buf,
	}).catch(() => {
		throw new Error(
			"Could not connect to the printer server. Make sure it's running.",
		)
	})
}

export function orderV2ToTicket(order: OrderV2): Ticket {
	const appliedDiscount =
		order.discountType === "AMOUNT"
			? order.subTotal.sub(order.discountAmount ?? new Decimal(0))
			: order.subTotal.times((order.discountAmount ?? new Decimal(0)).div(100))

	const couponToSubtotal =
		order?.coupon != null &&
		order.coupon.discount.type === "ORDER_SUBTOTAL_PERCENTAGE"
			? {
					amount: `${order.coupon.discount.amount.toFixed(2)}%`,
					appliedAmount: order?.subTotal?.sub(
						applyDiscountV2(order.subTotal, order.coupon.discount),
					),
				}
			: null

	const couponToShipping =
		order?.coupon != null &&
		order.coupon.discount.type === "SHIPPING_PERCENTAGE" &&
		order?.orderShipping?.shippingCost != null
			? {
					previousShipping: order.orderShipping.shippingCost?.div(
						new Decimal(1).sub(order.coupon.discount.amount.div(100)),
					),
					discountShipping: order.orderShipping.shippingCost?.sub(
						order.orderShipping.shippingCost?.div(
							new Decimal(1).sub(order.coupon.discount.amount.div(100)),
						),
					),
				}
			: null

	return {
		order: order?.receiptNumber || 0,
		customer: order?.receiptName
			? order.receiptName
			: order?.customer?.company
				? order.customer.company
				: order.customer
					? `${order.customer?.firstName} ${order.customer?.lastName ?? ""}`
					: order.userBasicInfo
						? `${order.userBasicInfo.firstName} ${order.userBasicInfo.lastName}`
						: "N/A",
		cashier: order?.associated
			? `${order.associated?.firstName ?? ""} ${
					order.associated?.lastName ?? ""
				}`
			: null,
		store: "1",
		workstation: 1,
		products: order.orderItemSku
			.filter(
				(i) =>
					i.isCustomAttributeMultipleItem === false &&
					i.orderItemSkuCustomAttribute.length === 0,
			)
			.map(({ itemSku, ...item }) => ({
				sku: itemSku.sku.toString(),
				title: itemSku.title,
				qty: item.quantity,
				discount: item?.discountAmount?.toNumber() ?? 0,
				price: item.price.toNumber(),
				total: item.total.toNumber(),
				storeLocation:
					itemSku.itemSkuStock?.find((it) => it.filialId === order.filialId)
						?.storeLocation ?? null,
				productCustomAttributePrincipal: false,
			})),
		customizedMultipledProducts:
			order?.orderItemSku
				?.filter(
					(o) =>
						o.orderItemSkuCustomAttribute.length > 0 &&
						o.orderItemSkuToCustomAttributeMultipleItem.length > 0,
				)
				.map((o) => {
					const associatedItems =
						o.orderItemSkuToCustomAttributeMultipleItem.map((oi) => {
							const orderItemSku = order.orderItemSku.find(
								(o) => o.id === oi.id,
							)
							return {
								...orderItemSku!,
								productCustomAttributePrincipal: false,
							}
						})

					return {
						principalProduct: { ...o, productCustomAttributePrincipal: true },
						associatedItems,
					}
				})
				.map((o) => {
					return [
						{
							sku: o.principalProduct.itemSku.sku.toString(),
							title: o.principalProduct.itemSku.title,
							qty: o.principalProduct.quantity,
							discount: o?.principalProduct.discountAmount?.toNumber() ?? 0,
							price: o.principalProduct.price.toNumber(),
							total: o.principalProduct.total.toNumber(),
							storeLocation:
								o.principalProduct.itemSku.itemSkuStock?.find(
									(it) => it.filialId === order.filialId,
								)?.storeLocation ?? null,
							productCustomAttributePrincipal:
								o.principalProduct.productCustomAttributePrincipal,
						},
						o.associatedItems.map((a) => ({
							sku: a.itemSku.sku.toString(),
							title: a.itemSku.title,
							qty: a.quantity,
							discount: a?.discountAmount?.toNumber() ?? 0,
							price: a.price.toNumber(),
							total: a.total.toNumber(),
							storeLocation:
								a.itemSku.itemSkuStock?.find(
									(it) => it.filialId === order.filialId,
								)?.storeLocation ?? null,
							productCustomAttributePrincipal:
								a.productCustomAttributePrincipal,
						})),
					]
				}) ?? [],
		payments: order.orderPayment
			.map((pm) => {
				let payment: {
					method: string
					amount: number
				} = { method: pm.paymentType, amount: 0 }
				if (pm.paidIn.toNumber() > 0.001) {
					payment = { ...payment, amount: pm.paidIn.toNumber() }
				} else {
					payment = { ...payment, amount: -pm.paidOut.toNumber() }
				}
				return payment
			})
			.filter((pm) => pm.amount !== 0),
		change: order.orderPayment.reduce(
			(old, pm) => pm.paidOut.add(old),
			new Decimal(0),
		),
		subtotal: order.subTotal ?? 0,
		taxPercentage: order.taxable ? order.taxRate.times(100) : new Decimal(0),
		taxAmount: order.taxTotal ?? new Decimal(0),
		total: order.total ?? new Decimal(0),
		saleDiscount:
			order.discountType === "AMOUNT" || order.discountType === "PERCENTAGE"
				? {
						type: order.discountType ?? "AMOUNT",
						amount: order.discountAmount ?? new Decimal(0),
					}
				: undefined,
		totalDiscount: order.orderItemSku
			.reduce(
				(old, e) =>
					old.plus(
						e.price.mul(e.quantity).sub(
							applyDiscountV2(
								e.price.mul(e.quantity),
								e.discountAmount
									? {
											amount: e.discountAmount!,
											type: e.discountType!,
										}
									: null,
							),
						),
					),
				new Decimal(0),
			)
			.plus(appliedDiscount ?? 0),
		columns: [19, 3, 3, 3, 3, 8, 3, 8],
		shipping: order.orderShipping?.shippingCost ?? new Decimal(0),
		createdAt: order.createdAt,
		coupon: couponToSubtotal,
		previousShipping: couponToShipping?.previousShipping,
		discountShipping: couponToShipping?.discountShipping,
		insurance: order.withInsurance ? new Decimal(10) : new Decimal(0),
		orderType: order.orderType,
		address:
			order?.orderShipping && order.orderType === "ONLINE"
				? `${order.orderShipping?.addressLine1 ?? ""}, ${order.orderShipping?.city ?? ""}, ${order.orderShipping?.state ?? ""}, ${order.orderShipping?.zipCode ?? ""}`
				: null,
	}
}

export async function printTicketFromOrderV2(props: {
	order: OrderV2
	withLocation: boolean
}) {
	const ticket = orderV2ToTicket(props.order)

	try {
		await printTicket(ticket, props.withLocation, "ORDER")
	} catch (e) {
		showNotification({
			title: "Failed to print receipt",
			message: e?.message ?? "",
			color: "red",
		})
	}
}

export async function printTicketFromOrderV2Id(props: {
	orderId: number
	withLocation: boolean
}) {
	const order = (await gTrpc.order.getById.query({
		id: props.orderId,
	}))!
	const ticket = orderV2ToTicket(order)

	try {
		await printTicket(ticket, props.withLocation, "ORDER")
	} catch (e) {
		showNotification({
			title: "Failed to print receipt",
			message: e?.message ?? "",
			color: "red",
		})
	}
}

export function cartV2ToTicket(cart: CartV2, filialId: number): Ticket {
	const subtotal = cart.cartItemSku!.reduce((acc, entry) => {
		return new Decimal(
			applyDiscountV2(
				entry!.itemSku.price.times(entry.quantity),
				entry!.discount,
			),
		).plus(acc)
	}, new Decimal(0))
	const tax = cart?.customer?.taxable
		? subtotal.times(cart?.filial?.taxRate)
		: new Decimal(0)
	const total = cart?.customer?.taxable ? subtotal.plus(tax) : subtotal

	return {
		order: 0,
		customer: cart.customer?.company
			? cart.customer.company
			: `${cart.customer?.firstName} ${cart.customer?.lastName ?? ""}`,
		cashier: cart?.associated
			? `${cart?.associated?.firstName} ${cart?.associated?.lastName ?? ""}`
			: null,
		store: "1",
		workstation: 1,
		products: cart.cartItemSku!.map(({ itemSku, ...item }) => {
			const subtotal = applyDiscountV2(
				itemSku.price.times(item.quantity),
				item.discount,
			)

			return {
				sku: itemSku.sku.toString(),
				title: itemSku.title,
				qty: item.quantity,
				discount: item!.discount?.amount.toNumber() ?? 0,
				price: itemSku.price?.toNumber(),
				total: subtotal.toNumber(),
				storeLocation:
					itemSku.itemSkuStock?.find((it) => it.filialId === filialId)
						?.storeLocation ?? "",
				productCustomAttributePrincipal: false,
			}
		}),
		// @ts-ignore
		payments: [],
		change: new Decimal(0),
		subtotal: subtotal,
		taxPercentage: cart?.customer?.taxable
			? cart?.filial?.taxRate.times(100)
			: new Decimal(0),
		taxAmount: tax,
		total: total,
		totalDiscount: cart.cartItemSku!.reduce(
			(old, e) =>
				old.plus(
					e.itemSku.price
						.times(e.quantity)
						.sub(
							applyDiscountV2(e.itemSku.price.times(e.quantity), e.discount) ??
								new Decimal(0),
						),
				),
			new Decimal(0),
		),
		note: cart?.note ?? "",
		columns: [19, 3, 3, 3, 3, 8, 3, 8],
		customizedMultipledProducts: [],
		orderType: cart.cartType,
		address: null,
	}
}

export async function printTicketFromCartV2(props: {
	cart: CartV2
	withLocation: boolean
	filialId: number
}) {
	const ticket = cartV2ToTicket(props.cart, props.filialId)

	try {
		await printTicket(ticket, props?.withLocation ?? false, "ORDER")
	} catch (e) {
		showNotification({
			title: "Failed to print receipt",
			message: e?.message ?? "",
			color: "red",
		})
	}
}

type RefundOrderItemSkus = NonNullable<
	RouterOutputs["orderItemSku"]["returnMany"]
>
export function refundOrderItemSkuToTicket(
	refunds: RefundOrderItemSkus,
	filialId: number,
): Ticket {
	const tax = refunds[0].customer?.taxable
		? new Decimal("0.07")
		: new Decimal(0)
	const subtotal = refunds!.reduce((acc, entry) => {
		return acc.plus(
			applyDiscountV2(
				entry!.orderItemSku.price.times(entry.quantity),
				entry!.orderItemSku?.discountAmount && entry!.orderItemSku?.discountType
					? {
							amount: entry!.orderItemSku?.discountAmount,
							type: entry!.orderItemSku?.discountType,
						}
					: null,
			),
		)
	}, new Decimal(0))

	const total = subtotal.plus(subtotal.times(tax))

	return {
		order: refunds[0].reference ?? 0,
		customer: `${refunds[0].customer?.firstName} ${
			refunds[0].customer?.lastName ?? ""
		}`,
		cashier: refunds[0].associated
			? `${refunds[0].associated.firstName} ${
					refunds[0].associated?.lastName ?? ""
				}`
			: "",
		store: "1",
		workstation: 1,
		products: refunds!.map(({ orderItemSku, ...item }) => ({
			sku: orderItemSku.itemSku.sku.toString(),
			title: orderItemSku.itemSku.title,
			qty: -item.quantity,
			discount: orderItemSku!.discountAmount?.toNumber() ?? 0,
			price: -orderItemSku.price.toNumber(),
			total: -(item.total.toNumber() ?? 0),
			storeLocation:
				orderItemSku.itemSku.itemSkuStock?.find(
					(it) => it.filialId === filialId,
				)?.storeLocation ?? "",
			productCustomAttributePrincipal: false,
		})),
		// @ts-ignore
		payments: [
			{
				method: refunds[0].refundMethod,
				amount: total?.toNumber() ?? 0,
			},
		],
		change: new Decimal(0),
		subtotal: subtotal.neg(),
		taxPercentage: refunds[0].customer?.taxable
			? new Decimal(7)
			: new Decimal(0),
		taxAmount: tax.neg(),
		total: total.neg(),
		totalDiscount: refunds!
			.reduce(
				(old, e) =>
					old.plus(
						e.orderItemSku.price.times(e.quantity).sub(
							applyDiscountV2(
								e.orderItemSku.price.times(e.quantity),
								e.orderItemSku?.discountAmount && e.orderItemSku?.discountType
									? {
											amount: e.orderItemSku?.discountAmount,
											type: e.orderItemSku?.discountType,
										}
									: null,
							) ?? new Decimal(0),
						),
					),
				new Decimal(0),
			)
			.neg(),
		note: "",
		columns: [19, 3, 3, 3, 3, 8, 3, 8],
		createdAt: refunds[0].createdAt,
		customizedMultipledProducts: [],
		orderType: "IN_STORE",
		address: null,
	}
}

export async function printTicketFromRefundV2(props: {
	refunds: RefundOrderItemSkus
	withLocation: boolean
	filialId: number
}) {
	const ticket = refundOrderItemSkuToTicket(props.refunds, props.filialId)

	try {
		await printTicket(ticket, props?.withLocation ?? false, "RETURN")
	} catch (e) {
		showNotification({
			title: "Failed to print receipt",
			message: e?.message ?? "",
			color: "red",
		})
	}
}

const CTL_VT = new Uint8Array([0x1b, 0x64, 0x04])
const HW_INIT = new Uint8Array([0x1b, 0x40])

function leftRight(left: string, right: string): string {
	const len = left.length + right.length
	return `${left}${"".padEnd(columns - len)}${right}`
}

type Row = {
	text: string
	cols: number
}[]

function row(cells: Row): string {
	const paddedCells: string[] = []
	for (let i = 0; i < cells.length; i++) {
		const cell = cells[i]
		const newText = cell.text.substring(0, cell.cols).padEnd(cell.cols)
		paddedCells.push(newText)
	}
	return paddedCells.join("")
}
