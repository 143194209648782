import { HStack, styled as s } from "#/css/jsx"
import React, { useState, useEffect } from "react"
import Logo from "#/images/colored-logo-invoice.png"


export function LogoWithWebsiteUrl() {
	return (
		<HStack w="100%" justify="space-between" alignItems="start">
			<s.img src={Logo} w="4cm" />
			<s.span c="var(--text-color)" rotate="-90deg" transformOrigin="100% 0">
				goldtreemiami.com
			</s.span>
		</HStack>
	)
}