import { type RouterOutputs, trpc } from "#/trpc"
import { Flex } from "#/css/jsx"
import { Link } from "react-router-dom"
import { useState } from "react"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	type MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table"
import { Button, Dropdown } from "@gt/ui"
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline"
import dayjs from "dayjs"
import { Badge } from "@radix-ui/themes"
import { match } from "ts-pattern"
import { openSeeDocumentsModal } from "./see-documents"
import Decimal from "decimal.js"

export interface VendorExpensesTableProps {
	search?: string | null
	vendorId: number
}

export const VendorExpensesTable = (props: VendorExpensesTableProps) => {
	// Pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})
	// Sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])

	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } =
		trpc.v2_5.purchaseOrder.getPurchaseOrdersByVendor.useQuery(
			{
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				sorting: querySorting,
				filters: {
					vendorId: props.vendorId,
				},
				search: props.search,
			},
			{
				keepPreviousData: true,
			},
		)

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
	})

	return <MantineReactTable table={table} />
}

type PurchaseOrder =
	RouterOutputs["v2_5"]["purchaseOrder"]["getPurchaseOrdersByVendor"]["entries"][number]

const columns: MRT_ColumnDef<PurchaseOrder>[] = [
	{
		id: "actions",
		header: "Actions",
		enableSorting: false,
		size: 100,
		Cell: (table) => {
			const original = table.row.original
			const poId = original.id

			return (
				<>
					<Flex>
						<Dropdown.Root>
							<Dropdown.Trigger>
								<Button variant="ghost" size="icon">
									<MenuIcon />
								</Button>
							</Dropdown.Trigger>
							<Dropdown.Content>
								<Dropdown.Item onClick={() => openSeeDocumentsModal(poId)}>
									See documents
								</Dropdown.Item>
								<Dropdown.Item asChild>
									<Link to={`purcharse-order/${poId}`}>Upload documents</Link>
								</Dropdown.Item>
							</Dropdown.Content>
						</Dropdown.Root>
					</Flex>
				</>
			)
		},
	},
	{
		id: "id",
		accessorKey: "id",
		header: "PO #",
	},
	{
		id: "type",
		accessorKey: "type",
		header: "Type",
		enableSorting: false,
	},
	{
		id: "createdAt",
		accessorKey: "createdAt",
		header: "Order date",
		Cell: (table) => {
			const createdAt = table.row.original.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).format("MM/DD/YYYY").toString()
		},
	},
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		enableSorting: false,
		Cell: (table) => {
			const status = table.row.original.status
			const value = `${status[0]}${status.substring(1).toLowerCase()}`

			return (
				<Badge
					size="3"
					radius="full"
					color={
						match(status)
							.with("UPCOMING", () => "red")
							.with("PARTIALLY_RECEIVED", () => "orange")
							.with("RECEIVED", () => "green")
							.otherwise(() => "gray") as any
					}
				>
					{value}
				</Badge>
			)
		},
	},
	{
		id: "createdById",
		accessorKey: "createdBy",
		header: "Created by",
		enableSorting: false,
		Cell: (table) => {
			const fullName = `${table.row.original?.createdBy?.firstName ?? ""} ${
				table.row.original?.createdBy?.lastName ?? ""
			}`

			return fullName
		},
	},
	{
		id: "total",
		header: "Total",
		enableSorting: false,
		Cell(table) {
			const total = table.row.original.purchaseOrderItemSku?.reduce(
				(acc, cur) => {
					return acc.plus(
						(cur?.cost ?? new Decimal(0)).times(cur.expectedQuantity),
					)
				},
				new Decimal(0),
			)

			return `$${total.toFixed(2)}`
		},
	},
]
