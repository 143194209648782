import { styled as s } from "#/css/jsx"
import { useParams } from "react-router"
import { trpc } from "#/trpc"
import { useState, useEffect, RefObject } from "react"
import { CoverPage } from "./cover-page.js"
import { Page } from "./page.js"
import { CatalogItemV2 } from "./types.js"
import { ContactPage } from "./contact-page.js"
import { Center, Loader } from "@mantine/core"
import Decimal from "decimal.js"
import { groupBy } from "lodash"

export const PrintCatalogPdf = () => {
	const [triggeredPrint, setTriggeredPrint] = useState(false)

	const params = useParams() as any
	const id = params.id ? Number(params.id) : null

	const { data: catalog, isLoading } = trpc.v2_5.catalog.getByIdToPdf.useQuery(
		{
			id: id!,
		},
		{
			enabled: !!id,
			cacheTime: 0,
		},
	)

	useEffect(() => {
		if (catalog && !triggeredPrint) {
			window.setTimeout(() => {
				// window.print()
			}, 700)
			setTriggeredPrint(true)
		}
	}, [catalog, triggeredPrint])

	if (isLoading || !catalog) {
		return (
			<Center p={64}>
				<Loader />
			</Center>
		)
	}

	return (
		<>
			<style>{`
				@page {
					margin: 0;
					size: A4 portrait;
				}
			`}</style>
			<CoverPage catalogItem={catalog} />
			<ItemPages catalogItem={catalog} />
			<ContactPage />
		</>
	)
}

function ItemPages(props: { catalogItem: CatalogItemV2 }) {
	return props.catalogItem.images.map((img, i) => (
		<ItemPage key={i} catalogItemImage={img} />
	))
}

function ItemPage(props: { catalogItemImage: CatalogItemV2["images"][0] }) {
	const imgSize = useImageSize(props.catalogItemImage.url)
	const w = imgSize?.[0] ?? 1
	const h = imgSize?.[1] ?? 1

	const ratio = w / h

	if (ratio < 0.8) {
		return <FullPageItem catalogItemImage={props.catalogItemImage} />
	} else {
		return <CenteredItemPage catalogItemImage={props.catalogItemImage} />
	}
}

function FullPageItem(props: { catalogItemImage: CatalogItemV2["images"][0] }) {
	const [imgEl, setImgEl] = useState<HTMLImageElement | null>(null)

	const setImgElRef = useCallback(
		(newImgEl: HTMLImageElement | null) => {
			if (imgEl == null && newImgEl != null) {
				setImgEl(newImgEl)
			}
		},
		[imgEl],
	)

	return (
		<Page>
			<s.div pos="relative">
				<s.img
					ref={setImgElRef}
					src={props.catalogItemImage.url}
					w="100%"
					h="297mm"
					objectFit="cover"
				/>
				{imgEl != null && (
					<ItemPins catalogItemImage={props.catalogItemImage} imgEl={imgEl} />
				)}
			</s.div>
			<ItemPinPrices tags={props.catalogItemImage.tags} />
		</Page>
	)
}

function CenteredItemPage(props: {
	catalogItemImage: CatalogItemV2["images"][0]
}) {
	const [imgEl, setImgEl] = useState<HTMLImageElement | null>(null)

	const setImgElRef = useCallback(
		(newImgEl: HTMLImageElement | null) => {
			if (imgEl == null && newImgEl != null) {
				setImgEl(newImgEl)
			}
		},
		[imgEl],
	)

	return (
		<Page>
			<s.div display="grid" w="100%" h="297mm" placeItems="center">
				<s.div pos="relative">
					<s.img ref={setImgElRef} src={props.catalogItemImage.url} w="100%" />
					{imgEl != null && (
						<ItemPins catalogItemImage={props.catalogItemImage} imgEl={imgEl} />
					)}
				</s.div>
			</s.div>
			<s.p pos="absolute" right="12" bottom="12" zIndex="1" c="slate.600">
				goldtreemiami.com
			</s.p>
			<ItemPinPrices tags={props.catalogItemImage.tags} />
		</Page>
	)
}

function ItemPins(props: {
	catalogItemImage: CatalogItemV2["images"][0]
	imgEl: HTMLImageElement
}) {
	return props.catalogItemImage.tags.map((tag, i) => (
		<ItemPin key={i} tag={tag} imgEl={props.imgEl} index={i + 1} />
	))
}

function ItemPin(props: {
	tag: CatalogItemV2["images"][0]["tags"][0]
	imgEl: HTMLImageElement
	index: number
	objectFit?: "cover"
}) {
	const [cw, ch] =
		window.getComputedStyle(props.imgEl).objectFit === "cover"
			? getCoverSize(props.imgEl)
			: [props.imgEl.width, props.imgEl.height]
	const offsetX = -(cw - props.imgEl.width) / 2
	const offsetY = -(ch - props.imgEl.height) / 2

	const pinBounds = {
		x: offsetX + cw * props.tag.x,
		y: offsetY + ch * props.tag.y,
	}

	return (
		<s.div
			pos="absolute"
			top="var(--top)"
			left="var(--left)"
			zIndex="1"
			// bg="slate.700/80"
			px="2"
			py="1"
			rounded="md"
			translate="auto"
			translateX="-50%"
			style={{
				"--left": `${pinBounds.x}px`,
				"--top": `${pinBounds.y}px`,
			}}
		>
			<s.div
				w="24px"
				fs="xs"
				display="grid"
				placeItems="center"
				opacity="0.8"
				aspectRatio="1/1"
				borderRadius="full"
				bg="slate.50"
			>
				{props.index}
			</s.div>
			{/* <s.p c="slate.50" fs="xs">
				{props.tag.itemSku!.sku}
			</s.p> */}
		</s.div>
	)
}

type TagWithIndex = {
	tag: CatalogItemV2["images"][0]["tags"][0]
	index: number
}

function ItemPinPrices(props: {
	tags: CatalogItemV2["images"][0]["tags"]
}) {
	const groupedTags: Record<string, Array<TagWithIndex>> = {}

	for (const [i, tag] of props.tags.entries()) {
		const price = tag.itemSku?.price.toFixed(2) ?? "N/A"
		if (groupedTags[price] == null) {
			groupedTags[price] = []
		}
		groupedTags[price].push({ tag, index: i + 1 })
	}

	return (
		<s.div
      display="grid"
			gapX="3"
			gapY="2"
			gridTemplateColumns="auto 1fr"
			pos="absolute"
			right="12"
			bottom="24"
			zIndex="1"
			c="slate.600"
		>
			{Object.entries(groupedTags).map(([price, tags], i) => {
				return <ItemPinPrice key={i} price={price} tagsWithIndex={tags} />
			})}
		</s.div>
	)
}

function ItemPinPrice(props: {
	price: string
	tagsWithIndex: Array<TagWithIndex>
}) {
	return (
		<>
			<s.div display="flex" gap="2" flexWrap="nowrap">
				{props.tagsWithIndex.map((tagWithIndex, i) => (
					<s.div
						key={i}
						w="22px"
						aspectRatio="1/1"
						fs="xs"
						bg="slate.50/70"
						rounded="full"
						display="grid"
						placeItems="center"
					>
						{tagWithIndex.index}
					</s.div>
				))}
			</s.div>
			<s.div textAlign="right">${props.price}</s.div>
		</>
	)
}
function useImageSize(src: string) {
	const [loaded, setLoaded] = useState(false)
	const [size, setSize] = useState([0, 0])
	const img = useRef<HTMLImageElement | null>(null)
	if (img.current == null) {
		img.current = new Image()
		img.current.src = src
		img.current.onload = function () {
			setLoaded(true)
			setSize([img.current!.width, img.current!.height])
		}
	}

	if (!loaded) {
		return null
	}

	return size
}

function getCoverSize(img: HTMLImageElement) {
	const naturalRatio = img.naturalWidth / img.naturalHeight
	const bb = img.getBoundingClientRect()
	const computedRatio = bb.width / bb.height

	if (computedRatio < naturalRatio) {
		return [bb.height * naturalRatio, bb.height]
	} else if (computedRatio > naturalRatio) {
		return [bb.width, bb.width / naturalRatio]
	} else {
		return [bb.width, bb.height]
	}
}
