import { PrinterIcon } from "#/components-ng/icons.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { exportEndOfDayToXLSX } from "../exportXlsx.js";
import { printEndOfDayEpson } from "../PrintEpson.js";
import { GeneralInformation } from "./GeneralInformation.js";
import { InventoryMovement } from "./InventoryMovement.js";
import { OrderHistory } from "./OrderHistory.js";
import { Payments } from "./Payments.js";
import { endOfDayAtom } from "./state.js";
import * as M from "@mantine/core";
import { Provider, useSetAtom } from "jotai";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export const GenerateEndOfDay = () => {
  return (
    <Provider>
      <GenerateEndOfDayInner />
    </Provider>
  );
};

const GenerateEndOfDayInner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const setEndOfDay = useSetAtom(endOfDayAtom);

  const { data, isLoading, error } =
    trpc.report.getLatestEndOfDayCache.useQuery(undefined, {
      onError(error) {
        reportUserError({
          title: "Failed to get latest end of day",
          message: error.message,
        });
      },
      onSuccess(data) {
        const endofday = data.find((eod) => eod.id === Number(id));

        if (endofday) {
          setEndOfDay({
            ...endofday,
            inventoryMovement: {
              ...endofday.inventoryMovement,
              noReturned: {
                ...endofday.inventoryMovement.noReturned,
                products: endofday.inventoryMovement.noReturned.products ?? [],
              },
              returned: {
                ...endofday.inventoryMovement.returned,
                products: endofday.inventoryMovement.returned.products ?? [],
              },
            },
            loading: false,
            ordersHistory: {
              ...endofday.ordersHistory,
              orders: endofday.ordersHistory.orders ?? [],
            },
          });
        }
      },
    });

  React.useEffect(() => {
    if (error && !isLoading) {
      reportUserError({
        title: "Failed to get latest end of day",
        message: error.message,
      });
      navigate("/sales/end-of-day");
    }
  }, [error, isLoading, navigate]);

  function handleExportXlsx() {
    const foundEod = data?.find((eod) => eod.id === Number(id))
    if (foundEod == null) {
      reportUserError({
        title: "End of day is not available anymore",
        message: "Please, generate a new end of day"
      })
      return;
    }
    
    exportEndOfDayToXLSX(foundEod);
  }

  const handlePrintEpsonTicket = async () => {
    try {
      const endofday = data?.find((eod) => eod.id === Number(id));
      await printEndOfDayEpson(endofday as any);
    } catch (e) {
      reportUserError({
        title: "Failed to print end of day",
        message: e.message,
      });
    }
  };

  const handlePrintPdf = () => {
    const endofday = data?.find((eod) => eod.id === Number(id));
    window.open(`/sales/end-of-day/print/${endofday?.id}`, "_blank");
  };

  return (
    <M.Container size="auto">
      <M.Stack>
        <M.Group align="flex-end" noWrap position="apart">
          <M.Title order={2}>End of day</M.Title>
          <M.Menu>
            <M.Menu.Target>
              <M.ActionIcon size="md">
                <PrinterIcon />
              </M.ActionIcon>
            </M.Menu.Target>
            <M.Menu.Dropdown>
              <M.Menu.Item onClick={handleExportXlsx}>Export XLSX</M.Menu.Item>
              <M.Menu.Item onClick={handlePrintPdf}>Print PDF</M.Menu.Item>
              <M.Menu.Item onClick={handlePrintEpsonTicket}>
                Print Epson
              </M.Menu.Item>
            </M.Menu.Dropdown>
          </M.Menu>
        </M.Group>
        <M.Stack spacing="xl">
          <GeneralInformation />
          <Payments />
          <OrderHistory />
          <InventoryMovement />
        </M.Stack>
      </M.Stack>
    </M.Container>
  );
};
