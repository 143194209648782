import { Box, HStack, styled } from "#/css/jsx"
import { type RouterInputs, type RouterOutputs, trpc } from "#/trpc"
import { Button, Dropdown } from "@gt/ui"
import { useDebouncedValue } from "@mantine/hooks"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	type MRT_ColumnFiltersState,
	type MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table"
import { useMemo, useState } from "react"
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline"
import placeholderImage from "#/placeholder-image.jpg"
import { css } from "#/css/css"
import { DropdownMenu, HoverCard, IconButton, Tooltip } from "@radix-ui/themes"
import { reportUserError, reportUserSuccess } from "#/util"
import { openConfirmModal } from "@mantine/modals"
import { MdOutlineDownload } from "react-icons/md"

type ColumnFilters =
	RouterInputs["v2_5"]["itemSku"]["getItemsWithoutStockByPage"]["filters"]

export const SuggestedDisabledItemsScene = () => {
	// pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})

	// sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	// Filtering
	const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])

	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const queryFilters = useMemo(() => {
		const filters: ColumnFilters = {}

		for (const columnFilter of columnFilters) {
			if (columnFilter.id === "sku") {
				filters.sku = `"${columnFilter.value}"`
			} else {
				filters[columnFilter.id] = columnFilter.value
			}
		}
		return filters
	}, [columnFilters])

	const [debouncedSearchTerm] = useDebouncedValue(queryFilters, 400)

	const { data, isLoading } =
		trpc.v2_5.itemSku.getItemsWithoutStockByPage.useQuery(
			{
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				filters: debouncedSearchTerm,
				sorting: querySorting,
			},
			{
				keepPreviousData: true,
			},
		)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.itemSku.exportItemsWithoutStockTableToCsvService.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			filters: debouncedSearchTerm,
			sorting: querySorting,
		})
	}

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: true,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		enableFilterMatchHighlighting: false,
		onColumnFiltersChange: setColumnFilters,
		initialState: {
			showColumnFilters: true,
		},
		mantineFilterTextInputProps() {
			return {
				classNames: {
					root: "border-b-0 mt-1",
					input: "px-2 font-normal text-sm",
				},
			}
		},
		renderBottomToolbarCustomActions: () => (
			<styled.div display="flex" alignItems="center" gap="2">
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</styled.div>
		),
	})

	return (
		<Box>
			<HStack justify="space-between" gap={3}>
				<DrowndownButton />
			</HStack>
			<Box mt="4">
				<MantineReactTable table={table} />
			</Box>
		</Box>
	)
}

type ItemSku =
	RouterOutputs["v2_5"]["itemSku"]["getItemsWithoutStockByPage"]["entries"][number]

const columns: MRT_ColumnDef<ItemSku>[] = [
	{
		id: "actions",
		enableSorting: false,
		enableColumnFilter: false,
		header: "Actions",
		size: 100,
		Cell: (table) => {
			const ctx = trpc.useContext()

			const { mutate } = trpc.v2_5.itemSku.disableItem.useMutation({
				onError: (err) => {
					reportUserError({
						title: "Failed to disable item",
						message: err.message,
					})
				},
				onSuccess: async () => {
					reportUserSuccess({
						title: "Successfully disabled item",
						message: "The item has been disabled",
					})
					ctx.v2_5.itemSku.invalidate()
				},
			})

			const openConfirmationModal = () => {
				openConfirmModal({
					title: "Are you sure you want to disable this item?",
					labels: { cancel: "Cancel", confirm: "Disable" },
					confirmProps: { color: "red" },
					onConfirm: () =>
						mutate({
							id: table.row.original.id,
						}),
				})
			}

			return (
				<Dropdown.Root>
					<Dropdown.Trigger>
						<Button variant="ghost" size="icon">
							<MenuIcon />
						</Button>
					</Dropdown.Trigger>
					<Dropdown.Content>
						<Dropdown.Item onClick={() => openConfirmationModal()}>
							Disable
						</Dropdown.Item>
					</Dropdown.Content>
				</Dropdown.Root>
			)
		},
	},
	{
		accessorKey: "defaultImage",
		header: "Image",
		enableSorting: false,
		enableColumnFilter: false,
		size: 100,
		Cell: (table) => {
			const original = table.row.original
			const image = table.row.original?.defaultImage
				? table.row.original?.defaultImage
				: original.images?.length > 0
					? original.images[0]
					: null

			return <img src={image ?? placeholderImage} className="w-11" alt="sku" />
		},
	},
	{
		id: "sku",
		accessorKey: "sku",
		header: "Sku",
		size: 100,
		Cell: (table) => {
			return (
				<HoverCard.Root>
					<HoverCard.Trigger>
						<p>{table.row.original.sku}</p>
					</HoverCard.Trigger>
					<HoverCard.Content>
						<styled.div display="flex" flexDirection="column" gap="1rem">
							{table.row.original.itemSkuStock?.map((it) => (
								<styled.div
									key={it.id}
									display="flex"
									flexDirection="column"
									gap="0.5rem"
								>
									<styled.p fontSize="md" fontWeight={700} mb="2">
										{it.filial?.brevis ?? it.filial.name}
									</styled.p>
									<styled.p fontSize="sm" fontWeight={500}>
										Store location:{" "}
										<styled.span fontWeight={400}>
											{it.storeLocation}
										</styled.span>
									</styled.p>
									<styled.p fontSize="sm" fontWeight={500}>
										Warehouse location:{" "}
										<styled.span fontWeight={400}>
											{it.warehouseLocation}
										</styled.span>
									</styled.p>
								</styled.div>
							))}
						</styled.div>
					</HoverCard.Content>
				</HoverCard.Root>
			)
		},
	},
	{
		id: "title",
		accessorKey: "title",
		header: "Name",
	},
	{
		id: "filials",
		header: "Filials",
		enableColumnFilterModes: false,
		Cell: (table) => {
			return (
				<div>
					{table.row.original.itemSkuStock.map((it) => (
						<div key={it.filialId}>
							{it.filial.name} - {it.quantity}
						</div>
					))}
				</div>
			)
		},
	},
	{
		id: "price",
		accessorFn: (row) => `$${row.price?.toNumber().toFixed(2)}`,
		header: "Price",
		enableColumnFilterModes: false,
		enableSorting: false,
		enableColumnFilter: false,
	},
]

const DrowndownButton = () => {
	const ctx = trpc.useContext()

	const { mutate } = trpc.v2_5.itemSku.disableItems.useMutation({
		onError: (err) => {
			reportUserError({
				title: "Failed to disable items",
				message: err.message,
			})
		},
		onSuccess: async () => {
			reportUserSuccess({
				title: "Successfully disabled items",
				message: "The items have been disabled",
			})
			ctx.v2_5.itemSku.invalidate()
		},
	})

	const openConfirmationModal = () => {
		openConfirmModal({
			title:
				"Are you sure you want to disable all items without stock in all filial?",
			labels: { cancel: "Cancel", confirm: "Disable" },
			confirmProps: { color: "red" },
			onConfirm: () => mutate(),
		})
	}

	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button
					className={css({
						gap: 4,
					})}
				>
					I want to
					<DropdownMenu.TriggerIcon />
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content>
				<Dropdown.Item onClick={() => openConfirmationModal()}>
					Disable all items
				</Dropdown.Item>
			</Dropdown.Content>
		</Dropdown.Root>
	)
}
