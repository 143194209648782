import { Select } from "#/components-ng"
import { createFormContext } from "#/components-ng/form-context"
import { css } from "#/css/css"
import { Circle, styled } from "#/css/jsx"
import { RouterOutputs, trpc } from "#/trpc"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Modal, ModalProps, TextInput } from "@mantine/core"
import { modals } from "@mantine/modals"
import { nanoid } from "nanoid"
import { FormProvider } from "react-hook-form"
import { z } from "zod"
import { atomWithStorage } from "jotai/utils"
import { motion } from "framer-motion"
import { DotLottieReact } from "@lottiefiles/dotlottie-react"
import { useGlobalAtomValue, useSetGlobalAtom } from "#/lib/jotai"
import { terminalDeviceCodeIdAtom } from "#/state/terminal.atom"

const PAIR_WITH_TERMINAL_FORM_MODAL_ID = "pair-with-terminal-form-setup"
const TERMINAL_CODE_MODAL_ID = "pair-with-terminal-form-code"

export function PaymentsTerminalScene() {
	return (
		<styled.div display="grid" placeItems="center" h="50vh">
			<PaymentsTerminalSceneContent />
		</styled.div>
	)
}

function PaymentsTerminalSceneContent() {
	const deviceCodeId = useGlobalAtomValue(terminalDeviceCodeIdAtom)
	const { data } = trpc.v2_5.square.getDeviceCode.useQuery(
		{
			id: deviceCodeId!,
		},
		{
			enabled: deviceCodeId != null,
			refetchInterval: 3000,
		},
	)

	if (data?.status === "PAIRED") {
		return <PairedScreenContent name={data?.name ?? "Default terminal name"} />
	} else {
		return <UnpairedScreenContent />
	}
}

function PairedScreenContent(props: { name: string }) {
	return (
		<styled.div
			css={{
				bg: "white",
				shadow: "sm",
				p: "6",
			}}
		>
			<styled.div
				css={{ display: "flex", alignItems: "center", columnGap: "3" }}
			>
				<Circle size="2" bg="green.500" />
				<styled.p
					css={{
						fontSize: "sm",
					}}
				>
					Connected
				</styled.p>
			</styled.div>
			<styled.div css={{ fontWeight: "medium" }}>{props.name}</styled.div>
		</styled.div>
	)
}

function UnpairedScreenContent() {
	return (
		<styled.div
			display="grid"
			rowGap="1"
			bg="white"
			p="6"
			rounded="md"
			shadow="sm"
		>
			<styled.h3>No terminal paired</styled.h3>
			<styled.p>
				To take payments, you need to pair with a Square Terminal.
			</styled.p>
			<PairWithTerminalButton />
		</styled.div>
	)
}

function PairWithTerminalButton() {
	function openFormModal() {
		modals.open({
			modalId: PAIR_WITH_TERMINAL_FORM_MODAL_ID,
			title: "Pair with Square Terminal",
			children: <PairWithTerminalFormModalInner />,
			classNames: {
				title: css({
					fontSize: "lg",
					fontWeight: "medium",
				}),
			},
		})
	}

	return (
		<Button
			onClick={openFormModal}
			className={css({
				justifySelf: "end",
				mt: "4",
			})}
		>
			Pair with terminal
		</Button>
	)
}

const PairWithTerminalFormSchema = z.object({
	idempotencyKey: z.string(),
	name: z.string(),
	location: z.object({
		id: z.string(),
		name: z.string(),
	}),
})

export type PairWithTerminalFormSchema = z.infer<
	typeof PairWithTerminalFormSchema
>

const { C, Controller, useForm } =
	createFormContext<PairWithTerminalFormSchema>()

function PairWithTerminalFormModalInner() {
	return <PairWithTerminalForm />
}

function PairWithTerminalForm() {
	const form = useForm({
		resolver: zodResolver(PairWithTerminalFormSchema),
		resetOptions: {
			keepDirtyValues: true,
		},
	})
	const setDeviceId = useSetGlobalAtom(terminalDeviceCodeIdAtom)

	useEffect(() => {
		form.setValue("idempotencyKey", nanoid())
	}, [])

	const { mutate, isLoading } = trpc.v2_5.square.createDeviceCode.useMutation({
		onSuccess(data) {
			modals.close(PAIR_WITH_TERMINAL_FORM_MODAL_ID)
			setDeviceId(data.id)
			openSquareTerminalCodeModal({
				id: data.id,
				code: data.code,
			})
		},
	})

	function handleSubmit(values: PairWithTerminalFormSchema) {
		mutate({
			name: values.name,
			locationId: values.location.id,
			idempotencyKey: values.idempotencyKey,
		})
	}

	return (
		<div>
			<FormProvider {...form}>
				<form
					onSubmit={form.handleSubmit(handleSubmit)}
					className={css({
						display: "grid",
						rowGap: "4",
					})}
				>
					<C
						$as={TextInput}
						name="name"
						label="Name"
						description="This name will be used to identify the terminal. Make it unique."
						placeholder="I.e. Miami Terminal 6"
					/>
					<SquareLocationSelect />
					<Button
						type="submit"
						loading={isLoading}
						className={css({
							justifySelf: "end",
						})}
					>
						Start pairing
					</Button>
				</form>
			</FormProvider>
		</div>
	)
}

type SquareLocation = RouterOutputs["v2_5"]["square"]["listLocations"][0]

function SquareLocationSelect() {
	const { data } = trpc.v2_5.square.listLocations.useQuery({})

	return (
		<Controller
			name="location"
			render={(c) => (
				<Select<SquareLocation>
					label="Location"
					description="New locations must be created in the Square dashboard"
					data={data ?? []}
					entryId={(d) => d.id}
					entryLabel={(d) => d.name}
					onChange={c.field.onChange}
					error={c.fieldState.error?.message}
				/>
			)}
		/>
	)
}

interface SquareTerminalCodeModalInnerProps {
	id: string
	code: string
}

function openSquareTerminalCodeModal(props: SquareTerminalCodeModalInnerProps) {
	modals.open({
		modalId: TERMINAL_CODE_MODAL_ID,
		title: "Pairing code",
		children: <SquareTerminalCodeModalInner {...props} />,
		classNames: {
			title: css({
				fontSize: "lg",
				fontWeight: "medium",
			}),
		},
	})
}

function SquareTerminalCodeModalInner(
	props: SquareTerminalCodeModalInnerProps,
) {
	const deviceId = useGlobalAtomValue(terminalDeviceCodeIdAtom)
	const { data } = trpc.v2_5.square.getDeviceCode.useQuery(
		{
			id: deviceId!,
		},
		{
			enabled: deviceId != null,
			refetchInterval: 1000,
		},
	)

	useEffect(() => {
		if (data?.status === "PAIRED") {
			setTimeout(() => {
				modals.close(TERMINAL_CODE_MODAL_ID)
			}, 2500)
		}
	}, [data?.status])

	if (data?.status === "PAIRED") {
		return (
			<styled.div
				css={{
					pb: 8,
				}}
			>
				<PairedMessage />
			</styled.div>
		)
	} else {
		return <UnpairedMessage code={props.code} />
	}
}

function PairedMessage() {
	return (
		<div>
			<CheckmarkIcon />
			<motion.p
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: 1,
				}}
				transition={{
					duration: 0.7,
				}}
				className={css({
					fontSize: "xl",
					textAlign: "center",
					fontWeight: "medium",
					animation: "ease-in",
				})}
			>
				Paired successfully
			</motion.p>
		</div>
	)
}

function UnpairedMessage(props: { code: string }) {
	return (
		<div>
			<div
				className={css({
					fontFamily: "monospace",
					fontSize: "xl",
					letterSpacing: "0.2em",
					textAlign: "center",
					mt: "8",
				})}
			>
				{props.code}
			</div>
			<div
				className={css({
					color: "slate.600",
					fontSize: "sm",
					mt: "8",
				})}
			>
				Enter this code in your Square Terminal by going into the{" "}
				<strong>Sign in Page</strong> then choosing the{" "}
				<strong>Device Code</strong> button
			</div>
		</div>
	)
}

function CheckmarkIcon() {
	return (
		<DotLottieReact
			src="/animations/success-checkmark.lottie"
			autoplay
			segment={[0, 50]}
			speed={1.5}
		/>
	)
}
