import { trpc } from "#/trpc"
import { ReactNode } from "react"
import {
	Button,
	Label,
	ListBox,
	ListBoxItem,
	Popover,
	Select,
	SelectValue,
	Key,
} from "react-aria-components"
import { ChevronDownIcon } from "lucide-react"
import { css } from "#/css/css"
import { reportUserSuccess } from "#/util"

interface Language {
	name: string
	code: string
	icon: ReactNode
}

const languages = [
	{
		name: "English",
		code: "en",
		icon: "🇺🇸",
		pathPrefix: "",
	},
	{
		name: "Español",
		code: "es",
		icon: "🇲🇽",
		pathPrefix: "/es",
	},
]

interface LanguageSelectProp {
	languaje: "EN" | "ES"
	customerId: number
}

export const LanguageSelect = (props: LanguageSelectProp) => {
	const ctx = trpc.useContext()
	const [selectedLanguage, setSelectedLanguage] = useState<Language>(() => {
		return (
			languages.find((language) => {
				const currentLocale = props.languaje?.toLowerCase()
				return language.code === currentLocale
			}) ?? languages[0]
		)
	})

	const { mutate: updateLanguage } = trpc.v2_5.user.updateLanguage.useMutation({
		onSuccess: (data) => {
			const language = languages?.find(
				(language) => language.code === data?.language?.toLowerCase(),
			)

			setSelectedLanguage(language ?? languages[0])
			ctx.user.invalidate()
			ctx.v2_5.user.invalidate()

			reportUserSuccess({
				message: "Language updated successfully",
			})
		},
	})

	function handleLanguageChange(languageKey: Key) {
		const newSelectedLanguage = languages.find((lang) => {
			return lang.code === languageKey
		})

		if (!newSelectedLanguage) {
			return
		}

		updateLanguage({
			language: newSelectedLanguage?.code?.toUpperCase() as any,
			userId: props.customerId,
		})
	}

	return (
		<Select
			selectedKey={selectedLanguage.code}
			onSelectionChange={handleLanguageChange}
			placeholder="Select language"
		>
			<Button
				className={css({
					display: "flex",
					alignItems: "center",
					rowGap: "2",
				})}
				aria-label="Select language"
			>
				<SelectValue>
					{(values) => {
						const selectedLanguage = languages.find((language) => {
							const selectedItem = values.selectedItem as Language | null
							return selectedItem?.code === language.code
						})

						if (!selectedLanguage) {
							return <span>🇺🇸</span>
						}

						return <span>{selectedLanguage.icon}</span>
					}}
				</SelectValue>
				<span aria-hidden="true">
					<ChevronDownIcon
						size="18"
						className={css({
							c: "slate.600",
						})}
					/>
				</span>
			</Button>

			<Popover
				className={css({
					bg: "white",
					rounded: "md",
					overflow: "hidden",
					shadow: "md",
				})}
			>
				<ListBox>
					{languages.map((language) => (
						<ListBoxItem
							key={language.code}
							id={language.code}
							value={language}
							textValue={language.name}
							className={css({
								display: "block",
								cursor: "pointer",
								px: "4",
								py: "2",
								c: "slate.800",

								"&[data-selected]": {
									bg: "slate.800",
									c: "white",
								},
								"&[data-focused],&[data-hovered]": {
									bg: "slate.100",
									c: "slate.800",
									outline: "none",
								},
								"&[data-focused][data-selected],&[data-hovered][data-selected]":
									{
										bg: "slate.700",
										c: "white",
									},
							})}
						>
							<span
								aria-labelledby="language-name-option"
								className={css({ mr: "2" })}
							>
								{language.icon}
							</span>
							<Label id="language-name-option">{language.name}</Label>
						</ListBoxItem>
					))}
				</ListBox>
			</Popover>
		</Select>
	)
}
