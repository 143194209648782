import { makeController } from "#/components-ng/index.js"
import { z } from "zod"

export const productsRange = ["10", "25", "50", "100"] as const

export const generatePromotionReportSchema = z.object({
	productsRange: z.enum(productsRange),
	promotionId: z.string().optional().nullish(),
	filter: z
		.discriminatedUnion("by", [
			z.object({
				by: z.literal("entity"),
				id: z.string(),
			}),
			z.object({
				by: z.literal("filial"),
				id: z.string(),
			}),
		])
		.default({ by: "entity", id: "1" }),
})

export type FormValues = z.infer<typeof generatePromotionReportSchema>
export const C = makeController<FormValues>()
