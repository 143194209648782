import GTLoader from "#/components-ng/loader";
import { trpc } from "#/trpc";
import { ItemSkuPreviewHistory } from "./History";
import { Gallery, ProductInfo } from "./Information";
import { TabsInfo } from "./TabsInfo";
import * as M from "@mantine/core";
import { useParams } from "react-router";

export const ItemPreview = () => {
  const { id } = useParams<{ id: string }>();
  const itemSkuId = Number.parseInt(id!);
  const { data, isLoading, isFetchedAfterMount } =
    trpc.itemSku.getById.useQuery(
      {
        id: itemSkuId,
      },
      {
        cacheTime: 0,
        enabled: !!itemSkuId,
      }
    );

  return isLoading || !isFetchedAfterMount ? (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <M.Container size="xl" bg="white" className="py-5">
      <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-[1fr_minmax(340px,0.9fr)] lg:gap-x-10">
        <Gallery product={data} />
        <ProductInfo product={data} />
      </div>
      <div className="mx-auto mt-10 max-w-[1100px]">
        <TabsInfo itemSkuId={itemSkuId} product={data} />
      </div>
      <M.Divider
        className="mx-auto my-5 max-w-[1100px]"
        size={3}
        color="#E0E0E0"
      />
      <div className="mx-auto mt-10 max-w-[1100px]">
        <ItemSkuPreviewHistory />
      </div>
    </M.Container>
  );
};
