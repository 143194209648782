import {
  CloseOutline,
  EditIcon,
  TrashFilledIcon,
} from "#/components-ng/index.js";
import { cn } from "#/lib/utils.js";
import { RouterOutputs, trpc } from "#/trpc";
import {
  Button,
  Dialog,
  Form,
  Input,
  Combobox,
  ComboboxDataEntry,
} from "@gt/ui";
import { LoadingOverlay } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CatalogImageFormValues } from "./types";

interface CatalogFormImageProps {
  // Index of this image in the form array.
  index: number;
  img: {
    url: string;
    isLoading: boolean;
    uuid: string;
  };
  removeImage: () => void;
}

// A `Pin` represents a clickable section in a catalog image that links
// somewhere when it's clicked.
interface Pin {
  // Title shown when a Pin is hovered.
  title: string;
  // Relative X position in pixels.
  x: number;
  // Relative Y position in pixels.
  y: number;
  // URL to navigate on click.
  url: string;
  // ItemSku
  itemSkuId?: number | null;
  sku?: number | null
}

type ItemSku = RouterOutputs["itemSku"]["search"][number];

export function CatalogFormImage({
  img,
  removeImage,
  ...props
}: CatalogFormImageProps) {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [editorImageRef, setEditorImageRef] = useState<HTMLImageElement | null>(
    null,
  );
  const [pins, setPins] = useState<Array<Pin>>([]);
  const [selectedPin, setSelectedPin] = useState<number | null>(null);
  const [currentAction, setCurrentAction] = useState<"move" | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const searchItemSkuQuery = trpc.itemSku.search.useQuery({
    query: `\\"${searchQuery}\\"`,
  });
  const form = useFormContext<CatalogImageFormValues>();
  const { getValues: getFormValues } = form;

  // Pre-populate the tags with the tags already stored.
  // We do this until the image ref is set because we need the size of the image.
  useEffect(() => {
    if (!editorImageRef || !isEditorOpen) return;
    // We have to add a `setTimeout` to wait for the opening animation of the
    // modal to finish, otherwise the size of the image will be incorrect.
    setTimeout(() => {
      const imageBounds = editorImageRef.getBoundingClientRect();
      const storedTags = getFormValues(`image.tags`);
      // We process the tags so that they're usable in the editor.
      const processedTags = storedTags.map((tag) => {
        // We convert the position from [0, 1] to a position relative to the size of the image.
        const newX = tag.x * imageBounds.width;
        const newY = tag.y * imageBounds.height;
        return {
          title: tag.title,
          url: tag.url,
          x: newX,
          y: newY,
          itemSkuId: tag.itemSkuId,
          sku: tag.sku
        };
      });
      setPins(processedTags);
    }, 300);
  }, [getFormValues, props.index, isEditorOpen, editorImageRef]);

  const searchItemSkuSelectData = useMemo(
    () =>
      searchItemSkuQuery.data?.map((itemSku) => ({
        id: itemSku.sku.toString(),
        label: `${itemSku.sku} ${itemSku.title.replace(`"`, "")}`,
        value: itemSku,
      })) ?? [],
    [searchItemSkuQuery.data],
  );

  // Utility function to change any prop of the selected pin with type-safety
  // Example: `changeSelectedPinProp("url", "new url value");`
  const changeSelectedPinProp = <Key extends keyof Pin, Value extends Pin[Key]>(
    key: Key,
    newValue: Value,
  ) => {
    setPins((prev) =>
      prev.map((currentPin, i) => {
        if (i === selectedPin) {
          return {
            ...prev[selectedPin],
            [key]: newValue,
          };
        }
        return currentPin;
      }),
    );
  };

  // @ts-ignore
  const SHOP_BASE_URL = import.meta.env.SECRET_SHOP_BASE_URL;

  const handleItemSkuChange = (entry: ComboboxDataEntry<ItemSku>) => {
    const itemSku = entry.value;
    changeSelectedPinProp(
      "title",
      `${itemSku.sku} ${itemSku.title.replace(`"`, "")}`,
    );
    changeSelectedPinProp("url", `${SHOP_BASE_URL}/products/${itemSku.sku}`);
    changeSelectedPinProp("itemSkuId", itemSku.id);
    changeSelectedPinProp("sku", itemSku.sku);
  };

  const handleEditorImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
    const pos = {
      title: "",
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      url: "",
      itemSkuId: null,
      sku: null,
    };
    setPins((prev) => [...prev, pos]);
    setSelectedPin(pins.length);
  };

  const handlePinClick = (
    index: number,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedPin(index);
  };

  const handlePinMouseDown = (
    index: number,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (index === selectedPin) {
      setCurrentAction("move");
    }
  };

  const deleteSelectedPin = useCallback(() => {
    setPins((prev) => prev.filter((_, i) => i !== selectedPin));
    setSelectedPin(null);
  }, [selectedPin]);

  useEffect(() => {
    // Handler for `mousemove` for updating the pin on resize, move, etc,
    function handler(e: MouseEvent) {
      if (!currentAction) return;

      setPins((prev) =>
        prev.map((currentPin, i) => {
          if (selectedPin === i) {
            const newProps: Partial<Pin> = {};

            const imageBounds = editorImageRef!.getBoundingClientRect();

            if (currentAction === "move") {
              newProps.x = currentPin.x + e.movementX;
              newProps.y = currentPin.y + e.movementY;
              // Limit the movement so that the pin does not go out of the
              // image
              newProps.x = Math.min(newProps.x, imageBounds.width);
              newProps.x = Math.max(newProps.x, 0);
              newProps.y = Math.min(newProps.y, imageBounds.height);
              newProps.y = Math.max(newProps.y, 0);
            }

            return {
              ...currentPin,
              ...newProps,
            };
          }
          return currentPin;
        }),
      );
    }
    window.addEventListener("mousemove", handler);
    return () => window.removeEventListener("mousemove", handler);
  }, [currentAction, editorImageRef, selectedPin]);

  useEffect(() => {
    // Handler for `mouseup` that stops the actions.
    function handler() {
      setCurrentAction(null);
    }
    window.addEventListener("mouseup", handler);
    return () => window.removeEventListener("mouseup", handler);
  }, []);

  useEffect(() => {
    // Handler for `keydown`.
    function handler(e: KeyboardEvent) {
      if (e.key === "Backspace" || e.key === "Delete") {
        deleteSelectedPin();
      }
    }
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [deleteSelectedPin]);

  const handleSave = () => {
    const imageBounds = editorImageRef!.getBoundingClientRect();
    const processedTags = pins.map((pin) => {
      // We clamp the position of the tags to [0, 1]
      const clampedX = pin.x / imageBounds.width;
      const clampedY = pin.y / imageBounds.height;
      return {
        title: pin.title,
        url: pin.url,
        x: clampedX,
        y: clampedY,
        itemSkuId: pin.itemSkuId,
        sku: pin.sku
      };
    });
    // Update the form values
    form.setValue(`image.tags`, processedTags);
  };

  const handleOpenChange = (isOpen: boolean) => {
    handleSave();
    setIsEditorOpen(isOpen);
  };

  return (
    <>
      <Dialog.Root open={isEditorOpen} onOpenChange={handleOpenChange}>
        <Dialog.Portal>
          <Dialog.Overlay className="z-50" />
          <Dialog.Content className="z-50 grid grid-cols-[auto_auto]">
            <div className="relative">
              <img
                ref={(ref) => setEditorImageRef(ref)}
                src={img.url}
                className="max-h-[min(80vh,700px)] max-w-[min(80vw,800px)]"
                onClick={handleEditorImageClick}
              />
              {pins.map((pin, i) => (
                <div
                  key={i}
                  className={cn(
                    "absolute grid w-[38px] cursor-pointer rounded-md bg-gray-800/70 px-1 py-0.5 text-[10px] text-white",
                    // Translate it so that the little arrow is at the right
                    // position.
                    "-translate-x-1/2 translate-y-[calc(-100%-4px)]",
                    i === selectedPin && "bg-gray-800",
                  )}
                  style={{
                    left: pin.x,
                    top: pin.y,
                  }}
                  onClick={(e) => handlePinClick(i, e)}
                  onMouseDown={(e) => handlePinMouseDown(i, e)}
                >
                  {/* Arrow for the pin */}
                  <div
                    className={cn(
                      "absolute bottom-[-15px] left-1/2 -translate-x-1/2",
                      "border-8 border-x-transparent border-b-transparent border-t-gray-800/70",
                      i === selectedPin && "border-t-gray-800",
                    )}
                  />
                  <p className="line-clamp-1 text-center">{pin?.sku || "..."}</p>
                </div>
              ))}
            </div>
            <div className="grid gap-y-3 self-start">
              <Form.Item>
                <Form.Label>
                  SKU <span className="text-red-700">*</span>
                </Form.Label>
                <Form.Control>
                  <Combobox
                    data={searchItemSkuSelectData}
                    disabled={selectedPin === null}
                    placeholders={{
                      selectValue: "Select SKU",
                      searchValue: "Search SKU",
                      valueNotFound: "No SKU found",
                    }}
                    classNames={{
                      button: "w-full",
                    }}
                    onSearchChange={setSearchQuery}
                    onChange={handleItemSkuChange}
                  />
                </Form.Control>
              </Form.Item>
              <Form.Item>
                <Form.Label>Title</Form.Label>
                <Form.Control>
                  <Input
                    disabled={selectedPin === null}
                    placeholder="Gold Earring"
                    value={selectedPin != null ? pins[selectedPin]!.title : ""}
                    onChange={(e) =>
                      changeSelectedPinProp("title", e.target.value)
                    }
                  />
                </Form.Control>
              </Form.Item>
              <Form.Item>
                <Form.Label>
                  URL <span className="text-red-700">*</span>
                </Form.Label>
                <Form.Control>
                  <Input
                    readOnly
                    disabled={selectedPin === null}
                    type="url"
                    placeholder="https://..."
                    value={selectedPin != null ? pins[selectedPin]!.url : ""}
                    onChange={(e) =>
                      changeSelectedPinProp("url", e.target.value)
                    }
                  />
                </Form.Control>
              </Form.Item>
              <Button
                variant="destructive"
                disabled={selectedPin === null}
                onClick={deleteSelectedPin}
              >
                <TrashFilledIcon className="mr-1" />
                Delete
              </Button>
              <Button onClick={() => handleOpenChange(false)}>Close</Button>
              <p className="text-sm text-slate-400">
                Tags are saved automatically
              </p>
              <p className="text-sm text-slate-400">All fields are required</p>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <div key={img.url} className="grid grid-cols-[auto_auto] gap-x-1">
        <div className="relative mb-3">
          <LoadingOverlay visible={img.isLoading} overlayBlur={2} />
          <img src={img.url} className={"w-full rounded-md object-cover"} />
        </div>
        <div className={cn("flex flex-col gap-y-2")}>
          <Button
            disabled={img.isLoading}
            variant="ghost"
            className="grid size-6 place-items-center p-0 text-red-500"
            onClick={() => removeImage()}
          >
            <CloseOutline className="size-5" />
          </Button>
          <Button
            disabled={img.isLoading}
            variant="ghost"
            className="grid size-6 place-items-center p-0 text-slate-400"
            onClick={() => setIsEditorOpen(true)}
          >
            <EditIcon className="size-4 text-slate-400" />
          </Button>
        </div>
      </div>
    </>
  );
}
