import { trpc } from "#/trpc.js"
import { reportUserError, reportUserSuccess } from "#/util/index.js"
import { CreatePoRestockForm, type CreatePoRestockFormSchema } from "./Form.js"
import { useNavigate } from "react-router"

export const CreatePoRestock = () => {
	const ctx = trpc.useContext()
	const navigate = useNavigate()

	const createPoRestockMutation =
		trpc.v2_5.purchaseOrder.createPurchaseOrder.useMutation({
			onSuccess() {
				ctx.purchaseOrder.invalidate()
				ctx.v2_5.purchaseOrder.invalidate()
				reportUserSuccess({
					title: "Purchase order created",
				})
				navigate("/inventory/upcoming-items")
			},
			onError(error) {
				reportUserError({
					title: "Purchase order not created",
					message: error.message,
				})
			},
		})

	function handleSubmit(status: "DRAFT" | "UPCOMING") {
		return function (values: CreatePoRestockFormSchema) {
			if (status === "UPCOMING") {
				const verify = values.itemSkus.find(
					(itemSku) => itemSku.shippingQuantity === 0,
				)
				if (verify) {
					reportUserError({
						title: "Purchase order not created",
						message: "Shipping quantity must be greater than 0",
					})
					return
				}
			}

			if (status === "UPCOMING" && values.itemSkus.length === 0) {
				reportUserError({
					title: "Purchase order not created",
					message: "Please add at least one item",
				})
				return
			}

			createPoRestockMutation.mutate({
				dutyCost: values.dutyCost,
				status,
				type: "RE_STOCK",
				purchaseOrderShipping: values.shippingInformation.entries.map(
					(entry) => ({
						shippingCompany: entry.company,
						shippingCost: entry.cost,
						shippingNumber: entry.trackingNumber,
					}),
				),
				purchaseOrderItemSkus: values.itemSkus.map((itemSku) => ({
					itemSkuId: itemSku.id,
					expectedQuantity: itemSku.shippingQuantity,
					cost: itemSku.cost,
					unitCost: itemSku.cost,
				})),
			})
		}
	}

	return <CreatePoRestockForm onSubmit={handleSubmit} />
}
