import {
	exportSalesReportXlsx,
	SalesReportData,
} from "#/scenes/Reports/templates/sales-report-template"
import { RouterOutputs } from "#/trpc.js"
import Decimal from "decimal.js"

type EndOfDay = RouterOutputs["report"]["getEndOfDay"]

export async function exportEndOfDayToXLSX(endOfDay: EndOfDay) {
	const nonTaxableNet = endOfDay.discountedSubtotalNonTaxable.minus(
		endOfDay.totalReturnNonTaxable,
	)
	const taxableNet = endOfDay.discountedSubtotalTaxable.minus(
		endOfDay.totalReturnTaxable,
	)
	const taxNet = endOfDay.totalTaxAmount.minus(endOfDay.totalReturnTax)

	const subtotalSales = endOfDay.totalOrderAmount.plus(endOfDay.totalTaxAmount)
	const subtotalReturns = endOfDay.totalReturnNonTaxable
		.plus(endOfDay.totalReturnTaxable)
		.plus(endOfDay.totalReturnTax)
	const subtotalNet = nonTaxableNet.plus(taxableNet).plus(taxNet)

	const totalActivitySales = subtotalSales
		.plus(endOfDay.totalShipping)
		.plus(endOfDay.totalInsurance)
	const totalActivityNet = subtotalNet
		.plus(endOfDay.totalShipping)
		.plus(endOfDay.totalInsurance)

	const data: SalesReportData = {
		from: endOfDay.from,
		to: endOfDay.to,
		salesActivities: {
			nonTaxable: {
				sales: endOfDay.discountedSubtotalNonTaxable,
				returns: endOfDay.totalReturnNonTaxable,
				net: nonTaxableNet,
			},
			taxable: {
				sales: endOfDay.discountedSubtotalTaxable,
				returns: endOfDay.totalReturnTaxable,
				net: taxableNet,
			},
			tax: {
				sales: endOfDay.totalTaxAmount,
				returns: endOfDay.totalReturnTax,
				net: taxNet,
			},
			subtotal: {
				sales: subtotalSales,
				returns: subtotalReturns,
				net: subtotalNet,
			},
			insurance: {
				sales: endOfDay.totalInsurance,
				returns: new Decimal(0),
				net: endOfDay.totalInsurance,
			},
			shipping: {
				sales: endOfDay.totalShipping,
				returns: new Decimal(0),
				net: endOfDay.totalShipping,
			},
			totalActivity: {
				sales: totalActivitySales,
				returns: subtotalReturns,
				net: totalActivityNet,
			},
		},
		payments: {
			totalAmount: endOfDay.payments.totalAmount,
			totalPaidIn: endOfDay.payments.totalPaidIn,
			totalReturned: endOfDay.payments.totalPaidOut,
			methods: endOfDay.payments.orders.flatMap((o) => ({
				method: o.method,
				totalPaidIn: o.totalPaidIn,
				totalReturned: o.totalPaidOut,
				totalAmount: o.totalAmount,
			})),
		},
		inventoryMovement: {
			count: endOfDay.inventoryMovement.count,
			soldItems:
				endOfDay.inventoryMovement.noReturned.totalCountProductsTransacted,
			returnedItems:
				endOfDay.inventoryMovement.returned.totalCountProductsReturned,
		},
	}

	return await exportSalesReportXlsx(data)
}
