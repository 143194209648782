import { styled as s } from "#/css/jsx";
import React from "react";

export function Page(props: { children: React.ReactNode} ) {
	return (
		<s.div
			minW="210mm"
			minH="297mm"
			maxW="210mm"
			maxH="297mm"
			bg="var(--bg)"
			overflow="hidden"
            position="relative"
			style={{
				"--bg": "#f1ecea",
				"--text-color": "#64534c",
			}}
		>
			{props.children}
		</s.div>
	)
}
