import { CloudUploadOutline } from "#/components-ng";
import { cn } from "#/lib/utils";
import { trpc } from "#/trpc";
import {
  S3File,
  UseS3UploadError,
  UseS3UploadObject,
  UseS3UploadResult,
} from "./types";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";

type UseS3Props = {
  onSuccess?: (data: UseS3UploadResult) => void;
  onError?: (data: UseS3UploadError) => void;
  onUploadingStart?: (data: UseS3UploadObject) => void;
};

function useS3(props?: UseS3Props) {
  const trpcClient = trpc.useContext().client;

  const [uploads, setUploads] = useState<UseS3UploadObject[]>([]);
  const upload = useCallback(
    async (s3Files: S3File[]) => {
      // get s3 presigned urls
      const uuid = uuidv4();
      const presignedUrls = await trpcClient.s3.getManyPresignedUrls.query(
        s3Files.map((s3File, i) => {
          // create unique name for file on S3

          const blobUrl = s3File.useBlobUrl
            ? URL.createObjectURL(s3File.body)
            : undefined;

          // update uploads state
          const uploadObject = {
            fileName: s3File.fileName,
            isLoading: true,
            uuid,
            blobUrl,
            url: blobUrl,
          };
          setUploads((prev) => [...prev, uploadObject]);

          // notify that we started uploading
          if (i === 0) {
            props?.onUploadingStart?.(uploadObject);
          }

          return {
            fileName: s3File.fileName,
            directory: s3File.directory,
            uuid,
          };
        })
      );

      s3Files.forEach(async (s3File, i) => {
        try {
          // upload file to S3
          await fetch(presignedUrls[i].presignedUrl, {
            method: "PUT",
            body: s3File.body,
          });

          // update upload state
          setUploads((prev) =>
            prev.map((upload) => {
              if (upload.uuid === presignedUrls[i].uuid) {
                return {
                  ...upload,
                  isLoading: false,
                  s3Url: presignedUrls[i].s3Url,
                  url: presignedUrls[i].s3Url,
                };
              }
              return upload;
            })
          );

          if (i === 0) {
            props?.onSuccess?.({
              fileName: s3File.fileName,
              uuid: presignedUrls[i].uuid,
              url: presignedUrls[i].s3Url,
            });
          }
        } catch (e) {
          props?.onError?.({
            fileName: s3File.fileName,
            uuid: presignedUrls[i].uuid,
            error: e,
          });
        }
      });
    },
    [props, trpcClient.s3.getManyPresignedUrls]
  );

  return { upload, uploads: uploads[0] };
}

type ImageUploadProps = {
  onS3UploadSuccess?: (data: UseS3UploadResult) => void;
  onS3UploadError: (data: UseS3UploadError) => void;
  onS3UploadingStart?: (data: UseS3UploadObject) => void;
  hiddenVisually?: boolean;
};
export function ImageUpload(props: ImageUploadProps) {
  const { upload } = useS3({
    onSuccess: props.onS3UploadSuccess,
    onError: props.onS3UploadError,
    onUploadingStart: props.onS3UploadingStart,
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    async onDrop(acceptedFiles) {
      const sizes = [
        { width: 160, height: 160, name: "thumbnail" },
        { width: 512, height: 512, name: "512" },
        { width: 1280, height: 720, name: "hd" },
        { width: 1920, height: 1080, name: "full_hd" },
        { width: 3840, height: 2160, name: "4k" },
      ];

      if (acceptedFiles[0].type.startsWith("video")) {
        upload([
          {
            fileName: acceptedFiles[0].name,
            body: acceptedFiles[0],
            directory: "item-skus",
            useBlobUrl: true,
          },
        ]);
      }

      const imagesArray: File[] = [acceptedFiles[0]];

      for await (const size of sizes) {
        const imageFile = await resizeFile(
          acceptedFiles[0],
          size.width,
          size.height
        );

        imagesArray.push(imageFile);
      }

      upload(
        imagesArray.map((file, i) => {
          const name =
            i === 0
              ? file.name
              : `${file.name.split(".")[0]}.${sizes[i - 1]?.name}.${
                  file.name.split(".")[1]
                }`;

          return {
            fileName: name,
            body: file,
            directory: "item-skus",
            useBlobUrl: true,
          };
        })
      );
    },
  });

  return (
    <div
      className={cn(
        "flex gap-x-6 rounded border border-slate-100 bg-white p-4 transition-shadow duration-200",
        isDragActive && "shadow-inner"
      )}
      {...getRootProps()}
    >
      <CloudUploadOutline className="text-brand-500 text-4xl" />
      <div>
        <p className="font-medium">
          Drop your image or video here, or{" "}
          <span className="text-brand-500">click to browse</span>
        </p>
        <p className="text-sm text-slate-500">Supports JPG, PNG, MP4</p>
      </div>
      {props.hiddenVisually ? (
        <input {...getInputProps()} />
      ) : (
        <VisuallyHidden.Root>
          <input {...getInputProps()} />
        </VisuallyHidden.Root>
      )}
    </div>
  );
}

const resizeFile = (file: File, width: number, height: number): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "jpg",
      95,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file"
    );
  });
