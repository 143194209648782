import { styled } from "#/css/jsx"
import { Button, Table } from "@gt/ui"
import { useState } from "react"
import type { PurchaseOrderDocumentType } from "server"
import { Drawer } from "@mantine/core"
import * as M from "@mantine/core"
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { z } from "zod"
import {
	makeController,
	PencilCustomIcon,
	TrashFilledIcon,
} from "#/components-ng"
import {
	type DefaultValues,
	FormProvider,
	type SubmitHandler,
} from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { trpc } from "#/trpc"
import { css } from "#/css/css"
import {
	FileUpload,
	type UseS3UploadObject,
	type UseS3UploadResult,
} from "#/components-ng/ui"
import { reportUserError, reportUserSuccess } from "#/util"
import { MdOutlineDownload } from "react-icons/md"
import FileSaver from "file-saver"
import { useParams } from "react-router"
import Decimal from "decimal.js"

interface VendorDocument {
	id: number
	title: string
	fileUrl: string
	type: PurchaseOrderDocumentType
	totalPaid?: number | null
}

interface VendorDocumentsSectionButtonProps {
	documents: VendorDocument[]
}

export const VendorDocumentsSectionButton = (
	props: VendorDocumentsSectionButtonProps,
) => {
	const params = useParams() as any
	const poId = Number(params.poId)
	const vendorId = Number(params.vendorId)
	const [openDrawer, setOpenDrawer] = useState<boolean>(false)

	return (
		<styled.div>
			<Button
				type="button"
				aria-label="Add/Edit vendor documents"
				aria-labelledby="add-edit-vendor-documents"
				onClick={() => setOpenDrawer(true)}
			>
				Add / Edit documents
			</Button>
			<VendorDocumentsSection
				open={openDrawer}
				setOpen={setOpenDrawer}
				poId={poId}
				vendorId={vendorId}
				documents={props.documents}
			/>
		</styled.div>
	)
}

interface VendorDocumentsSectionProps {
	open: boolean
	setOpen: (value: boolean) => void
	poId: number
	vendorId: number
	documents: VendorDocument[]
}

const VendorDocumentsSection = ({
	open,
	setOpen,
	poId,
	vendorId,
	documents,
}: VendorDocumentsSectionProps) => {
	const [openForm, setForm] = useState<boolean>(false)

	const table = useReactTable({
		columns: columns(setOpen, vendorId, poId),
		data: documents,
		getCoreRowModel: getCoreRowModel(),
	})

	const ctx = trpc.useContext()
	const { mutate: createDocument, isLoading } =
		trpc.v2_5.purchaseOrder.createVendorDocument.useMutation({
			onSuccess(data) {
				if (data) {
					ctx.v2_5.purchaseOrder.invalidate()
					setForm(false)
					setOpen(true)
				}
			},
		})

	const handleSubmit = (values: CreateDocumentFormSchema) => {
		if (values.fileUrl.length === 0) {
			reportUserError({
				title: "File not uploaded",
				message: "Please upload a file",
			})
			return
		}

		createDocument({
			title: values.title,
			fileUrl: values.fileUrl,
			type: values.type,
			vendorId: vendorId,
			totalPaid: values?.totalPaid ?? null,
			purchaseOrderId: poId,
		})
	}

	return (
		<>
			<Drawer
				opened={open}
				onClose={() => setOpen(false)}
				position="right"
				size="lg"
			>
				<styled.div display="flex" flexDirection="column" gap="md">
					<styled.div
						display="flex"
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<styled.h3>Documents</styled.h3>
						<Button
							variant="default"
							type="button"
							aria-label="Add vendor"
							aria-labelledby="add-vendor"
							onClick={() => {
								setForm(true)
								setOpen(false)
							}}
							size="sm"
						>
							Add new document
						</Button>
					</styled.div>
					<styled.hr marginY="1rem" />
					<SearchVendorDocumentField vendorId={vendorId} poId={poId} />
					<styled.div mt="1rem">
						<Table.Root>
							<Table.Header>
								{table.getHeaderGroups().map((headerGroup) => (
									<Table.Row key={headerGroup.id}>
										{headerGroup.headers.map((header) => (
											<Table.Head
												key={header.id}
												className="sticky top-0 bg-white"
											>
												{header.isPlaceholder
													? null
													: flexRender(
															header.column.columnDef.header,
															header.getContext(),
														)}
											</Table.Head>
										))}
									</Table.Row>
								))}
							</Table.Header>
							<Table.Body>
								{table.getRowModel().rows.map((row) => (
									<Table.Row key={row.id}>
										{row.getVisibleCells().map((cell) => (
											<Table.Cell key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</Table.Cell>
										))}
									</Table.Row>
								))}
							</Table.Body>
						</Table.Root>
					</styled.div>
				</styled.div>
			</Drawer>
			<VendorDocumentForm
				open={openForm}
				setOpen={setForm}
				onSubmit={handleSubmit}
				isLoading={isLoading}
			/>
		</>
	)
}

const column = createColumnHelper<VendorDocument>()

const columns = (
	setOpen: (value: boolean) => void,
	vendorId: number,
	poId?: number | null,
) => [
	column.accessor("title", {
		header: "Title",
	}),
	column.accessor("type", {
		header: "Type",
	}),
	column.accessor("id", {
		header: "Actions",
		cell: (row) => {
			const [openForm, setOpenForm] = useState<boolean>(false)
			const original = row.row.original
			const ctx = trpc.useContext()

			const { mutate: deletePoDocument, isLoading: isLoadingDelete } =
				trpc.v2_5.purchaseOrder.deletePurchaseOrderDocument.useMutation({
					onSuccess: () => {
						ctx.v2_5.purchaseOrder.invalidate()
						reportUserSuccess({
							title: "Vendor Document deleted",
							message: "Vendor document has been deleted successfully",
						})
					},
				})

			const { mutate: updateVendorDocument, isLoading: isLoadingUpdate } =
				trpc.v2_5.purchaseOrder.updateVendorDocument.useMutation({
					onSuccess(data) {
						if (data) {
							ctx.v2_5.purchaseOrder.invalidate()
							setOpenForm(false)
							setOpen(true)
						}
					},
				})

			const handleDelete = () => {
				if (poId) {
					deletePoDocument({
						vendorDocumentId: original.id,
						poId: poId,
					})
					return
				}
			}

			const handleUpdate = (values: CreateDocumentFormSchema) => {
				if (values.fileUrl.length === 0) {
					reportUserError({
						title: "File not uploaded",
						message: "Please upload a file",
					})
					return
				}

				updateVendorDocument({
					id: original.id,
					title: values.title,
					fileUrl: values.fileUrl,
					type: values.type,
					vendorId: vendorId,
					totalPaid: values?.totalPaid
						? values.totalPaid == 0
							? null
							: values.totalPaid
						: null,
				})
			}

			return (
				<>
					<styled.div display="flex" gap="1rem" alignItems="center">
						<M.ActionIcon
							variant="outline"
							onClick={() => {
								setOpenForm(true)
							}}
						>
							<PencilCustomIcon className="h-4 w-4" />
						</M.ActionIcon>
						<M.ActionIcon
							variant="filled"
							color="red"
							loading={isLoadingDelete}
							onClick={handleDelete}
						>
							<TrashFilledIcon />
						</M.ActionIcon>
						<M.ActionIcon
							variant="outline"
							onClick={() => {
								FileSaver.saveAs(
									row.row.original.fileUrl,
									row.row.original.title,
								)
							}}
						>
							<MdOutlineDownload color="gray" />
						</M.ActionIcon>
					</styled.div>
					<VendorDocumentForm
						open={openForm}
						setOpen={setOpenForm}
						defaultValues={{
							title: original.title,
							fileUrl: original.fileUrl,
							type: original.type,
							totalPaid: original?.totalPaid ?? null,
						}}
						isLoading={isLoadingUpdate}
						onSubmit={handleUpdate}
					/>
				</>
			)
		},
	}),
]

interface SearchVendorDocumentFieldProps {
	vendorId: number
	poId: number
}

const SearchVendorDocumentField = ({
	vendorId,
	poId,
}: SearchVendorDocumentFieldProps) => {
	const [query, setQuery] = useState<string>("")
	const ctx = trpc.useContext()

	const { data } = trpc.v2_5.purchaseOrder.searchVendorDocument.useQuery({
		query,
		vendorId,
	})

	const { mutate } =
		trpc.v2_5.purchaseOrder.createPurchaseOrderDocument.useMutation({
			onSuccess() {
				ctx.v2_5.purchaseOrder.invalidate()
				reportUserSuccess({
					title: "Vendor document asociated with purchase order",
					message: "Vendor document has been asociated with purchase order",
				})
			},
		})

	const handleCreatePurchaseOrderDocument = (vendorDocumentId: number) => {
		mutate({
			vendorDocumentId,
			purchaseOrderId: poId,
		})
	}

	return (
		<styled.div display="flex" flexDirection="column" gap="1rem" mt="1rem">
			<M.Select
				label="Search vendor document"
				data={
					data?.map((doc) => ({
						value: doc.id.toString(),
						label: doc.title,
					})) ?? []
				}
				value={null}
				searchable
				clearable
				onInput={(value) => {
					setQuery(value?.currentTarget?.value ?? "")
				}}
				className={css({ maxWidth: "200px" })}
				nothingFound="No vendor documents found"
				onChange={(value) => {
					if (!value) {
						return
					}

					const doc = data?.find((d) => d.id === Number(value))

					if (doc) {            
						handleCreatePurchaseOrderDocument(doc.id)
					}
				}}
			/>
		</styled.div>
	)
}

export const documentTypes = [
	"PACKING_LIST",
	"ORIGINAL_INVOICE",
	"PAYMENT_VOUCHER",
] as const

const createDocumentFormSchema = z.object({
	title: z.string({
		required_error: "Title is required",
	}),
	fileUrl: z.string(),
	type: z.enum(documentTypes),
	totalPaid: z.number().nullish(),
})
export type CreateDocumentFormSchema = z.infer<typeof createDocumentFormSchema>

const CD = makeController<CreateDocumentFormSchema>()

interface VendorDocumentFormProps {
	onSubmit: SubmitHandler<CreateDocumentFormSchema>
	defaultValues?: DefaultValues<CreateDocumentFormSchema>
	open: boolean
	setOpen: (value: boolean) => void
	isLoading: boolean
}

const VendorDocumentForm = (props: VendorDocumentFormProps) => {
	const [fileUrl, setFileUrl] = useState<{
		url: string
		isLoading: boolean
	} | null>(null)

	const form = CD.useForm({
		resolver: zodResolver(createDocumentFormSchema),
		shouldUnregister: false,
		defaultValues: {
			title: "",
			fileUrl: "",
			type: "ORIGINAL_INVOICE",
			totalPaid: 0,
			...props.defaultValues,
		},
	})

	const handleS3UploadSuccess = (upload: UseS3UploadResult) => {
		form.setValue("fileUrl", upload.url)
		setFileUrl({
			url: upload.url,
			isLoading: false,
		})
	}

	const handleS3UploadingStart = (upload: UseS3UploadObject) => {
		setFileUrl({
			url: upload.url!,
			isLoading: true,
		})
		form.setValue("fileUrl", upload.url!)
	}

	const type = form.watch("type")

	return (
		<Drawer
			title={
				<styled.h2>
					{props.defaultValues ? "Edit document" : "Add document"}
				</styled.h2>
			}
			opened={props.open}
			onClose={() => props.setOpen(false)}
			position="right"
			size="lg"
		>
			<FormProvider {...form}>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						form.handleSubmit(props.onSubmit)(e)
						form.reset()
					}}
				>
					<styled.div display="flex" flexDirection="column" gap="1rem">
						<CD.InputField
							label="Title *"
							name="title"
							required
							placeholder="Enter title"
						/>
						<CD.SelectField
							label="Type *"
							name="type"
							placeholder="Select type"
							required
							data={documentTypes.map((type) => ({
								id: type,
								label: type,
							}))}
							classNames={{
								content: css({
									zIndex: "1000 !important",
								}),
							}}
						/>
						<CD.NumberInputField
							label="Total Paid"
							name="totalPaid"
							placeholder="Enter total paid"
							disabled={type !== "PAYMENT_VOUCHER" ? true : false}
							type="number"
						/>
						<CD.InputField
							label="File URL *"
							name="fileUrl"
							required
							disabled
							className="pointer-events-none"
						/>
						<FileUpload
							directory="vendor-documents"
							label="Vendor Document"
							onS3UploadSuccess={handleS3UploadSuccess}
							onS3UploadingStart={handleS3UploadingStart}
						/>

						<styled.div display="flex" justifyContent="flex-end">
							<Button
								type="submit"
								disabled={fileUrl?.isLoading || props.isLoading}
							>
								Save
							</Button>
						</styled.div>
					</styled.div>
				</form>
			</FormProvider>
		</Drawer>
	)
}
