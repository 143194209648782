import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { C, createCatalogSchema, FormValues, types } from "./types.js";
import { styled } from "#/css/jsx";
import { css } from "#/css/css";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@gt/ui";
import { v4 as uuidv4 } from "uuid";
import {
  ImageUpload,
  UseS3UploadObject,
  UseS3UploadResult,
} from "#/components-ng/ui/media-upload.js";
import { IconButton } from "@radix-ui/themes";
import CloseIcon from "~icons/ion/close-circle-outline";

export interface CatalogFormProps {
  title: string;
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
  defaultValues?: DefaultValues<FormValues>;
  catalogId?: number | null;
  itemSkus?:
    | {
        id: number;
        sku: number;
        title: string;
        defaultImage?: string | null;
      }[]
    | null;
}

interface ImageUploadProps {
  url: string;
  uuid: string;
  isLoading: boolean;
}

export const CatalogInfo = (props: CatalogFormProps) => {
  const form = C.useForm({
    resolver: zodResolver(createCatalogSchema),
    shouldUnregister: false,
    defaultValues: {
      title: "",
      titleEsp: null,
      description: "",
      descriptionEsp: null,
      type: "BY_CATALOG_IMAGE",
      defaultImage: null,
      ...props.defaultValues,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
    shouldFocusError: false,
  });

  const [defaultImage, setDefaultImage] = useState<ImageUploadProps | null>(
    () =>
      form.getValues("defaultImage")
        ? {
            url: form.getValues("defaultImage")!,
            uuid: uuidv4(),
            isLoading: false,
          }
        : null,
  );

  const handleS3UploadDefaultImageStart = (upload: UseS3UploadObject) => {
    setDefaultImage({
      url: upload.url!,
      uuid: uuidv4(),
      isLoading: true,
    });
  };

  const handleS3UploadDefaultImageSuccess = (upload: UseS3UploadResult) => {
    setDefaultImage({
      url: upload.url!,
      uuid: uuidv4(),
      isLoading: false,
    });
    form.setValue("defaultImage", upload.url);
  };

  return (
    <styled.div
      bg="white"
      p="40px"
      rounded="md"
      border="1px solid #E0E0E0"
      mb={10}
      height="auto"
    >
      <FormProvider {...form}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            form.handleSubmit(props.onSubmit)(e);
          }}
        >
          <styled.div
            display="flex"
            width="full"
            justifyContent="space-between"
            gap={6}
            flexDirection="column"
            lg={{
              flexDirection: "row",
            }}
          >
            <styled.div
              display="flex"
              flexDirection="column"
              gap={6}
              width="full"
            >
              <C.InputField
                label="Title (English) *"
                name="title"
                required
                placeholder="Enter title"
              />
              <C.InputField
                label="Title (Spanish)"
                name="titleEsp"
                placeholder="Ingresa el titulo"
              />
              <C.TextareaField
                label="Description (English)"
                name="description"
                classNames={{
                  input: "min-h-10 border-slate-200 bg-white",
                }}
                placeholder="Enter description here"
              />
              <C.TextareaField
                label="Description (Spanish)"
                name="descriptionEsp"
                classNames={{
                  input: "min-h-10 border-slate-200 bg-white",
                }}
                placeholder="Ingresa la descripcion"
              />
              <C.SelectField
                label="Type"
                name="type"
                placeholder="Select type"
                required
                data={types.map((type) => ({
                  id: type,
                  label: type,
                }))}
              />
              <C.SwitchField label="Relational" name="relational" />
              <Button
                type="submit"
                className={css({
                  width: "fit",
                })}
                isLoading={props.isLoading || defaultImage?.isLoading}
              >
                Save catalog
              </Button>
            </styled.div>
            <styled.div
              display="flex"
              flexDirection="column"
              gap={6}
              width="full"
            >
              <styled.p fontSize="sm" fontWeight="medium" color="#64748B">
                Default image
              </styled.p>
              {defaultImage?.url ? (
                <styled.div
                  position="relative"
                  width="100%"
                  maxHeight="400px"
                  height="200px"
                  lg={{ height: "400px" }}
                >
                  <styled.img
                    src={defaultImage.url}
                    alt="Catalog image"
                    width="full"
                    height="full"
                    objectFit="contain"
                  />
                  <styled.div
                    position="absolute"
                    top={0}
                    right={0}
                    p={2}
                    rounded="full"
                  >
                    <IconButton
                      variant="ghost"
                      onClick={() => {
                        form.setValue("defaultImage", null);
                        setDefaultImage(null);
                      }}
                    >
                      <CloseIcon
                        fontSize={20}
                        color="red"
                        className={css({ cursor: "pointer" })}
                      />
                    </IconButton>
                  </styled.div>
                </styled.div>
              ) : (
                <ImageUpload
                  directory="catalog"
                  supports={{
                    image: true,
                  }}
                  onS3UploadSuccess={handleS3UploadDefaultImageSuccess}
                  onS3UploadingStart={handleS3UploadDefaultImageStart}
                  hiddenVisually={true}
                />
              )}
            </styled.div>
          </styled.div>
        </form>
      </FormProvider>
    </styled.div>
  );
};
