import {
  ImageUpload,
  UseS3UploadObject,
  UseS3UploadResult,
} from "#/components-ng/ui";
import {
  FormValues,
  createBannerSchema,
  C,
  textTemplates,
  buttonVariants,
  pages,
  positions,
} from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

export interface HomePageBannerFormProps {
  defaultValues?: DefaultValues<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  isLoading: boolean;
}

interface ImageUploadProps {
  url: string;
  uuid: string;
  isLoading: boolean;
}

export const HomePageBannerForm = ({
  defaultValues,
  isLoading,
  onSubmit,
}: HomePageBannerFormProps) => {
  const [dateRangeFilter, setDateRangeFilter] = useState({
    from: new Date(),
    to: dayjs().add(7, "days").toDate(),
  });
  const [openModalDesktop, setOpenModalDesktop] = useState<boolean>(false);
  const [openModalMobile, setOpenModalMobile] = useState<boolean>(false);

  const form = C.useForm({
    resolver: zodResolver(createBannerSchema),
    shouldUnregister: false,
    defaultValues: {
      template: "template1",
      buttonVariant: "FILLED",
      page: "HOME",
      position: "1",
      content: [
        {
          position: "1",
          content: "Text 1",
          contentEsp: null,
          colorHex: "#000000",
        },
        {
          position: "2",
          content: "Text 2",
          contentEsp: null,
          colorHex: "#000000",
        },
        {
          position: "3",
          content: "Text 3",
          contentEsp: null,
          colorHex: "#000000",
        },
        {
          position: "4",
          content: "Text 4",
          contentEsp: null,
          colorHex: "#000000",
        },
      ],
      ...defaultValues,
    },
  });

  const [desktopImage, setDesktopImage] = useState<ImageUploadProps | null>(
    () =>
      form.getValues(`imageDesktop`)
        ? {
            url: form.getValues(`imageDesktop`)!,
            uuid: uuidv4(),
            isLoading: false,
          }
        : null,
  );

  const [mobileImage, setMobileImage] = useState<ImageUploadProps | null>(() =>
    form.getValues(`imageMobile`)
      ? {
          url: form.getValues(`imageMobile`)!,
          uuid: uuidv4(),
          isLoading: false,
        }
      : null,
  );

  const contents = C.useFieldArray({
    control: form.control,
    name: "content",
  });

  const handleS3UploadDesktopImageStart = (upload: UseS3UploadObject) => {
    setDesktopImage({
      url: upload.url!,
      uuid: upload.uuid,
      isLoading: true,
    });
  };

  const handleS3UploadDesktopImageSuccess = (result: UseS3UploadResult) => {
    setDesktopImage({
      url: result.url,
      uuid: result.uuid,
      isLoading: false,
    });
    form.setValue(`imageDesktop`, result.url);
  };

  const handleS3UploadMobileImageStart = (upload: UseS3UploadObject) => {
    setMobileImage({
      url: upload.url!,
      uuid: upload.uuid,
      isLoading: true,
    });
  };

  const handleS3UploadMobileImageSuccess = (result: UseS3UploadResult) => {
    setMobileImage({
      url: result.url,
      uuid: result.uuid,
      isLoading: false,
    });
    form.setValue(`imageMobile`, result.url);
  };

  // @ts-ignore
  const SHOP_BASE_URL = import.meta.env.SECRET_SHOP_BASE_URL;

  const content = {
    text1: form.watch("content.0.content"),
    color1: form.watch("content.0.colorHex"),
    text2: form.watch("content.1.content"),
    color2: form.watch("content.1.colorHex"),
    text3: form.watch("content.2.content"),
    color3: form.watch("content.2.colorHex"),
    text4: form.watch("content.3.content"),
    color4: form.watch("content.3.colorHex"),
    buttonText: form.watch("buttonText"),
    buttonVariant: form.watch("buttonVariant"),
    buttonColor: form.watch("buttonHexColor"),
    withButton: form.watch("withButton"),
    redirectTo: form.watch("redirectTo"),
    bgDesktop: form.watch("imageDesktop"),
    bgMobile: form.watch("imageMobile"),
    template: form.watch("template"),
  };

  const params = encodeURIComponent(JSON.stringify(content));
  const urlIframe = `${SHOP_BASE_URL}/preview?bannerProps=${params}`;

  return (
    <M.Container size="xl">
      <M.Box
        bg="#fff"
        p="2.5rem"
        className="mb-10 rounded-md border border-[#E0E0E0]"
      >
        <M.Title order={2} className="mb-6">
          Home page banner
        </M.Title>
        <M.Divider className="my-8 stroke-[#E0E0E0]" />
        <FormProvider {...form}>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              form.handleSubmit(onSubmit)(e);
            }}
          >
            <M.Group position="apart" noWrap={false} align="initial">
              <M.Stack className="w-full p-2 lg:max-w-[500px]">
                <C.InputField
                  label="Title"
                  name="title"
                  placeholder="Enter title"
                  required
                />
                <C.SelectField
                  label="Select template *"
                  name="template"
                  placeholder="Select template"
                  required
                  data={textTemplates.map((template) => ({
                    id: template,
                    label: template,
                  }))}
                />
                <C.SelectField
                  label="Select page *"
                  name="page"
                  placeholder="Select page"
                  required
                  data={pages.map((page) => ({
                    id: page,
                    label: page,
                  }))}
                />
                <C.SelectField
                  label="Select position *"
                  name="position"
                  placeholder="Select position"
                  required
                  data={positions.map((position) => ({
                    id: position,
                    label: position,
                  }))}
                />
                <M.Group className="mb-4" spacing="xs" position="apart">
                  <C.M
                    name="dateRange"
                    as={DatePickerInput}
                    label="Date Range"
                    type="range"
                    defaultValue={[dateRangeFilter.from, dateRangeFilter.to]}
                    onChange={(v) => {
                      if (v[0] == null || v[1] == null) return;
                      setDateRangeFilter({
                        from: v[0],
                        to: v[1],
                      });
                    }}
                    className="w-full"
                  />
                </M.Group>
                <C.InputField
                  label="Redirect to"
                  name="redirectTo"
                  placeholder="Enter link"
                  required
                />
                <M.Divider className="my-8 stroke-[#E0E0E0]" />

                <M.Text className="mb-4" size="md" weight="bold">
                  Banner content:
                </M.Text>

                {contents.fields.map((field, index) => {
                  return (
                    <M.Group
                      spacing="xs"
                      className="mb-4"
                      key={field.position}
                      position="apart"
                    >
                      <C.InputField
                        label={`Text position: ${field.position} (English)`}
                        name={`content.${index}.content`}
                        placeholder="Enter title"
                      />
                      <C.InputField 
                        label={`Text position: ${field.position} (Spanish)`}
                        name={`content.${index}.contentEsp`}
                        placeholder="Ingresa el titulo en español"
                      />
                      <C.M
                        as={M.ColorInput}
                        label="Color"
                        name={`content.${index}.colorHex`}
                        placeholder="Enter color"
                      />
                    </M.Group>
                  );
                })}

                <M.Divider className="my-8 stroke-[#E0E0E0]" />
                <M.Text className="mb-4" size="md" weight="bold">
                  Button:
                </M.Text>
                <C.SwitchField label="Show button" name="withButton" />
                <C.SelectField
                  label="Select button variant"
                  name="buttonVariant"
                  placeholder="Select button variant"
                  required={form.watch("withButton")}
                  data={buttonVariants.map((variant) => ({
                    id: variant,
                    label: variant,
                  }))}
                  disabled={!form.watch("withButton")}
                />
                <M.Group className="mb-4" spacing="xs" position="apart">
                  <C.InputField
                    label="Button text (English)"
                    name="buttonText"
                    placeholder="Enter button text"
                    required={form.watch("withButton")}
                    disabled={!form.watch("withButton")}
                  />
                  <C.InputField
                    label="Button text (Spanish)"
                    name="buttonTextEsp"
                    placeholder="Ingresa el texto"
                    disabled={!form.watch("withButton")}
                  />
                  <C.M
                    as={M.ColorInput}
                    label="Button color"
                    name="buttonHexColor"
                    placeholder="Enter button color"
                    required={form.watch("withButton")}
                    disabled={!form.watch("withButton")}
                  />
                </M.Group>
              </M.Stack>
              <M.Stack className="w-full p-2 lg:max-w-[600px]">
                <M.Tabs defaultValue="desktop">
                  <M.Tabs.List grow>
                    <M.Tabs.Tab value="desktop">Desktop</M.Tabs.Tab>
                    <M.Tabs.Tab value="mobile">Mobile</M.Tabs.Tab>
                  </M.Tabs.List>
                  <M.Tabs.Panel value="desktop">
                    <M.Stack>
                      <div className="my-5 flex items-center gap-x-10">
                        <M.Text className="text-sm font-medium text-slate-500">
                          Upload your desktop banner image
                        </M.Text>
                      </div>
                      <M.Group>
                        {desktopImage?.url && (
                          <M.Avatar size="xl" src={desktopImage?.url} />
                        )}
                        <ImageUpload
                          directory="banners"
                          supports={{
                            image: true,
                          }}
                          onS3UploadSuccess={handleS3UploadDesktopImageSuccess}
                          onS3UploadingStart={handleS3UploadDesktopImageStart}
                          hiddenVisually={true}
                        />
                      </M.Group>
                      <M.Text className="mt-2 text-xs font-medium text-slate-500">
                        Suggested size: 1920 x 688 px
                      </M.Text>
                      <M.Box>
                        <M.Button
                          variant="light"
                          onClick={() => setOpenModalDesktop(true)}
                        >
                          Preview
                        </M.Button>
                        <M.Modal
                          fullScreen
                          opened={openModalDesktop}
                          onClose={() => setOpenModalDesktop(false)}
                          centered
                        >
                          <iframe
                            src={urlIframe}
                            className="h-[1080px] w-[1920px] scale-75 "
                          />
                        </M.Modal>
                      </M.Box>
                    </M.Stack>
                  </M.Tabs.Panel>
                  <M.Tabs.Panel value="mobile">
                    <M.Stack>
                      <div className="my-5 flex items-center gap-x-10">
                        <M.Text className="text-sm font-medium text-slate-500">
                          Upload your mobile banner image
                        </M.Text>
                      </div>
                      <M.Group>
                        {mobileImage?.url && (
                          <M.Avatar size="xl" src={mobileImage?.url} />
                        )}

                        <ImageUpload
                          directory="banners"
                          supports={{
                            image: true,
                          }}
                          onS3UploadSuccess={handleS3UploadMobileImageSuccess}
                          onS3UploadingStart={handleS3UploadMobileImageStart}
                          hiddenVisually={true}
                        />
                      </M.Group>
                      <M.Text className="mt-2 text-xs font-medium text-slate-500">
                        Suggested size: 375 x 626 px
                      </M.Text>
                      <M.Box>
                        <M.Button
                          variant="light"
                          onClick={() => setOpenModalMobile(true)}
                        >
                          Preview
                        </M.Button>
                        <M.Modal
                          opened={openModalMobile}
                          onClose={() => setOpenModalMobile(false)}
                          centered
                          size="md"
                        >
                          <iframe
                            src={urlIframe}
                            className="h-[800px] w-[412px]"
                          />
                        </M.Modal>
                      </M.Box>
                    </M.Stack>
                  </M.Tabs.Panel>
                </M.Tabs>
              </M.Stack>
            </M.Group>
            <div className="flex w-full lg:justify-start">
              <M.Button
                className="mt-2 px-12 "
                type="submit"
                loading={
                  isLoading || mobileImage?.isLoading || desktopImage?.isLoading
                }
              >
                Save banner
              </M.Button>
            </div>
          </form>
        </FormProvider>
      </M.Box>
    </M.Container>
  );
};
