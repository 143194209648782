import { getDefaultStore, Atom } from "jotai/vanilla"
import { useAtom, useAtomValue, useSetAtom } from "jotai/react"

const _useGlobalAtom = (atom: any) => {
	const [state, setState] = useAtom(atom, {
		store: getDefaultStore(),
	})
	return [state, setState] as const
}

export const useGlobalAtom = _useGlobalAtom as typeof useAtom

const _useGlobalAtomValue = (atom: any) => {
	const state = useAtomValue(atom, {
		store: getDefaultStore(),
	})

	return state
}

export const useGlobalAtomValue = _useGlobalAtomValue as typeof useAtomValue

const _useSetGlobalAtom = (atom: any) => {
	const setState = useSetAtom(atom, {
		store: getDefaultStore(),
	})
	return setState
}

export const useSetGlobalAtom = _useSetGlobalAtom as typeof useSetAtom
