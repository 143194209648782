import { useAuth } from "#/context/AuthContext";
import { RouterOutputs, trpc } from "#/trpc";
import { Button, Dropdown } from "@gt/ui";
import dayjs from "dayjs";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import { Link } from "react-router-dom";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { openSendOrdersPdfsModal } from "./send-orders-pdfs";
import { openInactivateUserModal } from "./inactivate-user-modal";
import { reportUserError, reportUserSuccess } from "#/util";
import { openConfirmModal } from "@mantine/modals";
import { NumberInput, PasswordInput } from "@mantine/core";
import { generateOTPModal } from "./generate-otp-modal";
import { IconButton, Tooltip } from "@radix-ui/themes";
import { MdOutlineDownload } from "react-icons/md";
import { css } from "#/css/css";

export interface CustomersTableeProps {
  search?: string | null;
}

export const CustomersTable = (props: CustomersTableeProps) => {
  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  // sorting
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const querySorting = useMemo(() => {
    if (sorting[0]) {
      return {
        desc: sorting[0].desc,
        key: sorting[0].id as any,
      };
    }
    return null;
  }, [sorting]);

  const { data, isLoading } = trpc.v2_5.user.getUsersByPage.useQuery(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: props.search,
      filters: {
        type: "CUSTOMER",
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutate: exportCsv, isLoading: isLoadingExport } =
    trpc.v2_5.user.exportUsersTableToCsv.useMutation({
      onSuccess: () => {
        reportUserSuccess({
          title: "The csv file has been sent to email",
        });
      },
    });

  const handleExportCsv = () => {
    exportCsv({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: querySorting,
      search: props.search,
      filters: {
        type: "CUSTOMER",
      },
    });
  };

  const table = useMantineReactTable({
    data: data?.entries ?? [],
    columns,
    manualPagination: true,
    enableTopToolbar: false,
    rowCount: data?.totalEntries ?? 0,
    enableFilters: false,
    state: {
      pagination,
      sorting,
      isLoading,
    },
    enableStickyHeader: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    renderBottomToolbarCustomActions: () => (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 2,
        })}
      >
        <Tooltip content="Export to CSV">
          <IconButton
            color="gray"
            variant="soft"
            loading={isLoadingExport}
            onClick={handleExportCsv}
          >
            <MdOutlineDownload />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  return <MantineReactTable table={table} />;
};

type Customer =
  RouterOutputs["v2_5"]["user"]["getUsersByPage"]["entries"][number];

const columns: MRT_ColumnDef<Customer>[] = [
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    size: 100,
    Cell: (table) => {
      const [{ auth }] = useAuth();
      const ctx = trpc.useContext();
      const original = table.row.original;
      const pin = useRef("pin");
      const credits = useRef(original.credits?.toNumber()) as any;

      const { mutate: generateOtp } = trpc.v2_5.auth.authWithOtp.useMutation({
        onError: (error) => {
          reportUserError({
            title: "Failed to generate OTP",
            message: error.message,
          });
        },
        onSuccess: (data) => {
          if(data.isErr()){
            reportUserError({
              title: "Failed to generate OTP",
              message: data.error.message,
            });
            return
          }

          generateOTPModal({
            otp: data.value.otp,
            token: data?.value.token,
          });
          reportUserSuccess({
            message: "OTP sent successfully",
          });
        },
      });

      const { mutate: adjustCredits } =
        trpc.v2_5.user.updateCredits.useMutation({
          onError: (error) => {
            reportUserError({
              title: "Failed to adjust credits",
              message: error.message,
            });
          },
          onSuccess: () => {
            reportUserSuccess({
              message: "Credits adjusted successfully",
            });
            ctx.user.invalidate();
            ctx.v2_5.user.invalidate();
          },
        });

      const handleAdjustCredits = async () => {
        if (!auth?.user?.filialId) {
          reportUserError({
            title: "Failed to adjust credits",
            message:
              "you need to have a filial assigned to be able to adjust credits",
          });
          return;
        }

        adjustCredits({
          id: Number(original.id),
          pin: pin.current,
          credits: credits.current,
        });
      };

      const askForPinForAdjustCredits = () => {
        openConfirmModal({
          title: "Are you sure you want to adjust credits?",
          children: auth.role.name !== "ROOT" && (
            <>
              <PasswordInput
                label="Pin"
                onChange={(e) => (pin.current = e.currentTarget.value)}
              />
              <NumberInput
                label="Credits"
                onChange={(e) => (credits.current = e)}
                defaultValue={original.credits.toNumber()}
                min={0}
                precision={2}
              />
            </>
          ),
          labels: {
            confirm: "Adjust",
            cancel: "Cancel",
          },
          confirmProps: { color: "red" },
          onConfirm: () => {
            handleAdjustCredits();
          },
        });
      };

      return (
        <>
          <Dropdown.Root>
            <Dropdown.Trigger>
              <Button variant="ghost" size="icon">
                <MenuIcon />
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Dropdown.Item asChild>
                <Link to={`preview/${original.id}`}>Preview</Link>
              </Dropdown.Item>
              <Dropdown.Item asChild>
                <Link to={`edit/${original.id}`}>Edit</Link>
              </Dropdown.Item>
             {
              original.suspended === false && (
                <Dropdown.Item
                  onClick={() => openInactivateUserModal(original.id)}
                >
                  Inactivate
                </Dropdown.Item>
              )
             }
              <Dropdown.Item
                onClick={() => {
                  generateOtp({
                    returnOtp: true,
                    email: original.email,
                    method: "EMAIL",
                  });
                }}
              >
                {" "}
                Generate OTP by email
              </Dropdown.Item>
              <Dropdown.Item onClick={askForPinForAdjustCredits}>
                Adjust credits
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => openSendOrdersPdfsModal(original.id)}
              >
                Send orders pdfs
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Root>
        </>
      );
    },
  },
  {
    id: "name",
    header: "Customer",
    accessorKey: "firstName",
    Cell: (table) => {
      const name = `${table.row.original.firstName} ${table.row.original?.lastName ?? ""}`;
      return name;
    },
  },
  {
    id: "phoneNumber",
    header: "Phone number",
    accessorKey: "phoneNumber",
    Cell: (table) => {
      const phoneNumber = `+${table.row.original.areaCode} ${table.row.original.phoneNumber}`;
      return phoneNumber;
    },
  },
  {
    id: "email",
    header: "Email",
    accessorKey: "email",
  },
  {
    id: "company",
    header: "Company",
    accessorKey: "company",
  },
  {
    id: "createdAt",
    header: "Created At",
    accessorKey: "createdAt",
    Cell: (table) => {
      const createdAt = table.row.original.createdAt;
      if (!createdAt) return "";
      return dayjs(createdAt).format("MM/DD/YYYY").toString();
    },
  },
  {
    id: "registeredFrom",
    header: "Registered From",
    accessorKey: "registeredFrom",
    enableSorting: false,
  },
];
