import { useState } from "react"
import { HStack, styled } from "#/css/jsx"
import { SearchButton } from "#/components@v2_5/search-button"
import { useParams } from "react-router"
import { VendorExpensesTable } from "./table"

export const VendorExpensesSection = () => {
	const [search, setSearch] = useState<string | null>(null)
	const { vendorId } = useParams()

	return (
		<>
			<HStack justify="space-between" gap={3}>
				<SearchButton setQuery={setSearch} />
			</HStack>
			<styled.div marginTop={15}>
				<VendorExpensesTable vendorId={Number(vendorId)} search={search} />
			</styled.div>
		</>
	)
}
