import logoUrl from "#/images/colored-logo-invoice.png"
import { fetchImageAsBase64 } from "#/util"
import dayjs from "dayjs"
import Decimal from "decimal.js"
import ExcelJS, { Style } from "exceljs"
import { saveAs } from "file-saver"
import type { PaymentType } from "server"

export type SalesReportData = {
	from: Date
	to: Date
	salesActivities: {
		nonTaxable: SalesActivityData
		taxable: SalesActivityData
		tax: SalesActivityData
		subtotal: SalesActivityData
		shipping: SalesActivityData
		insurance: SalesActivityData
		totalActivity: SalesActivityData
	}
	payments: {
		totalPaidIn: Decimal
		totalReturned: Decimal
		totalAmount: Decimal
		methods: Array<{
			method: PaymentType
			totalPaidIn: Decimal
			totalReturned: Decimal
			totalAmount: Decimal
		}>
	}
	inventoryMovement: {
		count: number
		soldItems: number
		returnedItems: number
	}
}

export type SalesActivityData = {
	sales: Decimal
	returns: Decimal
	net: Decimal
}

export async function exportSalesReportXlsx(data: SalesReportData) {
	const wb = new ExcelJS.Workbook()
	const sheet = wb.addWorksheet("Sheet 1", {
		pageSetup: { paperSize: 9 },
	})
	sheet.views = [{ showGridLines: false }]

	sheet.columns = [
		{ key: "A", width: 16 },
		{ key: "B", width: 16 },
		{ key: "C", width: 16 },
		{ key: "D", width: 16 },
		{ key: "E", width: 16 },
		{ key: "F", width: 16 },
		{ key: "G", width: 16 },
	]

	const style = {
		name: "Montserrat",
		size: 10,
	}

	const imgData = await fetchImageAsBase64(logoUrl)
	const logo = wb.addImage({
		base64: imgData.data,
		extension: imgData.ext,
	})

	const styleBackground = (
		aligment: "left" | "right" | "center",
	): Partial<Style> => {
		return {
			fill: {
				type: "pattern",
				bgColor: { argb: "fff2f2f2" },
				fgColor: { argb: "fff2f2f2" },
				pattern: "solid",
			},
			alignment: {
				horizontal: `${aligment}`,
				vertical: "middle",
			},
		}
	}

	const generatePaymentsSummary = (
		title: string,
		rowTitle: number,
		cellsTitle: string,
		data: {
			showOnlyTotalAmount?: boolean
			totalPaidIn: {
				value: Decimal
				cells: string[]
			}
			totalPaidOut: {
				value: Decimal
				cells: string[]
			}
			totalAmount: {
				value: Decimal
				cells: string[]
			}
		},
	) => {
		const showOnlyTotalAmount = data.showOnlyTotalAmount ?? false

		sheet.getRow(rowTitle).height = 39.75
		sheet.mergeCells(`${cellsTitle}`)
		sheet.getCell(`${cellsTitle}`).font = {
			...style,
			size: 12,
			color: { argb: "ffa67744" },
		}
		sheet.getCell(`${cellsTitle}`).alignment = {
			vertical: "middle",
		}
		sheet.getCell(`${cellsTitle}`).value = title

		if (!showOnlyTotalAmount) {
			// Total Paid In
			sheet.mergeCells(`${data.totalPaidIn.cells[0]}`)
			sheet.mergeCells(`${data.totalPaidIn.cells[1]}}`)
			sheet.getCell(`${data.totalPaidIn.cells[0]}`).value = `Total Paid In`
			sheet.getCell(`${data.totalPaidIn.cells[0]}`).style =
				styleBackground("left")
			sheet.getCell(`${data.totalPaidIn.cells[1]}`).style =
				styleBackground("right")
			sheet.getCell(`${data.totalPaidIn.cells[1]}`).value =
				data.totalPaidIn.value?.toNumber().toFixed(2) ?? 0.0

			// Total Paid Out
			sheet.mergeCells(`${data.totalPaidOut.cells[0]}`)
			sheet.mergeCells(`${data.totalPaidOut.cells[1]}}`)
			sheet.getCell(`${data.totalPaidOut.cells[0]}`).value = "Returns"
			sheet.getCell(`${data.totalPaidOut.cells[1]}`).alignment = {
				horizontal: "right",
				vertical: "middle",
			}
			sheet.getCell(`${data.totalPaidOut.cells[1]}`).value =
				data.totalPaidOut.value?.toNumber().toFixed(2) ?? 0.0
		}
		// Total Amount
		sheet.mergeCells(`${data.totalAmount.cells[0]}`)
		sheet.mergeCells(`${data.totalAmount.cells[1]}}`)
		sheet.getCell(`${data.totalAmount.cells[0]}`).value = `Total Amount`
		sheet.getCell(`${data.totalAmount.cells[0]}`).style =
			styleBackground("left")
		sheet.getCell(`${data.totalAmount.cells[1]}`).style =
			styleBackground("right")
		sheet.getCell(`${data.totalAmount.cells[1]}`).value =
			data.totalAmount.value?.toNumber().toFixed(2) ?? 0.0
	}

	// Here starts generating the excel
	// Header
	sheet.mergeCells("A1:G1")
	sheet.getRow(1).height = 6
	sheet.getCell("A1:G1").style = {
		fill: {
			type: "pattern",
			bgColor: { argb: "ffa67744" },
			fgColor: { argb: "ffa67744" },
			pattern: "solid",
		},
	}

	sheet.getRow(3).height = 32.25
	sheet.mergeCells("B3:D3")
	sheet.getCell("B3:D3").font = {
		...style,
		size: 20,
		color: { argb: "ffa67744" },
	}
	sheet.getCell("B3:D3").value = "SALES REPORT"

	sheet.getRow(4).height = 12.75
	sheet.getRow(5).height = 12.75
	sheet.mergeCells("B4:D4")
	sheet.getCell("B4:D4").font = {
		...style,
		color: { argb: "ff707070" },
	}

	sheet.addImage(logo, {
		tl: {
			col: 5,
			row: 2,
		},
		ext: { width: 100, height: 70 },
	})

	sheet.getCell("B4:D4").value = `Date range: ${dayjs(data.from).format(
		"DD/MM/YYYY",
	)} - ${dayjs(data.to).format("DD/MM/YYYY")}`
	sheet.getCell("B5:D5").value = `Created at: ${dayjs().format("DD/MM/YYYY")}`
	sheet.mergeCells("B5:D5")
	sheet.getCell("B5:D5").font = {
		...style,
		color: { argb: "ff707070" },
	}

	sheet.getRow(6).height = 12.75
	sheet.getRow(7).height = 12.75
	sheet.mergeCells("B6:F6")
	sheet.getCell("B6:F6").border = {
		bottom: { style: "thin" },
	}

	let R = 8

	// biome-ignore format: one-liners look better
	{
    // Sales Summary
    const salesSummaryColumns = {
      activity: "B",
      sales: "C",
      returns: "D",
      net: "E",
    };

    const salesSummaryRows = {
      heading: R++,
      nonTaxable: R++,
      taxable: R++,
      tax: R++,
      subtotal: R++,
      shipping: R++,
      insurance: R++,
      totalActivity: R++,
    };

    const c = salesSummaryColumns;
    const r = salesSummaryRows;

    const netNextCell = String.fromCharCode(c.net.charCodeAt(0) + 1);
    sheet.mergeCells(`${c.net}${r.heading}:${netNextCell}${r.heading}`);
    sheet.mergeCells(`${c.net}${r.nonTaxable}:${netNextCell}${r.nonTaxable}`);
    sheet.mergeCells(`${c.net}${r.taxable}:${netNextCell}${r.taxable}`);
    sheet.mergeCells(`${c.net}${r.tax}:${netNextCell}${r.tax}`);
    sheet.mergeCells(`${c.net}${r.subtotal}:${netNextCell}${r.subtotal}`);
    sheet.mergeCells(`${c.net}${r.shipping}:${netNextCell}${r.shipping}`);
    sheet.mergeCells(`${c.net}${r.insurance}:${netNextCell}${r.insurance}`);
    sheet.mergeCells(
      `${c.net}${r.totalActivity}:${netNextCell}${r.totalActivity}`,
    );

    Object.values(c)
      .slice(1)
      .flatMap((col) => Object.values(r).map((row) => `${col}${row}`))
      .forEach((cell) => {
        sheet.getCell(cell).alignment = {
          horizontal: "right",
          vertical: "middle",
        };
      });

    sheet.getCell(`${c.activity}${r.heading}`).value = "Sales activity";
    sheet.getCell(`${c.sales}${r.heading}`).value = "Sales";
    sheet.getCell(`${c.returns}${r.heading}`).value = "Returns";
    sheet.getCell(`${c.net}${r.heading}`).value = "Net";

    sheet.getCell(`${c.activity}${r.nonTaxable}`).value = "Non taxable";
    sheet.getCell(`${c.activity}${r.taxable}`).value = "Taxable";
    sheet.getCell(`${c.activity}${r.tax}`).value = "Tax";
    sheet.getCell(`${c.activity}${r.subtotal}`).value = "Subtotal";
    sheet.getCell(`${c.activity}${r.shipping}`).value = "Shipping";
    sheet.getCell(`${c.activity}${r.insurance}`).value = "Insurance";
    sheet.getCell(`${c.activity}${r.totalActivity}`).value = "Total activity";

    const act = data.salesActivities;

    // Sales
    sheet.getCell(`${c.sales}${r.nonTaxable}`).value =
      act.nonTaxable.sales.toFixed(2);
    sheet.getCell(`${c.sales}${r.taxable}`).value =
      act.taxable.sales.toFixed(2);
    sheet.getCell(`${c.sales}${r.tax}`).value = act.tax.sales.toFixed(2);
    sheet.getCell(`${c.sales}${r.subtotal}`).value =
      act.subtotal.sales.toFixed(2);
    sheet.getCell(`${c.sales}${r.shipping}`).value =
      act.shipping.sales.toFixed(2);
    sheet.getCell(`${c.sales}${r.insurance}`).value =
      act.insurance.sales.toFixed(2);
    sheet.getCell(`${c.sales}${r.totalActivity}`).value =
      act.totalActivity.sales.toFixed(2);

    // Returns
    sheet.getCell(`${c.returns}${r.nonTaxable}`).value =
      act.nonTaxable.returns.toFixed(2);
    sheet.getCell(`${c.returns}${r.taxable}`).value =
      act.taxable.returns.toFixed(2);
    sheet.getCell(`${c.returns}${r.tax}`).value = act.tax.returns.toFixed(2);
    sheet.getCell(`${c.returns}${r.subtotal}`).value =
      act.subtotal.returns.toFixed(2);
    sheet.getCell(`${c.returns}${r.totalActivity}`).value =
      act.totalActivity.returns.toFixed(2);

    // Net
    sheet.getCell(`${c.net}${r.nonTaxable}`).value = act.nonTaxable.net.toFixed(2);
    sheet.getCell(`${c.net}${r.taxable}`).value = act.taxable.net.toFixed(2);
    sheet.getCell(`${c.net}${r.tax}`).value = act.tax.net.toFixed(2);
    sheet.getCell(`${c.net}${r.subtotal}`).value = act.subtotal.net.toFixed(2);
    sheet.getCell(`${c.net}${r.shipping}`).value = act.shipping.net.toFixed(2);
    sheet.getCell(`${c.net}${r.insurance}`).value = act.insurance.net.toFixed(2);
    sheet.getCell(`${c.net}${r.totalActivity}`).value =
      act.totalActivity.net.toFixed(2);
  }

	// Payments
	sheet.getRow(++R).height = 51.75
	sheet.mergeCells(`B${R}:F${R}`)
	sheet.getCell(`B${R}:F${R}`).font = {
		...style,
		size: 14,
		bold: true,
		color: { argb: "ffa67744" },
	}
	sheet.getCell(`B${R}:F${R}`).alignment = {
		vertical: "middle",
	}
	sheet.getCell(`B${R}:F${R}`).value = "Payments"

	sheet.mergeCells(`B${++R}:C${R}`)
	sheet.mergeCells(`D${R}:F${R}`)
	sheet.getCell(`B${R}:C${R}`).style = styleBackground("left")
	sheet.getCell(`D${R}:F${R}`).style = styleBackground("right")
	sheet.getCell(`B${R}:C${R}`).value = "Total Paid in"
	sheet.getCell(`D${R}:F${R}`).value = data.payments.totalPaidIn
		.toNumber()
		.toFixed(2)

	sheet.mergeCells(`B${++R}:C${R}`)
	sheet.mergeCells(`D${R}:F${R}`)
	sheet.getCell(`D${R}:F${R}`).alignment = {
		horizontal: "right",
		vertical: "middle",
	}
	sheet.getCell(`B${R}:C${R}`).value = "Returns"
	sheet.getCell(`D${R}:F${R}`).value = data.payments.totalReturned
		.toNumber()
		.toFixed(2)

	sheet.mergeCells(`B${++R}:C${R}`)
	sheet.mergeCells(`D${R}:F${R}`)
	sheet.getCell(`B${R}:C${R}`).style = styleBackground("left")
	sheet.getCell(`D${R}:F${R}`).style = styleBackground("right")
	sheet.getCell(`B${R}:C${R}`).value = "Total Paid"
	sheet.getCell(`D${R}:F${R}`).value = data.payments.totalAmount
		.toNumber()
		.toFixed(2)

	// payments cash
	const cash = data.payments.methods
		.filter((order) => order.method === "CASH")
		?.reduce(
			(acc, order) => ({
				totalPaidIn: acc.totalPaidIn.plus(order.totalPaidIn),
				totalPaidOut: acc.totalPaidIn.plus(order.totalReturned),
				totalAmount: acc.totalAmount.plus(order.totalAmount),
			}),
			{
				totalPaidIn: new Decimal(0),
				totalPaidOut: new Decimal(0),
				totalAmount: new Decimal(0),
			},
		)

	generatePaymentsSummary("CASH", ++R, `B${R}:F${R}`, {
		totalPaidIn: {
			value: cash?.totalPaidIn ?? 0.0,
			cells: [`B${++R}:C${R}`, `D${R}:F${R}`],
		},
		totalPaidOut: {
			value: cash?.totalPaidOut ?? 0.0,
			cells: [`B${++R}:C${R}`, `D${R}:F${R}`],
		},
		totalAmount: {
			value: cash?.totalAmount ?? 0.0,
			cells: [`B${++R}:C${R}`, `D${R}:F${R}`],
		},
	})

	const cardPaymentMethods: Array<PaymentType> = [
		"AMERICAN_EXPRESS",
		"VISA",
		"DISCOVER",
		"MASTERCARD",
	]
	// payments all credit/debit cards
	const allCards = data.payments.methods
		.filter((method) => cardPaymentMethods.includes(method.method))
		?.reduce(
			(acc, order) => ({
				totalPaidIn: acc.totalPaidIn.plus(order.totalPaidIn),
				totalPaidOut: acc.totalPaidOut.plus(order.totalReturned),
				totalAmount: acc.totalAmount.plus(order.totalAmount),
			}),
			{
				totalPaidIn: new Decimal(0),
				totalPaidOut: new Decimal(0),
				totalAmount: new Decimal(0),
			},
		)
	generatePaymentsSummary("All Credit/Debit cards", ++R, `B${R}:F${R}`, {
		totalPaidIn: {
			value: allCards?.totalPaidIn ?? 0.0,
			cells: [`B${++R}:C${R}`, `D${R}:F${R}`],
		},
		totalPaidOut: {
			value: allCards?.totalPaidOut ?? 0.0,
			cells: [`B${++R}:C${R}`, `D${R}:F${R}`],
		},
		totalAmount: {
			value: allCards?.totalAmount ?? 0.0,
			cells: [`B${++R}:C${R}`, `D${R}:F${R}`],
		},
	})

	// payments summary by each card type
	// eslint-disable-next-line prefer-const
	let lastRow = R
	data.payments.methods
		.filter(
			(order) => !cardPaymentMethods.concat(["CASH"]).includes(order.method),
		)
		.map((p) => p.method)
		.filter((v, i, a) => a.indexOf(v) === i)
		.forEach((method) => {
			generatePaymentsSummary(
				method,
				lastRow + 1,
				`B${lastRow + 1}:F${lastRow + 1}`,
				{
					showOnlyTotalAmount: method === "USER_CREDITS",
					totalPaidIn: {
						value: data.payments.methods
							.filter((order) => order.method === method)
							.reduce(
								(acc, order) => acc.plus(order.totalPaidIn),
								new Decimal(0),
							),
						cells:
							method !== "USER_CREDITS"
								? [
										`B${lastRow + 2}:C${lastRow + 2}`,
										`D${lastRow + 2}:F${lastRow + 2}`,
									]
								: [],
					},
					totalPaidOut: {
						value: data.payments.methods
							.filter((order) => order.method === method)
							.reduce(
								(acc, order) => acc.plus(order.totalReturned),
								new Decimal(0),
							),
						cells:
							method !== "USER_CREDITS"
								? [
										`B${lastRow + 3}:C${lastRow + 3}`,
										`D${lastRow + 3}:F${lastRow + 3}`,
									]
								: [],
					},
					totalAmount: {
						value: data.payments.methods
							.filter((order) => order.method === method)
							.reduce(
								(acc, order) => acc.plus(order.totalAmount),
								new Decimal(0),
							),
						cells:
							method !== "USER_CREDITS"
								? [
										`B${lastRow + 4}:C${lastRow + 4}`,
										`D${lastRow + 4}:F${lastRow + 4}`,
									]
								: [
										`B${lastRow + 2}:C${lastRow + 2}`,
										`D${lastRow + 2}:F${lastRow + 2}`,
									],
					},
				},
			)
			if (method !== "USER_CREDITS") {
				lastRow = lastRow + 4
			} else {
				lastRow = lastRow + 2
			}
		})

	// Inventory movement
	sheet.getRow(lastRow + 1).height = 51.75
	sheet.mergeCells(`B${lastRow + 1}:F${lastRow + 1}`)
	sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).font = {
		...style,
		size: 14,
		bold: true,
		color: { argb: "ffa67744" },
	}
	sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).alignment = {
		vertical: "middle",
	}
	sheet.getCell(`B${lastRow + 1}:F${lastRow + 1}`).value = "Inventory Movement"

	sheet.mergeCells(`B${lastRow + 2}:C${lastRow + 2}`)
	sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).style =
		styleBackground("center")
	sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).font = {
		bold: true,
		color: { argb: "ff707070" },
	}
	sheet.getCell(`B${lastRow + 2}:C${lastRow + 2}`).value = "Count"
	sheet.getCell(`D${lastRow + 2}`).style = styleBackground("center")
	sheet.getCell(`D${lastRow + 2}`).font = {
		bold: true,
		color: { argb: "ff707070" },
	}
	sheet.getCell(`D${lastRow + 2}`).value = "Sold Items"
	sheet.mergeCells(`E${lastRow + 2}:F${lastRow + 2}`)
	sheet.getCell(`E${lastRow + 2}:F${lastRow + 2}`).style =
		styleBackground("center")
	sheet.getCell(`E${lastRow + 2}:F${lastRow + 2}`).font = {
		bold: true,
		color: { argb: "ff707070" },
	}
	sheet.getCell(`E${lastRow + 2}:F${lastRow + 2}`).value = "Returned Items"

	sheet.mergeCells(`B${lastRow + 3}:C${lastRow + 3}`)
	sheet.getCell(`B${lastRow + 3}:C${lastRow + 3}`).alignment = {
		horizontal: "center",
		vertical: "middle",
	}
	sheet.getCell(`B${lastRow + 3}:C${lastRow + 3}`).value =
		data.inventoryMovement.count
	sheet.getCell(`D${lastRow + 3}`).alignment = {
		horizontal: "center",
		vertical: "middle",
	}
	sheet.getCell(`D${lastRow + 3}`).value = data.inventoryMovement.soldItems
	sheet.mergeCells(`E${lastRow + 3}:F${lastRow + 3}`)
	sheet.getCell(`E${lastRow + 3}:F${lastRow + 3}`).alignment = {
		horizontal: "center",
		vertical: "middle",
	}
	sheet.getCell(`E${lastRow + 3}:F${lastRow + 3}`).value =
		data.inventoryMovement.returnedItems

	lastRow = lastRow + 3

	const xlsx = await wb.xlsx.writeBuffer({
		// @ts-ignore
		base64: true,
	})
	saveAs(
		new Blob([xlsx], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		}),
		`salesReport_${dayjs(data.from).format("YYYY-MM-DD")} - ${dayjs(
			data.to,
		).format("YYYY-MM-DD")}.xlsx`,
	)
}
