import { css } from "#/css/css"
import { styled } from "#/css/jsx"
import dayjs from "dayjs"
import { PieChartCard } from "../common"
import { useState } from "react"
import { DatePickerInput } from "@mantine/dates"
import CalendarIcon from "~icons/ion/calendar-clear-outline"
import { type RouterOutputs, trpc } from "#/trpc"
import GTLoader from "#/components-ng/loader"
import { LoadingOverlay } from "@mantine/core"
import { generateCsv } from "#/util/csv"
import FileSaver from "file-saver"
import { Button } from "@gt/ui"
import Download from "~icons/ion/download-outline"

type Orders =
	RouterOutputs["v2_5"]["report"]["notificationUpsellReport"]["ordersWithFirstNotification"]["orders"]

export const NotificationUpsellReport = () => {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().startOf("month").toDate(),
		dayjs().endOf("month").toDate(),
	])

	const { data, isLoading } =
		trpc.v2_5.report.notificationUpsellReport.useQuery(
			{
				startDate: dateRange[0]!,
				endDate: dateRange[1]!,
			},
			{
				enabled: dateRange[0] && dateRange[1] ? true : false,
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		)

	const downloadCsv = async (orders: Orders, pathName: string) => {
		{
			const headers = [
				{ label: "Receipt number", value: "receiptNumber" },
				{ label: "Name", value: "customer.firstName" },
				{ label: "Last Name", value: "customer.lastName" },
				{ label: "Email", value: "customer.email" },
				{ label: "Phone Number", value: "customer.phoneNumber" },
				{ label: "Total", value: "total" },
				{ label: "Created At", value: "createdAt" },
			]
			const csv = await generateCsv({
				headers,
				data: orders,
			})
			FileSaver.saveAs(
				new Blob([csv], { type: "text/csv" }),
				`${pathName}${dayjs(dateRange[0]).format("MM-DD-YYYY")}__${dayjs(dateRange[1]).format("MM-DD-YYYY") ?? ""}.csv`,
			)
		}
	}

	return (
		<>
			<styled.div
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				flexDirection={{
					base: "column",
					md: "row",
				}}
			>
				<styled.h2 my="1rem">Notification upsell</styled.h2>
				<DatePickerInput
					name="dateRange"
					type="range"
					onChange={(v) => {
						if (v[0] == null || v[1] == null) return
						setDateRange([v[0], v[1]])
					}}
					numberOfColumns={2}
					popoverProps={{
						zIndex: 9000,
					}}
					w={350}
					required
					icon={<CalendarIcon />}
					defaultValue={dateRange}
				/>
			</styled.div>

			<styled.hr mt="2" mb="2rem" />

			{isLoading ? (
				<LoadingOverlay
					visible={isLoading}
					loader={<GTLoader width={100} height={100} />}
				/>
			) : (
				<styled.div
					display="flex"
					justifyContent="space-between"
					gap="2rem"
					flexDirection={{
						base: "column",
						md: "row",
					}}
				>
					<PieChartCard
						title="Users who purchased after receiving the first email"
						isMoney={false}
						total={data?.totalOrders ?? 0}
						text={
							data?.ordersWithFirstNotification?.total
								? `${((data?.ordersWithFirstNotification?.total / data?.totalOrders) * 100).toFixed(2)}%`
								: "0%"
						}
						data={[
							{
								title: "1st email",
								value: data?.ordersWithFirstNotification?.total ?? 0,
								color: "#4DBAFF",
							},
						]}
						includeLabel={false}
					>
						<Button
							className={css({
								gap: 4,
								mb: 4,
								width: "full",
							})}
							onClick={() =>
								downloadCsv(
									data?.ordersWithFirstNotification?.orders ?? [],
									"users_who_purchased_orders_after_receiving_the_first_email_",
								)
							}
						>
							<Download />
							<styled.p fontSize="sm" color="white">
								Download users
							</styled.p>
						</Button>
					</PieChartCard>
					<PieChartCard
						title="Users who purchased after receiving the second email"
						isMoney={false}
						total={data?.totalOrders ?? 0}
						text={
							data?.ordersWithSecondNotification?.total
								? `${((data?.ordersWithSecondNotification?.total / data?.totalOrders) * 100).toFixed(2)}%`
								: "0%"
						}
						data={[
							{
								title: "2st email",
								value: data?.ordersWithSecondNotification?.total ?? 0,
								color: "#60C69B",
							},
						]}
						includeLabel={false}
					>
						<Button
							className={css({
								gap: 4,
								mb: 4,
								width: "full",
							})}
							onClick={() =>
								downloadCsv(
									data?.ordersWithSecondNotification?.orders ?? [],
									"users_who_purchased_orders_after_receiving_the_second_email_",
								)
							}
						>
							<Download />
							<styled.p fontSize="sm" color="white">
								Download users
							</styled.p>
						</Button>
					</PieChartCard>
					<PieChartCard
						title="Users who purchased after being contacted by phone"
						isMoney={false}
						total={data?.totalOrders ?? 0}
						text={
							data?.ordersWithThirdNotification?.total
								? `${((data?.ordersWithThirdNotification?.total / data?.totalOrders) * 100).toFixed(2)}%`
								: "0%"
						}
						data={[
							{
								title: "called",
								value: data?.ordersWithThirdNotification?.total ?? 0,
								color: "#60C69B",
							},
						]}
						includeLabel={false}
					>
						<Button
							className={css({
								gap: 4,
								mb: 4,
								width: "full",
							})}
							onClick={() =>
								downloadCsv(
									data?.ordersWithThirdNotification?.orders ?? [],
									"users_who_purchased_orders_after_being_contacted_by_phone_",
								)
							}
						>
							<Download />
							<styled.p fontSize="sm" color="white">
								Download users
							</styled.p>
						</Button>
					</PieChartCard>
				</styled.div>
			)}
		</>
	)
}
