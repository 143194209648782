import { type RouterOutputs, trpc } from "#/trpc"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	type MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table"
import { reportUserError, reportUserSuccess } from "#/util"
import { Checkbox } from "@radix-ui/themes"
import { Button, Dropdown } from "@gt/ui"
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline"
import dayjs from "dayjs"
import type { FilterKey } from "./$common"
import {
	CalledFormDrawer,
	type UpdateCalledCartValues,
} from "./called-form-drawer"
import { Link } from "react-router-dom"
import { css } from "#/css/css"

export interface CartsNotificationsTableProps {
	filter: Array<FilterKey>
	search?: string | null
}

export const CartsNotificationsTable = (
	props: CartsNotificationsTableProps,
) => {
	// Pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})
	// Sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])

	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } = trpc.v2_5.cart.getCartsWithNotifications.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			search: props.search,
			filters: {
				filter: props.filter,
			},
		},
		{
			keepPreviousData: true,
			onError(error) {
				reportUserError({
					title: "Failed to get carts",
					message: error.message,
				})
			},
		},
	)

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
	})

	return <MantineReactTable table={table} />
}

type CartNotification =
	RouterOutputs["v2_5"]["cart"]["getCartsWithNotifications"]["entries"][number]

const columns: MRT_ColumnDef<CartNotification>[] = [
	{
		id: "actions",
		header: "Actions",
		enableSorting: false,
		enableColumnFilter: false,
		size: 100,
		Cell: (table) => {
			const [openDrawer, setOpenDrawer] = useState<boolean>(false)

			const ctx = trpc.useContext()
			const { mutate, isLoading } =
				trpc.v2_5.cart.updateCalledNotification.useMutation({
					onSuccess() {
						ctx.v2_5.cart.invalidate()
						reportUserSuccess({
							title: "Called notification updated",
						})
						setOpenDrawer(false)
					},
				})

			const handleSubmit = (values: UpdateCalledCartValues) => {
				if (!values.calledAt) {
					reportUserError({
						title: "Failed to update",
						message: "Called at is required",
					})
					return
				}

				mutate({
					cartId: Number(table.row.original.cartId),
					calledAt: values.calledAt,
					calledByUserId: Number(values.calledByUserId),
				})
			}

			return (
				<>
					<Dropdown.Root>
						<Dropdown.Trigger>
							<Button variant="ghost" size="icon">
								<MenuIcon />
							</Button>
						</Dropdown.Trigger>
						<Dropdown.Content>
							<Dropdown.Item
								onClick={() => {
									setOpenDrawer(true)
								}}
								disabled={
									table.row.original?.orderReceiptNumber ||
									table.row.original?.calledByUserId
										? true
										: false
								}
							>
								Mark As Called
							</Dropdown.Item>
						</Dropdown.Content>
					</Dropdown.Root>
					<CalledFormDrawer
						opened={openDrawer}
						onClose={() => setOpenDrawer(false)}
						isLoading={isLoading}
						onSubmit={handleSubmit}
						defaultValues={{
							calledAt: table.row.original.calledAt,
							calledByUserId: table.row.original?.calledByUserId
								? table.row.original.calledByUserId?.toString()
								: "",
						}}
					/>
				</>
			)
		},
	},
	{
		id: "customerName",
		header: "Customer Name",
		accessorKey: "customerName",
		enableSorting: false,
		enableColumnFilter: false,
	},
	{
		id: "phoneNumber",
		header: "Phone number",
		accessorKey: "phoneNumber",
		enableSorting: false,
		enableColumnFilter: false,
	},
	{
		id: "email",
		header: "Email",
		accessorKey: "email",
		enableSorting: false,
		enableColumnFilter: false,
	},
	{
		id: "cartId",
		header: "Cart",
		accessorKey: "cartId",
		enableSorting: false,
		enableColumnFilter: false,
		size: 100,
		Cell: (table) => {
			return table.row.original.cartStatus === "OPEN" ? (
				<Link
					target="_blank"
					to={`/sales/pending-carts/edit-ecom/${table.row.original.cartUuid}`}
					className={css({
						textDecoration: "underline",
						color: "blue",
					})}
				>
					{table.row.original.cartId}
				</Link>
			) : (
				<p>{table.row.original.cartId}</p>
			)
		},
	},
	{
		id: "orderReceiptNumber",
		header: "Order",
		accessorKey: "orderReceiptNumber",
		size: 100,
		Cell: (table) => {
			if (!table.row.original.orderReceiptNumber) return ""
			return (
				<Link
					target="_blank"
					to={`/sales/ecommerce-orders/${table.row.original.orderId}`}
					className={css({
						textDecoration: "underline",
						color: "blue",
					})}
				>
					{table.row.original.orderReceiptNumber}
				</Link>
			)
		},
	},
	{
		id: "firstEmail",
		header: "1st Email",
		accessorKey: "firstEmail",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (props) => {
			return <Checkbox checked={props.row.original.firstEmail} />
		},
		size: 100,
	},
	{
		id: "secondEmail",
		header: "2nd Email",
		accessorKey: "secondEmail",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (props) => {
			return <Checkbox checked={props.row.original.secondEmail} />
		},
		size: 100,
	},
	{
		id: "called",
		header: "Called",
		accessorKey: "called",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (props) => {
			return <Checkbox checked={props.row.original.called} />
		},
		size: 100,
	},
	{
		id: "calledBy",
		header: "Called by",
		accessorKey: "calledBy",
		enableSorting: false,
		enableColumnFilter: false,
	},
	{
		id: "calledAt",
		header: "Called at",
		accessorKey: "calledAt",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (table) => {
			const calledAt = table.row.original.calledAt
			if (!calledAt) return ""
			return dayjs(calledAt).format("MM/DD/YYYY HH:MM").toString()
		},
	},
]
