import { css } from "#/css/css"
import { HStack } from "#/css/jsx"
import { styled as s } from "#/css/jsx"
import InstagramIcon from "~icons/ion/logo-instagram"
import WhatsappIcon from "~icons/ion/social-whatsapp-outline"
import { LogoWithWebsiteUrl } from "./logo-with-website.js"
import { Page } from "./page.js"

export function ContactPage() {
	return (
		<Page>
			<s.p px="2.5cm" pt="2.5cm" c="var(--text-color)">
				<LogoWithWebsiteUrl />
				<s.p fs="1.75cm" fw="medium" mt="3cm">
					Contacto
				</s.p>
				<HStack>
					<WhatsappIcon
						className={css({
							color: "green.500",
						})}
					/>
					<s.span>+1 786 678 0954</s.span>
				</HStack>
				<HStack>
					<InstagramIcon
						className={css({
							color: "#d050b8",
						})}
					/>
					<s.span>@goldtreemiami</s.span>
				</HStack>
				<s.p fw="bold" mt="3">
					Plaza 33, 755 NW 72nd Ave, Miami, FL 33126
				</s.p>
				<s.p>www.goldtreemiami.com</s.p>
			</s.p>
		</Page>
	)
}
