import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

// Schemas to create catalog
export const types = ["BY_CATALOG_IMAGE", "BY_ITEM"] as const;

export const createCatalogSchema = z.object({
  title: z.string({
    required_error: "Title is required",
  }),
  titleEsp: z.string().nullish(),
  description: z.string({
    required_error: "Description is required",
  }),
  descriptionEsp: z.string().nullish(),
  type: z.enum(types).default("BY_CATALOG_IMAGE"),
  defaultImage: z.string().nullish(),
});

export type FormValues = z.infer<typeof createCatalogSchema>;
export const C = makeController<FormValues>();

// schemas to create catalog images
export const createCatalogImageSchema = z.object({
  image: z
    .object({
      uid: z.string(),
      url: z.string(),
      isLoading: z.boolean(),
      tags: z.array(
        z.object({
          // Title shown in the tag
          title: z.string(),
          // URL to redirect to on click
          url: z.string(),
          // X position as a fraction [0, 1]
          x: z.number(),
          // Y position as a fraction [0, 1] (starts at the top)
          y: z.number(),
          itemSkuId: z.number().nullish(),
          sku: z.number().nullish(),
        }),
      ),
    })
    .nullish(),
});

export type CatalogImageFormValues = z.infer<typeof createCatalogImageSchema>;
// CI - Catalog Image
export const CI = makeController<CatalogImageFormValues>();

// schemas to create catalog itemskus
export const createCatalogItemSkuSchema = z.object({
  itemSkus: z.array(
    z.object({
      id: z.number(),
      sku: z.number(),
      title: z.string(),
      defaultImage: z.string().nullish(),
    }),
  ),
});

export type CatalogItemSkuFormValues = z.infer<
  typeof createCatalogItemSkuSchema
>;
export const CIS = makeController<CatalogItemSkuFormValues>();
