export type CartStatus = "OPEN" | "HOLD" | "CLOSED" | "CANCELLED" | "ECOMMERCE";

export const Filter: Record<CartStatus, string> = {
  OPEN: "Open",
  HOLD: "On hold",
  CLOSED: "Closed",
  CANCELLED: "Cancelled",
  ECOMMERCE: "E-commerce",
} as const;

export type FilterKey = keyof typeof Filter;
export type Filter = (typeof Filter)[FilterKey];

export type CustomerFilterType = "isGuest" | "withCustomer"

export const CustomerFilter: Record<CustomerFilterType, string> = {
  isGuest: "Guest",
  withCustomer: "With customer",
} as const

export type CustomerFilterKey = keyof typeof CustomerFilter
export type CustomerFilter = (typeof CustomerFilter)[CustomerFilterKey]
