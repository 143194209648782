import { gTrpc, RouterOutputs, trpc } from "./trpc"

export type AllFeatureFlagsByUser =
	RouterOutputs["v2_5"]["featureFlags"]["getAllByUser"]

export function useFeatureFlag(flagName: string) {
	const { data } = trpc.v2_5.featureFlags.getAllByUser.useQuery()

	if (data == null) {
		return null
	}

	return extractFeatureFlag(flagName, data)
}

export async function getFeatureFlag(flagName: string) {
	const data = await gTrpc.v2_5.featureFlags.getAllByUser.query()

	if (data == null) {
		return null
	}

	return extractFeatureFlag(flagName, data)
}

function extractFeatureFlag(
	flagName: string,
	allFeatureFlagsByUser: AllFeatureFlagsByUser,
) {
	const featureFlag = allFeatureFlagsByUser.featureFlags.find(
		(flag) => flag.name === flagName,
	)

	if (featureFlag == null) {
		return null
	}

	const found = allFeatureFlagsByUser.featureFlagsOnUsers.find(
		(f) => f.featureId === featureFlag.id,
	)
	const status = found ? ("active" as const) : ("inactive" as const)

	return {
		...featureFlag,
		status,
	}
}
