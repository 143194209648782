import GTLoader from "#/components-ng/loader"
import { css } from "#/css/css"
import { Flex, styled } from "#/css/jsx"
import { type RouterOutputs, trpc } from "#/trpc"
import { LoadingOverlay } from "@mantine/core"
import { Badge } from "@radix-ui/themes"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table"
import { useParams } from "react-router"
import { match } from "ts-pattern"
import placeholderImage from "#/placeholder-image.jpg"
import { VendorDocumentsSectionButton } from "./vendor-documents"
import { Link } from "react-router-dom"
import { Button } from "@gt/ui"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "#/components-ng"
import Decimal from "decimal.js"

type PurchaseOrder =
	RouterOutputs["v2_5"]["purchaseOrder"]["getPurchaseOrderByVendor"]

export const PurchaseOrderByVendor = () => {
	const { vendorId, poId } = useParams()

	const { data, isLoading, isFetchedAfterMount } =
		trpc.v2_5.purchaseOrder.getPurchaseOrderByVendor.useQuery(
			{
				purchaseOrderId: Number(poId),
				vendorId: Number(vendorId),
			},
			{
				refetchOnWindowFocus: false,
			},
		)

	const itemSkus = useMemo(
		() => data?.purchaseOrderItemSku ?? [],
		[data?.purchaseOrderItemSku],
	)

	return isLoading || !isFetchedAfterMount ? (
		<LoadingOverlay
			visible={isLoading || isFetchedAfterMount}
			loader={<GTLoader width={100} height={100} />}
		/>
	) : (
		<>
			<Header purchaseOrder={data} />
			<Table purchaseOrderItemSkus={itemSkus} />
			<CostBreakdown purchaseOrder={data} />
		</>
	)
}

interface HeaderProps {
	purchaseOrder?: PurchaseOrder | null
}

const Header = (props: HeaderProps) => {
	const badgeColor = match(props.purchaseOrder?.status)
		.with("UPCOMING", () => "red")
		.with("PARTIALLY_RECEIVED", () => "orange")
		.with("RECEIVED", () => "green")
		.otherwise(() => "gray")

	return (
		<styled.div
			className={css({
				width: "100%",
				backgroundColor: "white",
				padding: "40px",
				borderRadius: "lg",
				marginBottom: "10px",
			})}
		>
			<Flex align="center" justify="space-between">
				<styled.div display="flex" gap="1rem">
					<styled.p color="black" fontSize="16px" fontWeight="bold">
						Purchase order: #{props.purchaseOrder?.id}
					</styled.p>
					<styled.p color="black" fontSize="16px" fontWeight="bold">
						|
					</styled.p>
					<styled.p color="black" fontSize="16px" fontWeight={500}>
						Status:{" "}
						<Badge size="2" radius="full" color={badgeColor as any}>
							{props.purchaseOrder?.status}
						</Badge>
					</styled.p>
				</styled.div>
				<styled.div display="flex" gap="1rem">
					<VendorDocumentsSectionButton
						documents={
							props.purchaseOrder?.purchaseOrderDocuments?.map((d) => ({
								id: d.vendorDocument.id,
								title: d.vendorDocument.title,
								fileUrl: d.vendorDocument.fileUrl,
								type: d.vendorDocument.type,
								totalPaid: d.vendorDocument?.totalPaid?.toNumber() ?? null,
							})) ?? []
						}
					/>
					<Link
						to={`/inventory/upcoming-items/receive/${props.purchaseOrder?.id}`}
					>
						<Button variant="secondary">See PO</Button>
					</Link>
				</styled.div>
			</Flex>
		</styled.div>
	)
}

type PurchaseOrderItemSku = PurchaseOrder["purchaseOrderItemSku"][number]

interface TableProps {
	purchaseOrderItemSkus: PurchaseOrderItemSku[]
}

const Table = (props: TableProps) => {
	const table = useMantineReactTable({
		columns,
		data: props.purchaseOrderItemSkus ?? [],
		initialState: {
			showGlobalFilter: true,
		},
	})

	return <MantineReactTable table={table} />
}

const columns: MRT_ColumnDef<PurchaseOrderItemSku>[] = [
	{
		id: "image",
		header: "Image",
		size: 100,
		Cell: (table) => {
			const imageUrl =
				table.row.original.itemSku.defaultImage ?? placeholderImage
			return (
				<styled.img
					src={imageUrl}
					onError={(e) =>
						((e.target as HTMLImageElement).src = placeholderImage)
					}
					width={50}
					height={50}
				/>
			)
		},
	},
	{
		id: "itemSku.sku",
		header: "SKU",
		accessorKey: "itemSku.sku",
		size: 100,
	},
	{
		id: "itemSku.cost",
		header: "Cost",
		size: 100,
		accessorKey: "itemSku.cost",
		Cell: (table) => {
			return `$${table.row.original.itemSku.cost?.toFixed(2)}`
		},
	},
	{
		header: "PKG",
		size: 100,
		Cell: (table) => {
			const itemSku = table.row.original.itemSku
			return `${itemSku?.presentationValue ?? "-"} ${itemSku?.presentationType ?? "-"}`
		},
	},
	{
		id: "expectedQuantity",
		header: "Expected Qty",
		accessorKey: "expectedQuantity",
		size: 100,
	},
	{
		id: "receivedTotalQty",
		header: "Received Qty",
		size: 100,
		Cell: (table) => {
			const receivedQty =
				table.row.original.receivedPurchaseOrderItemSku?.reduce(
					(acc, received) => acc + (received?.receivedQuantity ?? 0),
					0,
				)

			return receivedQty
		},
	},
	{
		id: "shortfallQty",
		header: "Shortfall qty",
		size: 100,
		Cell: (table) => {
			const expectedQty = table.row.original.expectedQuantity
			const receivedQty =
				table.row.original.receivedPurchaseOrderItemSku?.reduce(
					(acc, received) => acc + (received?.receivedQuantity ?? 0),
					0,
				)

			return expectedQty - receivedQty
		},
	},
	{
		id: "total",
		header: "Total",
		Cell: (table) => {
			const cost = table.row.original.itemSku.cost ?? new Decimal(0)
			const expectedQuantity = table.row.original.expectedQuantity
			return `$${cost.times(expectedQuantity)?.toFixed(2)}`
		},
	},
]

interface CostBreakdownProps {
	purchaseOrder?: PurchaseOrder | null
}

const CostBreakdown = (props: CostBreakdownProps) => {
	const subTotal = props?.purchaseOrder?.purchaseOrderItemSku?.reduce(
		(acc, item) =>
			acc.plus((item?.cost ?? new Decimal(0)).times(item.expectedQuantity)),
		new Decimal(0),
	)

	const totalDocumentsPaid = props?.purchaseOrder?.purchaseOrderDocuments
		?.filter((d) => d.vendorDocument.type === "PAYMENT_VOUCHER")
		.reduce(
			(acc, d) => acc.plus(d.vendorDocument.totalPaid ?? 0),
			new Decimal(0),
		)

	const total = subTotal
		?.plus(props?.purchaseOrder?.dutyCost ?? 0)
		?.minus(totalDocumentsPaid ?? 0)

	return (
		<styled.div display="flex" marginTop="1rem" justifyContent="right">
			<Accordion type="multiple">
				<AccordionItem value="total" className="border-none">
					<AccordionTrigger
						className={css({
							minWidth: "37ch",
							borderRadius: "md",
							backgroundColor: "green.400",
							paddingX: "1rem",
							paddingY: "0.5rem !important",
							fontSize: "1rem",
							lineHeight: "1.5rem",
							fontWeight: "600 !important",
							color: "white",
							_hover: {
								textDecorationLine: "none !important",
								backgroundColor: "green.500",
							},
						})}
					>
						<styled.div
							marginRight="0.5rem"
							display="grid"
							flex="1 1 0%"
							gridTemplateColumns="1fr 1fr"
						>
							<styled.p textAlign="left">Total</styled.p>
							<styled.p textAlign="right">${total?.toFixed(2)}</styled.p>
						</styled.div>
					</AccordionTrigger>
					<AccordionContent className={css({ paddingX: "1rem" })}>
						<styled.div
							display="grid"
							gridTemplateColumns="1fr 1fr"
							className="[&>*]:my-1"
							gridRowGap="0.3rem"
							marginTop="0.7rem"
						>
							<styled.p color="gray.500" fontSize="16px">
								Subtotal
							</styled.p>
							<styled.p
								textAlign="right"
								color="gray.500"
								fontSize="16px"
								fontWeight={700}
							>
								${subTotal?.toFixed(2)}
							</styled.p>
							<styled.p color="gray.500" fontSize="16px">
								Duty cost
							</styled.p>
							<styled.p
								textAlign="right"
								color="gray.500"
								fontSize="16px"
								fontWeight={700}
							>
								${props?.purchaseOrder?.dutyCost?.toFixed(2) ?? "0.00"}
							</styled.p>
							<styled.p color="gray.500" fontSize="16px">
								Total voucher paid
							</styled.p>
							<styled.p
								textAlign="right"
								color="red"
								fontSize="16px"
								fontWeight={700}
							>
								-${totalDocumentsPaid?.toFixed(2)}
							</styled.p>
						</styled.div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</styled.div>
	)
}
