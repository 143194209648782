import { modals } from "@mantine/modals"
import { css } from "#/css/css"
import { styled } from "#/css/jsx"
import { useState, useMemo } from "react"
import { trpc } from "#/trpc"
import { NumberInput, Select } from "@mantine/core"
import { Button } from "@gt/ui"

import { reportUserError } from "#/util"

export const openCreateTransferInventoryWithItemsOutOfStockModal = () => {
	modals.open({
		centered: true,
		size: "lg",
		modalId: "create-transfer-inventory-with-items-out-of-stock-modal",
		title: <h4>Inventory check</h4>,
		children: <CreateTransferInventoryWithItemsOutOfStockModalChildren />,
	})
}

const CreateTransferInventoryWithItemsOutOfStockModalChildren = () => {
	const [filialFromId, setFilialFromId] = useState<number | null>(null)
	const [filialToId, setFilialToId] = useState<number | null>(null)
	const [minStock, setMinStock] = useState<number>(5)

	const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})

	const { mutate: createInventoryTransfer, isLoading } =
		trpc.v2_5.transferInventory.createTransferInventoryWithOutOfStocks.useMutation(
			{
				onSuccess(data) {
					setFilialFromId(null)
					setFilialToId(null)
					modals.close(
						"create-transfer-inventory-with-items-out-of-stock-modal",
					)
					window.location.href = `/inventory/inventory-transfer/edit/${data.id}`
				},
			},
		)

	const filialsFrom = useMemo(
		() =>
			filials
				?.filter((f) => f.id !== filialToId)
				?.map((f) => ({
					label: f.name,
					value: f.id.toString(),
				})) ?? [],
		[filialToId, filials],
	)

	const filialsTo = useMemo(
		() =>
			filials
				?.filter((f) => f.id !== filialFromId)
				?.map((f) => ({
					label: f.name,
					value: f.id.toString(),
				})) ?? [],
		[filialFromId, filials],
	)

	const handleSubmit = () => {
		if (!filialFromId || !filialToId) {
			reportUserError({
				title: "You must select both filials",
			})
			return
		}

		if (minStock < 5) {
			reportUserError({
				title: "Minimum stock must be greater than or equal to 5",
			})
			return
		}

		createInventoryTransfer({
			fromFilialId: filialFromId,
			toFilialId: filialToId,
			minStock: minStock,
		})
	}

	return (
		<styled.div display="flex" flexDirection="column" gap="2">
			<styled.p fontSize="14px" mb="1rem">
				Create an inventory transfer with 'draft' status for all items from a
				filial (filialTo) that currently has no stock, based on the available
				stock from another filial (filialFrom). Only include items where the
				minimum stock is greater than or equal to a specified threshold (e.g.,
				5) and where the stock level in filialTo is zero.
			</styled.p>
			<styled.div display="flex" gap="1.5rem">
				<Select
					withinPortal
					label="Filial from"
					placeholder="Select filial"
					data={filialsFrom}
					onChange={(value) => setFilialFromId(Number(value))}
					required
				/>
				<Select
					withinPortal
					label="Filial to"
					placeholder="Select filial"
					data={filialsTo}
					onChange={(value) => setFilialToId(Number(value))}
					required
				/>
			</styled.div>
			<styled.div display="flex" gap="2rem" alignItems="flex-end">
				<NumberInput
					value={minStock}
					onChange={(value) => {
						setMinStock(Number(value))
					}}
					min={5}
					step={1}
					label="Min stock"
					required
					onInput={(e) => {
						const value = e.currentTarget.value
						const onlyNumbers = value.replace(/[^0-9]/g, "")
						e.currentTarget.value = onlyNumbers
					}}
				/>
				<Button
					disabled={filialFromId === null || filialToId === null}
					className={css({ cursor: "pointer" })}
					isLoading={isLoading}
					onClick={handleSubmit}
				>
					Create transfer inventory
				</Button>
			</styled.div>
		</styled.div>
	)
}
