import GTLoader from "#/components-ng/loader";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { HomePageBannerForm } from "./Form";
import { FormValues, textTemplates, positions } from "./Form/types";
import { LoadingOverlay } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";

export const UpdateHomePageBanner = () => {
  const params = useParams();
  const id = params?.id ? Number(params.id) : null;
  const navigate = useNavigate();
  const ctx = trpc.useContext();

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.banner.getById.useQuery(
      { id: id! },
      { enabled: !!id, cacheTime: 0 }
    );

  const { mutate, isLoading: isLoadingUpdate } =
    trpc.v2_5.banner.update.useMutation({
      onError(error) {
        reportUserError({
          title: "Error updating home page banner",
          message: error.message,
        });
      },
      onSuccess() {
        ctx.v2_5.banner.invalidate();
        reportUserSuccess({
          title: "Home page banner updated",
        });
        navigate("/ecommerce-crm/banner");
      },
    });

  const handleSubmit = (values: FormValues) => {
    mutate({
      id: id!,
      input: {
        title: values.title,
        template: values.template,
        position: Number(values.position),
        page: values.page,
        redirectTo: values.redirectTo,
        imageDesktop: values.imageDesktop,
        imageMobile: values.imageMobile,
        activeFrom: values.dateRange[0],
        activeTo: values.dateRange[1],
        withButton: values.withButton,
        content: values.content.map((it) => ({
          content: it.content,
          contentEsp: it?.contentEsp ?? null,
          colorHex: it.colorHex,
          position: Number(it.position),
        })),
        buttonHexColor: values.withButton ? values.buttonHexColor : null,
        buttonText: values.withButton ? values.buttonText : null,
        buttonTextEsp: values.withButton ? values?.buttonTextEsp : null,
        buttonVariant: values.withButton ? values.buttonVariant : null,
      },
    });
  };

  return isLoading || !isFetchedAfterMount ? (
    <LoadingOverlay
      visible={isLoading || !isFetchedAfterMount}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <HomePageBannerForm
      isLoading={isLoadingUpdate}
      onSubmit={handleSubmit}
      defaultValues={{
        title: data?.title,
        template: data?.template as (typeof textTemplates)[number],
        position: data?.position?.toString() as (typeof positions)[number],
        page: data?.page,
        redirectTo: data?.redirectTo,
        imageDesktop: data?.imageDesktop,
        imageMobile: data?.imageMobile,
        dateRange: [data?.activeFrom, data?.activeTo],
        withButton: data?.withButton,
        buttonHexColor: data?.buttonHexColor ?? "#000000",
        buttonText: data?.buttonText,
        buttonTextEsp: data?.buttonTextEsp,
        buttonVariant: data?.buttonVariant,
        content: data?.bannerContentTexts.map((it) => ({
          content: it.content,
          contentEsp: it?.contentEsp,
          colorHex: it.colorHex,
          position: it.position?.toString() as (typeof positions)[number],
        })),
      }}
    />
  );
};
