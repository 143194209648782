export type CartFilter =
	| "makeAnOrder"
	| "withFirstEmail"
	| "withSecondEmail"
	| "called"

export const Filter: Record<CartFilter, string> = {
	makeAnOrder: "Make an order",
	withFirstEmail: "1st email sended",
	withSecondEmail: "2nd email sended",
	called: "Called",
} as const

export type FilterKey = keyof typeof Filter
export type Filter = (typeof Filter)[FilterKey]
