import { useState, useEffect } from "react"
import * as M from "@mantine/core"
import logoUrl from "#/images/colored-logo-invoice.png"
import dayjs from "dayjs"
import type { InventoryTransferStatus } from "server"
import { styled } from "#/css/jsx"

interface PdfDataInterface {
	guide: string | null
	filialFrom: string
	filialTo: string
	reason: string
	note?: string | null
	itemSkus: {
		title: string
		sku: number
		quantity: number
		previousQuantityFrom: number
		previousQuantityTo: number
	}[]
	status: InventoryTransferStatus
	createdBy: string
	createdAt?: string | null
}

export const PrintTransferInventoryPdf = () => {
	const [imageLoaded, setImageLoaded] = useState<boolean>(false)

	const transferInventory: PdfDataInterface = JSON.parse(
		localStorage.getItem("transferInventory") || "{}",
	)

	console.log(transferInventory)

	useEffect(() => {
		if (transferInventory && imageLoaded) {
			window.print()
		}
	}, [transferInventory, imageLoaded])

	if (!transferInventory) {
		return (
			<M.Center>
				<M.Loader size="lg" p="xl" />
			</M.Center>
		)
	}

	return (
		<M.Box p="xl">
			<M.Group position="apart">
				<M.Group position="left" spacing="md" align="flex-start">
					<M.Image
						src={logoUrl}
						width={100}
						onLoad={() => setImageLoaded(true)}
					/>
					<M.Stack spacing={0} align="flex-start">
						<M.Text weight={600} size={20} color="#836415">
							Gold Tree
						</M.Text>
						<M.Text weight={500} size={16} color="#1A1C21">
							www.goldtreemiami.com
						</M.Text>
						<M.Text size={16} color="#5E6470">
							+1 (786) 7177131
						</M.Text>
						<M.Text size={16} color="#5E6470">
							755 NW 72nd Ave Plaza 33
						</M.Text>
						<M.Text size={16} color="#5E6470">
							Miami, FL, 33126
						</M.Text>
					</M.Stack>
				</M.Group>
				<M.Stack spacing={0} align="flex-end">
					<M.Text size={16} color="#5E6470">
						Guide
					</M.Text>
					<M.Text weight={500} size={16} color="#1A1C21" mb={2}>
						{transferInventory?.guide ?? ""}
					</M.Text>
					<M.Text size={16} color="#5E6470">
						Created by: {transferInventory?.createdBy ?? ""}
					</M.Text>
					<M.Text size={16} color="#5E6470">
						Created At:{" "}
						{transferInventory?.createdAt
							? dayjs(transferInventory?.createdAt).format("MM/DD/YYYY")
							: dayjs().format("MM/DD/YYYY")}
					</M.Text>
					<M.Text size={16} color="#5E6470">
						Printed date: {dayjs().format("MM/DD/YYYY")}
					</M.Text>
				</M.Stack>
			</M.Group>

			<M.Divider my={40} color="#D7DAE0" />

			<M.Stack spacing={0} align="flex-start" mb={40}>
				<M.Text size={16} color="#5E6470">
					From:{" "}
					<styled.span fontWeight={500} fontSize={16} color="#1A1C21">
						{transferInventory?.filialFrom ?? ""}
					</styled.span>
				</M.Text>
				<M.Text weight={500} size={16} color="#1A1C21"></M.Text>
				<M.Text size={16} color="#5E6470">
					To:{" "}
					<styled.span fontWeight={500} fontSize={16} color="#1A1C21">
						{transferInventory?.filialTo ?? ""}
					</styled.span>
				</M.Text>
				<M.Text size={16} color="#5E6470">
					Status: {transferInventory?.status}
				</M.Text>
				<M.Text size={16} color="#5E6470">
					Reason: {transferInventory?.reason ?? ""}
				</M.Text>
				<M.Text size={16} color="#5E6470"></M.Text>
			</M.Stack>

			<M.Table mb={40}>
				<thead className="border !border-x-0 !border-y-[#D7DAE0] p-5">
					<tr>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							Sku
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							Produt name
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							Move quantity
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							Previous quantity from
						</th>
						<th className="!p-4 !text-center !text-[11.2px] !font-semibold !text-[#5E6470]">
							Previous quantity to
						</th>
					</tr>
				</thead>
				<tbody className="border !border-x-0 !border-y-[#D7DAE0]">
					{transferInventory?.itemSkus?.map((p, i) => {
						return (
							<tr key={i}>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{p?.sku}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{p?.title}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{p?.quantity}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{p?.previousQuantityFrom}
								</td>
								<td className="!border-none !p-4 text-center !text-xs !text-[#1A1C21]">
									{p?.previousQuantityTo}
								</td>
							</tr>
						)
					})}
				</tbody>
			</M.Table>

			{transferInventory?.note && (
				<M.Stack className="mt-5" spacing="xs">
					<M.Text size={14} color="#1A1C21" weight={600}>
						Note:
					</M.Text>
					<M.Text size={14} color="#5E6470">
						{transferInventory?.note}
					</M.Text>
				</M.Stack>
			)}
		</M.Box>
	)
}
