import { trpc } from "#/trpc"
import { zodResolver } from "@hookform/resolvers/zod"
import {
	C,
	type FormValues,
	generatePromotionReportSchema,
	productsRange,
} from "./types"
import { reportUserError } from "#/util"
import { styled } from "#/css/jsx"
import { FormProvider } from "react-hook-form"
import { LoadingOverlay, Select } from "@mantine/core"
import { Button } from "@gt/ui"
import { css } from "#/css/css"
import GTLoader from "#/components-ng/loader"
import { BarChart } from "#/components-ng"
import { PieChartCard } from "../common"
import React from "react"
import dayjs from "dayjs"

export const PromotionReport = () => {
	const { data: entities } = trpc.entity.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})
	const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})
	const { data: promotions } = trpc.v2_5.promotion.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})

	const form = C.useForm({
		resolver: zodResolver(generatePromotionReportSchema),
		shouldUnregister: false,
		defaultValues: {
			productsRange: "10",
			promotionId: null,
			filter: {
				by: "entity",
				id: "1",
			},
		},
	})

	const {
		data: report,
		mutate: generateReport,
		isLoading,
	} = trpc.v2_5.report.getPromotionReport.useMutation()

	const entityOptions = useMemo(
		() =>
			entities?.map((entity) => ({
				label: entity.name,
				value: entity.id.toString(),
			})) ?? [],
		[entities],
	)

	const filialOptions = useMemo(
		() =>
			filials?.map((filial) => ({
				label: filial.name,
				value: filial.id.toString(),
			})) ?? [],
		[filials],
	)

	const promotionsOptions = useMemo(
		() =>
			promotions?.map((promotion) => ({
				label: `${promotion.id} - ${promotion.name}`,
				value: promotion.id.toString(),
				description: `${dayjs(promotion.activeAt).format("MM/DD/YYYY")} - ${dayjs(promotion.inactiveAt).format("MM/DD/YYYY")}`,
			})) ?? [],
		[promotions],
	)

	const data = useMemo(() => {
		if (report) {
			return report
		}
		return null
	}, [report])

	const handleSubmit = (values: FormValues) => {
		if (!values.promotionId) {
			reportUserError({
				title: "Promotion is required",
				message: "Please select a promotion to generate the report",
			})
			return
		}

		generateReport({
			promotionId: Number(values.promotionId),
			productsRange: Number(values.productsRange),
			filter: {
				by: values.filter.by,
				id: Number(values.filter.id),
			},
		})
	}

	const filterBy = form.watch("filter.by")

	return (
		<>
			<styled.div gap="1rem" display="flex" flexDirection="column">
				<styled.h2>Promotion report</styled.h2>
				<FormProvider {...form}>
					<form
						onSubmit={(e) => {
							e.stopPropagation()
							form.handleSubmit(handleSubmit)(e)
						}}
					>
						<styled.div display="flex" gap="1rem">
							<C.M
								as={Select}
								label="Report type"
								name="filter.by"
								data={[
									{ label: "Entity", value: "entity" },
									{ label: "Filial", value: "filial" },
								]}
								required
							/>
							<C.M
								as={Select}
								label={
									filterBy === "entity" ? "Select entity" : "Select filial"
								}
								name="filter.id"
								data={filterBy === "entity" ? entityOptions : filialOptions}
								required
							/>
							<C.M
								as={Select}
								label="Select promotion"
								name="promotionId"
								data={promotionsOptions}
								required
								searchable
								clearable
								className="w-[250px]"
								itemComponent={SelectItem}
							/>
							<C.M
								as={Select}
								label="Products range"
								name="productsRange"
								data={productsRange.map((r) => ({
									label: r,
									value: r,
								}))}
								className="w-[150px]"
							/>

							<Button
								type="submit"
								isLoading={isLoading}
								className={css({
									marginTop: "1.25rem",
									paddingX: "3rem",
								})}
							>
								Generate
							</Button>
						</styled.div>
					</form>
				</FormProvider>
				{isLoading && (
					<LoadingOverlay
						visible={isLoading}
						loader={<GTLoader width={100} height={100} />}
					/>
				)}
				{data && !isLoading && (
					<>
						<styled.hr />
						<styled.div
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							gap="1rem"
							flexDirection={{
								base: "column",
								lg: "row",
							}}
						>
							<PieChartCard
								title="Total Sales"
								text={`$${data?.total?.general?.toNumber()?.toFixed(2) ?? 0}`}
								total={data?.total?.general?.toNumber() ?? 0}
								data={[
									{
										title: "Promotion",
										value: data?.total?.promotions?.toNumber() ?? 0,
										color: "#60C69B",
									},
								]}
								isMoney={true}
							/>
							<PieChartCard
								title="Total orders"
								text={`${data?.orders?.total ?? 0}`}
								total={data?.orders?.total ?? 0}
								data={[
									{
										title: "Orders with promotion",
										value: data?.orders?.withPromotions ?? 0,
										color: "#4DBAFF",
									},
								]}
								isMoney={false}
							/>
						</styled.div>

						<styled.hr marginY="1.5rem" />
						<styled.p fontWeight={600}>
							Products most sold of the promotion
						</styled.p>
						<BarChart
							pathname="/inventory/item-list/preview/"
							enableRedirect
							textLabel="Sold items"
							data={
								data?.itemsMostSoldGrouped?.map((p) => ({
									id: p.id,
									label: `${p.sku}`,
									total: p.totalTimesSold ?? 0,
								})) ?? []
							}
						/>
					</>
				)}
			</styled.div>
		</>
	)
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
	label: string
	description: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
	({ label, description, ...others }: ItemProps, ref) => (
		<div ref={ref} {...others}>
			<styled.div flexWrap="nowrap">
				<div>
					<styled.p fontSize="13px">{label}</styled.p>
					<styled.p fontSize="12px" opacity={0.65}>
						{description}
					</styled.p>
				</div>
			</styled.div>
		</div>
	),
)
