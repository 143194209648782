import { styled as s } from "#/css/jsx"
import { LogoWithWebsiteUrl } from "./logo-with-website.js"
import { CatalogItemV2 } from "./types"
import { Page } from "./page"

export function CoverPage(props: { catalogItem: CatalogItemV2 }) {
	const catalogItem = props.catalogItem

	return (
		<Page>
			<s.div display="flex" flexDirection="column" h="297mm" gapY="0">
				<s.div px="2.5cm" pt="2.5cm" pb="1.5cm">
					<LogoWithWebsiteUrl />
					<s.p c="var(--text-color)" fs="1.75cm" fw="medium" mt="3cm">
						{catalogItem?.title}
					</s.p>
				</s.div>
				<s.div
					flex="1"
					display="flex"
					justifyContent="center"
					alignItems="center"
					overflow="auto"
				>
					<s.img
						src={catalogItem?.defaultImage ?? ""}
						objectFit="cover"
						maxH="100%"
						w="210mm"
					/>
				</s.div>
			</s.div>
		</Page>
	)
}
