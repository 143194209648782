import { useAuth } from "#/context/AuthContext.js"
import { css } from "#/css/css"
import { HStack, styled } from "#/css/jsx"
import { trpc } from "#/trpc.js"
import { useState } from "react"
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper"
import {
	openDetailsDrawerAtom,
	openModalAtom,
	selectedItemSkuIdAtom,
	selectedItemSkuToPrintAtom,
} from "../ItemList/ItemList"
import { Provider } from "jotai"
import { PrintLabelsModal } from "../ItemList/PrintLabelsModal"
import { DetailsDrawer } from "../ItemList/DetailsDrawer"
import { Select } from "@mantine/core"
import { ItemListTable } from "../ItemList/ItemListTable"
import { Button, Dropdown } from "@gt/ui"
import { DropdownMenu } from "@radix-ui/themes"
import { openCreateTransferInventoryWithItemsOutOfStockModal } from "./create-transfer-inventory-with-items-out-of-stock-modal"
import { Link } from "react-router-dom"

function OutOfStockScene() {
	const [{ auth }] = useAuth()

	const [browserPrint] = useState(() => new ZebraBrowserPrintWrapper())
	const [isLoadingPrinters, setIsLoadingPrinters] = useState<boolean>(false)

	const [selectedItemSkuId, setSelectedItemSkuId] = useAtom(
		selectedItemSkuIdAtom,
	)
	const [selectedItemSkuToPrint, setSelectedItemSkuToPrint] = useAtom(
		selectedItemSkuToPrintAtom,
	)
	const [openModal, setOpenModal] = useAtom(openModalAtom)
	const [openDetailsDrawer, setOpenDetailsDrawer] = useAtom(
		openDetailsDrawerAtom,
	)

	const [filialSelected, setFilialSelected] = useState<string | null>(null)

	const { data: allFilials } = trpc.filial.getAll.useQuery(undefined, {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			if (data.length > 0) {
				const filialStore = data.find((e) => e.name.includes("Store"))
				setFilialSelected(filialStore?.id.toString() ?? data[0].id.toString())
			}
		},
		enabled: !auth?.user ? true : false,
	})

	useEffect(() => {
		async function setDefaultPrinter() {
			setIsLoadingPrinters(true)
			const availablePrinters = await browserPrint.getAvailablePrinters()
			browserPrint.setPrinter(availablePrinters[0])
			setIsLoadingPrinters(false)
		}

		setDefaultPrinter()
	}, [browserPrint])

	return (
		<>
			<Provider key={selectedItemSkuToPrint?.id}>
				<PrintLabelsModal
					printer={browserPrint}
					isLoadingPrinters={isLoadingPrinters}
					defaultProduct={selectedItemSkuToPrint as any}
					setSelectedItemSkuToPrint={setSelectedItemSkuToPrint}
					opened={openModal}
					setOpened={setOpenModal}
				/>
			</Provider>
			<HStack gap={3}>
				<DrowndownButton />
				{!auth?.user?.filialId && (
					<HStack justify="space-between" gap={3} flexWrap="nowrap">
						<Select
							placeholder="Select filial"
							data={
								allFilials?.map((filial) => ({
									value: filial.id.toString(),
									label: filial.name,
								})) ?? []
							}
							value={filialSelected ?? ""}
							onChange={(value) => {
								setFilialSelected(value)
							}}
						/>
					</HStack>
				)}
			</HStack>
			<styled.div mt={15}>
				<ItemListTable
					onlyOutOfStock={true}
					filialId={
						auth?.user?.filialId
							? auth?.user?.filialId
							: filialSelected
								? Number(filialSelected)
								: null
					}
				/>
			</styled.div>
			<DetailsDrawer
				opened={openDetailsDrawer}
				onClose={() => {
					setOpenDetailsDrawer(false)
					setSelectedItemSkuId(null)
				}}
				itemSkuId={selectedItemSkuId?.id ?? 0}
				sku={selectedItemSkuId?.sku?.toString() ?? ""}
				title={selectedItemSkuId?.title ?? ""}
			/>
		</>
	)
}

export default OutOfStockScene

const DrowndownButton = () => {
	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button
					className={css({
						gap: 4,
					})}
				>
					I want to
					<DropdownMenu.TriggerIcon />
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content>
				<Dropdown.Item
					onClick={() => openCreateTransferInventoryWithItemsOutOfStockModal()}
				>
					Inventory Check
				</Dropdown.Item>
				<Dropdown.Item>
					<Link to="suggested-disable-items">Suggested disable items</Link>
				</Dropdown.Item>
			</Dropdown.Content>
		</Dropdown.Root>
	)
}
