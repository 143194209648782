import { trpc } from "#/trpc";
import { useParams } from "react-router";
import { CatalogForm, FormValues } from "./Form/index.js";
import { LoadingOverlay } from "@mantine/core";
import GTLoader from "#/components-ng/loader.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";

export const UpdateCatalog = () => {
  const params = useParams() as any;
  const ctx = trpc.useContext();
  const id = params.id ? Number(params.id) : null;

  const { data, isLoading, isFetchedAfterMount } =
    trpc.v2_5.catalog.getById.useQuery(
      {
        id: id!,
      },
      {
        enabled: !!id,
        cacheTime: 0,
      },
    );

  const { mutate, isLoading: isLoadingUpdate } =
    trpc.v2_5.catalog.update.useMutation({
      onError(error) {
        reportUserError({
          title: "Error updating catalog item",
          message: error.message,
        });
      },
      onSuccess() {
        reportUserSuccess({
          title: "Catalog item updated",
        });
        ctx.v2_5.catalog.invalidate();
      },
    });

  const handleSubmit = (values: FormValues) => {
    mutate({
      id: id!,
      title: values.title,
      titleEsp: values?.titleEsp ?? null,
      description: values.description,
      descriptionEsp: values?.descriptionEsp ?? null,
      type: values.type,
      defaultImage: values.defaultImage,
    });
  };

  return data && isFetchedAfterMount ? (
    <CatalogForm
      onSubmit={handleSubmit}
      isLoading={isLoadingUpdate}
      title="Update catalog item"
      defaultValues={{
        title: data.title,
        titleEsp: data?.titleEsp,
        description: data.description ?? "",
        descriptionEsp: data?.descriptionEsp ?? null,
        type: data.type,
        defaultImage: data.defaultImage,
      }}
      catalogId={id!}
      itemSkus={data?.catalogItemSkus?.map((item) => ({
        id: item.itemSku.id,
        sku: item.itemSku.sku,
        title: item.itemSku.title,
        defaultImage: item.itemSku?.defaultImage ?? null,
      }))}
    />
  ) : (
    <LoadingOverlay
      visible={isLoading || !isFetchedAfterMount}
      loader={<GTLoader width={100} height={100} />}
    />
  );
};
